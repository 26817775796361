import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';
import MTKLogo from './../../../assets/images/make-the-kut/mtklogo.svg';

const  UserSubmissionArea = ({  }) => {

	
	useEffect(() => { 

    },[]);
    
    return (
        <div class="user-submission-area">
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.14314 10.5713H6.00028C5.3691 10.5713 4.85742 11.083 4.85742 11.7141V16.2856C4.85742 16.9168 5.3691 17.4284 6.00028 17.4284H7.14314C7.77432 17.4284 8.28599 16.9168 8.28599 16.2856V11.7141C8.28599 11.083 7.77432 10.5713 7.14314 10.5713Z" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13.9996 10.5713H12.8567C12.2255 10.5713 11.7139 11.083 11.7139 11.7141V16.2856C11.7139 16.9168 12.2255 17.4284 12.8567 17.4284H13.9996C14.6308 17.4284 15.1424 16.9168 15.1424 16.2856V11.7141C15.1424 11.083 14.6308 10.5713 13.9996 10.5713Z" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M2.57129 12.857V9.99986C2.57129 8.02968 3.35394 6.14019 4.74707 4.74707C6.14019 3.35394 8.02968 2.57129 9.99986 2.57129C11.97 2.57129 13.8595 3.35394 15.2527 4.74707C16.6458 6.14019 17.4284 8.02968 17.4284 9.99986V12.857" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <span>User submission area</span>
		</div>
    );
  
}


function mapStateToProps(state) {
	return {
	};
 }
 
 function mapDispatchToProps(dispatch) {
	return {
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(UserSubmissionArea);
