import React, { useState, useEffect } from "react";

import { checkCustomer } from '../../components/utlity/checkCustomer';
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import FavouriteButton from '../../elements/songElements/favouriteButton';
import DownloadAllButton from '../../elements/songElements/downloadAllButton';
import DropboxAllButton from '../../elements/songElements/dropboxAllBtn';
import SongToggleButton from '../../elements/songElements/songToggleButton';
import PlayPauseButton from '../../elements/songElements/playPauseButton';
import SongVersion from './songVersion';
import PlaylistPopup from './playlistPopup';

const  MTKSongTrack = ({ songs, sectionName, activeBtn, isCuratedSpotlight, customerProfile }) => {

    const [toggleSong, setToggleSong ] = useState(false);
    const [activeSongId, setActiveSongId] = useState(0);
    const [customClassName, setCustomClassName] = useState("block");
    const [oneClickDownload, setOneClickDownload ] = useState(false);
    const [ canAccess, setCanAccess ] = useState(false);

    useEffect(() => { 
        if(checkCustomer(customerProfile)){
            setCanAccess(true);
        }
    },[customerProfile])

    const setSongToggle = (songId) => {
        
        if(activeSongId == songId ){
            setToggleSong(!toggleSong)
        } else {
            setToggleSong(true)
        }
        setActiveSongId(songId);
        
    }

    useEffect(() => { 
        setOneClickDownload(parseInt(customerProfile.one_click_download));
    },[customerProfile]);

    return (
        <div>
        {
        songs && songs.length > 0 ? 
            songs.map((song, index)=>{
                return <div className={(toggleSong && activeSongId == song.entity_id) || oneClickDownload ? "block active top-listing top-listing-summary" : "block top-listing top-listing-summary"} key={`song${sectionName}${song.entity_id}`}>
                            <div className="top-listing-block">
                                <div className="top-listing-inner">
                                    <div className="play-pause-icon" onClick={() => setSongToggle(song.entity_id)}>
                                        <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="1" width="43" height="43" rx="21.5" fill="#25282C"/>
                                            <rect x="0.5" y="1" width="43" height="43" rx="21.5" stroke="#2B2F32"/>
                                            <path d="M16.9717 27.3912C16.975 27.5817 17.0304 27.7676 17.1319 27.9288C17.2335 28.09 17.3773 28.2203 17.5477 28.3055C17.7178 28.4017 17.91 28.4522 18.1054 28.4522C18.3008 28.4522 18.493 28.4017 18.6631 28.3055L26.5717 23.4141C26.7438 23.3312 26.8889 23.2014 26.9906 23.0397C27.0922 22.8779 27.1461 22.6908 27.1461 22.4998C27.1461 22.3088 27.0922 22.1217 26.9906 21.9599C26.8889 21.7982 26.7438 21.6684 26.5717 21.5855L18.6631 16.7307C18.493 16.6345 18.3008 16.584 18.1054 16.584C17.91 16.584 17.7178 16.6345 17.5477 16.7307C17.3773 16.8159 17.2335 16.9461 17.1319 17.1073C17.0304 17.2685 16.975 17.4545 16.9717 17.6449V27.3912Z" fill="#F4FD84"/>
                                        </svg>
                                    </div>
                                    <div className="top-listing-content">
                                        <div className="image-name">
                                            {/* <img src={CoverImage} alt="cover" /> */}
                                            <div className="name">
                                                <span className="first" dangerouslySetInnerHTML={{__html: song.title}}></span>
                                                {/* { 
                                                    song.artist_search && song.artist_search && song.artist_search.length > 0 ? 
                                                        song.artist_search.map((as, asIndex) => {
                                                            // return <div key={`as${asIndex}-${song.entity_id}`} ><Link to={`/mp3/artist/${as}`} dangerouslySetInnerHTML={{__html: as.trim()}} className="text-5"></Link> <span className="text-5">&nbsp;{ asIndex < (song.artist_search[0].artist.length -1) ? song.artist_search[0].split_string : ''}&nbsp;</span></div>
                                                            return <Link key={`as${asIndex}-${song.entity_id}`} to={`/mp3/artist/${as}`} className="text-5"><span dangerouslySetInnerHTML={{__html: as.trim()}}></span>{ asIndex != (song.artist_search.length -1) ? ', ' : ''}</Link>
                                                        })
                                                    :
                                                    <div><Link to={`/mp3/artist/${song.artist}`} dangerouslySetInnerHTML={{__html: song.artist}} className="text-5"></Link></div>
                                                } */}
                                                <span className="last" dangerouslySetInnerHTML={{__html: song.artist}}></span>
                                            </div>
                                        </div>
                                        <div className="track-summary">
                                            <div className="track-summary-inner">
                                                <span>BMP <b>{song.max_bpm}</b></span>
                                                <span>Key <b>{song.song_key}</b></span>
                                                <span>{song.created_at}</span>
                                            </div>
                                            <div className="download-icon">
                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M24.6294 14.7593L21.667 25.815L10.6113 22.8526" stroke="#828282" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M10.333 6.18506L21.553 25.6187" stroke="#828282" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <PlayPauseButton parentComponent="songTrack" song={song} /> */}
                                    {/* <div className="track-name">
                                        <span className="text-2" dangerouslySetInnerHTML={{__html: song.title}}></span>
                                        
                                        <div className="artist-name">
                                            { 
                                                song.artist_search && song.artist_search && song.artist_search.length > 0 ? 
                                                    song.artist_search.map((as, asIndex) => {
                                                        // return <div key={`as${asIndex}-${song.entity_id}`} ><Link to={`/mp3/artist/${as}`} dangerouslySetInnerHTML={{__html: as.trim()}} className="text-5"></Link> <span className="text-5">&nbsp;{ asIndex < (song.artist_search[0].artist.length -1) ? song.artist_search[0].split_string : ''}&nbsp;</span></div>
                                                        return <Link key={`as${asIndex}-${song.entity_id}`} to={`/mp3/artist/${as}`} className="text-5"><span dangerouslySetInnerHTML={{__html: as.trim()}}></span>{ asIndex != (song.artist_search.length -1) ? ', ' : ''}</Link>
                                                    })
                                                :
                                                <div><Link to={`/mp3/artist/${song.artist}`} dangerouslySetInnerHTML={{__html: song.artist}} className="text-5"></Link></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="play-track-content">
                                    <div>
                                        <span className="text-4">{song.max_bpm}</span>
                                        <span className="text-4">{song.song_key}</span>
                                        <span className="text-5">{song.created_at}</span>
                                    </div>
                                    <div className="divider">
                                        { song.download_count && song.download_count ? <span className="st-fav m-0 download-report-count">{song.download_count} </span> : canAccess ? <span className="st-fav m-0"><FavouriteButton song={song} /></span> : '' }
                                        { activeBtn == 'download' ? <span className="st-download m-0"><DownloadAllButton entity_id={song.entity_id} isCkExclusive={song.isCkExclusive} isCuratedSpotlight={isCuratedSpotlight} totalDownload={song.download_count} /></span> : ''}
                                        { activeBtn == 'dropbox' ? <span className="st-dropbox m-0"><DropboxAllButton entity_id={song.entity_id} isCkExclusive={song.isCkExclusive} isCuratedSpotlight={isCuratedSpotlight} /></span> : ''}
                                        { canAccess  ? <PlaylistPopup song={song} /> : '' }
                                    </div>
                                    { 
                                        oneClickDownload ? 
                                        ''
                                        : 
                                        <div>
                                            <SongToggleButton songId={song.entity_id} setSongToggle={setSongToggle} toggleSong={toggleSong}  />
                                        </div>
                                    }
                                </div>
                                <div className="version-container">
                                {
                                    song.version && song.version.length > 0 ?
                                        song.version.map((version, index) => {
                                            return <div className='block-inner-content' key={`song${sectionName}version${song.entity_id}${version.song_version_id}`}>
                                                <SongVersion version={version} songId={song.entity_id} songSectionId={song.song_section_id} song={song} isCuratedSpotlight={isCuratedSpotlight} />
                                            </div>
                                        })
                                    : ''
                                }
                                </div> */}
                            </div>
                            </div>
                        </div>
                    })
            : ''
        }
        </div>
        
    );
  
}


function mapStateToProps(state) {
  return {
    activeBtn: state.activeDownloadBtn.activeDownloadBtn,
    customerProfile: state.customer.customerProfile
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(MTKSongTrack);
