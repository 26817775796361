import React, {useState, useEffect} from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import AccountNav from '../../elements/accountElements/accountNav';
import Spinner from 'react-bootstrap/Spinner';
import ManageDevice from './manageDevice';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {connect} from 'react-redux';
import * as customerAction from '../../store/customer/actions';





const AccountSetting = ({user, userProfile, displayLoading, hideLoading, updateProfile }) => {
    
    const [ processing, setProcessing ] = useState(false);
    const [ oneClickDownload, setOneClickDownload ] = useState(false);
    const [formData, setFormData ] = useState({
        token: user,
        one_click_download: 0
    })
    
    // console.log(userProfile)
    useEffect(() => { 
        displayLoading();
        if(userProfile && userProfile.one_click_download && parseInt(userProfile.one_click_download) == 1){
            setOneClickDownload(true);
        }
        hideLoading();
    },[]);

    const handleChange = (e) => {

        const formData= {
            token: user,
            one_click_download: e.target.checked ? 1 : 0
        };  
        setOneClickDownload(e.target.checked)
        updateProfile(formData).then(res => {
        });
    }

    

  return (
    <MusicDashboard  accountPages={true} middleSectionCssClass={'account-info-sec'}>
       	<div className="content-sec">
            <div className="account-block">
                <div className="top-heading">
                    <h2>Account Settings</h2>
                </div>
                <AccountNav />
                {/* <div className="account-inner-block">
                    <div className="edit-profile">
                        <div className="title-heading">
                            <h3>One Click Download</h3>
                        </div>
                    </div>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            label={oneClickDownload ? "ON" : "OFF"}
                            onChange={handleChange}
                            name="one_click_download"
                            checked={oneClickDownload}
                        />
                </div> */}
                <ManageDevice />
            </div>
		</div>        
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        authentication: state.authentication,
        userProfile: state.customer.customerProfile,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCustomerProfile: (data) => dispatch(customerAction.getCustomerProfile(data)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        updateProfile: (post) => dispatch(customerAction.updateProfile(post)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSetting);