import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CuratedSpotlightCarousel from '../../components/curatedSpotlightCarousel/curatedSpotlightCarousel';
import RightSide from "../../components/rightSide/rightSide";
import SongTrack from '../../components/songTrack/songTrack';

import MusicDashboard from '../../layouts/musicDashboard';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { Link } from "react-router-dom";
import { checkCustomer } from '../../components/utlity/checkCustomer';
import { checkPremiumMember } from '../../components/utlity/checkPremiumMember';
import { connect } from 'react-redux';
import * as actions from '../../store/curated/actions';
import SongTrackLoader from "../../components/loader/songTrackLoader";



const  CuratedSpotlightDetail = ({  getCuratedSongs, curatedSongs, displayLoading, hideLoading, userProfile, curatedItems, getCuratedItems }) => {

    let match = useParams("/mp3/spotlight/detail/:id");
    const navigate = useNavigate();
    const [ page, setPage ] = useState(1);
    const [dataProcessing, setDataProcessing] = useState(false);
    const [ canAccess, setCanAccess ] = useState(false);
	const [ isPremium, setIsPremium ] = useState(false);
    const [allowAccessOnlyStandardUsers, setAllowAccessOnlyStandardUsers] = useState();
    const [showCuratedList, setShowCuratedList ] = useState(true);
    // const newArray = originalArray.map(item => item[keyToExtract]);
    useEffect(() => { 
        setShowCuratedList(true);
		loadData()
    },[match.id])

    

    useEffect(() => { 
		if(curatedItems.length > 0){
            const filteredCuratedItemsById = curatedItems.map(item => parseInt(item['curated_list_id']));
            setAllowAccessOnlyStandardUsers(filteredCuratedItemsById);
            if(canAccess && !isPremium){
                if(!filteredCuratedItemsById.includes(parseInt(match.id))){
                    // console.log('fff', filteredCuratedItemsById.includes(parseInt(match.id)), '-fff')
                    setShowCuratedList(false);
                }
                //  console.log('filteredCuratedItemsById', filteredCuratedItemsById, match.id)
            }
        } else {
            getCuratedItems();
        }
        
    },[curatedItems])
    
    

	const loadData = async() => {
        setDataProcessing(true)
        displayLoading();
		await getCuratedSongs({page: page, curated_list_id: match.id}).then((res) => {
            // console.log('res', curatedSongs)
            if(allowAccessOnlyStandardUsers){
                if(canAccess && !isPremium){
                    if(!allowAccessOnlyStandardUsers.includes(parseInt(match.id))){
                        // console.log('ss', allowAccessOnlyStandardUsers.includes(parseInt(match.id)), '--')
                        setShowCuratedList(false);
                    }
                    // console.log('allowAccessOnlyStandardUsers', allowAccessOnlyStandardUsers, match.id)
                }
            }
        });
        if(curatedItems.length == 0){
            await getCuratedItems();
        }
        hideLoading();
        setDataProcessing(false);
        window.scrollTo({top: 0});
    }

    useEffect(() => { 
		if(checkCustomer(userProfile)){
            // navigate('/subscription/plans');
            setCanAccess(true);
        }
        if(checkPremiumMember(userProfile)){
			setIsPremium(true);
		}
	},[userProfile]);
    
    
	return (
			<MusicDashboard rightContent={<RightSide blocks={{curatedFilter: true }} />}>
			    <div className="content-sec">
                    <div className="top-heading">
                        <div className="back-arrow-head">
                            <span onClick={() => navigate(-1)} className="cp">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 6L8.09618 9.78462C8.06582 9.81224 8.04162 9.8456 8.02508 9.88265C8.00853 9.91969 8 9.95963 8 10C8 10.0404 8.00853 10.0803 8.02508 10.1174C8.04162 10.1544 8.06582 10.1878 8.09618 10.2154L12 14" stroke="#080A0D" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </span>
                            <h2>Curated Spotlights</h2>
                        </div>
                        <Link to={`/mp3/spotlight/home`} className="text-2">View all</Link>
                    </div>
                    <div className="play-list-sec">
                        {
                            curatedSongs ? 
                            <div style={{backgroundImage: `url(${curatedSongs.image})`}} class="cu-block-inner">
                                <div className="cd-container cu-block-bg">
                                    <div className="cu-midil-content">
                                        <div className="cd-image">
                                            <img src={curatedSongs.image} alt={curatedSongs.title} />
                                        </div>
                                        <div className="cd-detail">
                                            <h3 dangerouslySetInnerHTML={{__html: curatedSongs.title}}></h3>
                                            <h5><span>Curated By: </span><a href={curatedSongs.url} target="_blank" className="text-2">{curatedSongs.created_by}</a></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : 
                            
                            ''
                        }
                        {
                            // curatedSongs  && showCuratedList ? 
                            curatedSongs ? 
                                <div className="arrow-heading">
                                    {
                                        curatedSongs.previous_curated_list ? 
                                        <Link to={`/mp3/spotlight/detail/${curatedSongs.previous_curated_list}`}>
                                            <span>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17 12L7 12" stroke="#ff07b3" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M9.5 9.5L7 12L9.5 14.5" stroke="#ff07b3" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M11.9997 22.8337C6.01659 22.8337 1.16634 17.9834 1.16634 12.0003C1.16634 6.01724 6.01659 1.16699 11.9997 1.16699C17.9828 1.16699 22.833 6.01724 22.833 12.0003C22.833 17.9834 17.9828 22.8337 11.9997 22.8337Z" stroke="#ff07b3" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </span>
                                        </Link> :
                                        <span>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17 12L7 12" stroke="#ff07b3" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M9.5 9.5L7 12L9.5 14.5" stroke="#ff07b3" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M11.9997 22.8337C6.01659 22.8337 1.16634 17.9834 1.16634 12.0003C1.16634 6.01724 6.01659 1.16699 11.9997 1.16699C17.9828 1.16699 22.833 6.01724 22.833 12.0003C22.833 17.9834 17.9828 22.8337 11.9997 22.8337Z" stroke="#ff07b3" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                    }
                                    <h3><span dangerouslySetInnerHTML={{__html: curatedSongs.title}}></span></h3>
                                    {
                                        curatedSongs.next_curated_list ? 
                                        <Link to={`/mp3/spotlight/detail/${curatedSongs.next_curated_list}`}>
                                            <span>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7 12H17" stroke="#ff07b3" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.5 9.5L17 12L14.5 14.5" stroke="#ff07b3" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M12.0003 22.8337C17.9834 22.8337 22.8337 17.9834 22.8337 12.0003C22.8337 6.01724 17.9834 1.16699 12.0003 1.16699C6.01724 1.16699 1.16699 6.01724 1.16699 12.0003C1.16699 17.9834 6.01724 22.8337 12.0003 22.8337Z" stroke="#ff07b3" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </span>
                                        </Link>
                                        :
                                        <span>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 12H17" stroke="#ff07b3" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M14.5 9.5L17 12L14.5 14.5" stroke="#ff07b3" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12.0003 22.8337C17.9834 22.8337 22.8337 17.9834 22.8337 12.0003C22.8337 6.01724 17.9834 1.16699 12.0003 1.16699C6.01724 1.16699 1.16699 6.01724 1.16699 12.0003C1.16699 17.9834 6.01724 22.8337 12.0003 22.8337Z" stroke="#ff07b3" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span> 
                                    }
                                </div>
                                : 
                                    dataProcessing ? 
                                        <SongTrackLoader itemCount={20} /> 
                                    : ''

                            }
                        <div className="inner-block">
                        { 
                            curatedSongs && curatedSongs.songs && showCuratedList ? 
                                <SongTrack songs={curatedSongs.songs} isCuratedSpotlight={true} /> 
                            : 
                                <div className="alert alert-danger">No Song found in this curated list</div>
                                // <div className="alert alert-danger">Your current access to Curated Spotlights is limited. <Link to={`/subscription/plans`} className="text-3">Upgrade</Link> now to enjoy unlimited access to this exclusive feature.</div>
                        }
                        </div>
                    </div>
                </div>
            </MusicDashboard>
        );
}

function mapStateToProps(state) {
	return {
        loggingIn: state.authentication.loggedIn,
        curatedSongs: state.curated.curatedSongs,
        curatedAlbums: state.curated.curatedAlbums,
        userProfile: state.customer.customerProfile,
        curatedItems: state.curated.homeCuratedList
	};
 }
 
function mapDispatchToProps(dispatch) {
	return {
        getCuratedSongs: (data) => dispatch(actions.getCuratedSongs(data)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        getCuratedItems: () => dispatch(actions.getHomeCuratedLists()),
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(CuratedSpotlightDetail);
