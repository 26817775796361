import React, {useState, useEffect} from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import AccountNav from '../../elements/accountElements/accountNav';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import {isDesktop, isMobile, isTablet, browserName, browserVersion, osName, osVersion, isMobileOnly} from 'react-device-detect';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import {axiosReqOther} from "../../services/config";
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {connect} from 'react-redux';
import * as customerAction from '../../store/customer/actions';






const ManageDevice = ({devices, getCustomerProfile, user, userProfile, displayLoading, hideLoading, getUsersDevices, removeUserDevice, themeType}) => {
    const MySwal = withReactContent(Swal)
    const [ processing, setProcessing ] = useState(false);
    const [ customerDeviceId, setCustomerDeviceId ] = useState(false);
    const [ userDeviceInfo, setUserDeviceInfo ] =  useState({
        token: user,
        device_type: isDesktop ? 'desktop' : isMobile ? 'mobile' : 'tablet',
        browser_name: browserName,
        browser_version: browserVersion,
        os_name: osName,
        os_version: osVersion,
        ip_address: '',
    });

    const getIP =  () => {
        // const res = await axios.get("https://api.ipify.org/?format=json");
        let deviceInfo = userDeviceInfo;
        // deviceInfo.ip_address = res.data.ip;
        setUserDeviceInfo(deviceInfo);
    }

    useEffect(() => { 
        displayLoading();
        getUsersDevices({token: user});
        getIP();
        hideLoading();
    },[]);

    const removeDevice = async(deviceId) => {
        setCustomerDeviceId(deviceId);

        setProcessing(true);
        const data = {
            token: user,
            customer_device_info_id: deviceId
        }
        await removeUserDevice(data);
        await getCustomerProfile(userDeviceInfo); 
        setProcessing(true);
    }

    const updateDeviceName = (deviceId, deviceName) => {
        Swal.fire({
            title: 'Update Device Name',
            input: 'text',
            html: '<p>Enter the new device name below </p>',
            inputValue: deviceName,
            // inputAttributes: {
            //   autocapitalize: 'off'
            // },
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Submit',
            allowOutsideClick: true,
            preConfirm: (deviceName) => {
                if(deviceName.length > 30){
                    Swal.showValidationMessage(
                        `Device Name should less then 30 characters`
                    );
                    Swal.enableButtons();
                } else {
                    const data = {
                        token: localStorage.user,
                        user: userProfile.entity_id,
                        device_name: deviceName,
                        customer_device_info_id: deviceId
                    }
                    // // const response = verifyDeviceCode(data);
                    const url = `customer/update-device-name.json?store_id=1&lang_code=en`;
                    return axiosReqOther(url, data, 'post').then(res => { 
                    // window.location.reload(); 
                        getUsersDevices({token: user});                 
                        return res;  
                    }).catch(err => { 
                        // console.log(err.response.data.message.invalid);
                        Swal.showValidationMessage(
                            `Request failed: ${err.response.data.message.invalid}`
                        );
                        Swal.enableButtons(); // Enable the confirm button again.
                    });
                }
            }
        })
    }

  return (
            <div>
                <div className="account-inner-block">
                    <div className="edit-profile">
                        <div className="title-heading">
                            <h3>Device Managment</h3>
                            
                            
                        </div>
                        {/* <p>Clubkillers allows premium users to seamlessly access the platfrom fo any two devices. If you wish access from new device, siply log into Clubkiller account on the new device and follow the on-screen instructions.</p> */}
                        <p className="text-3">Premium Clubkillers users can access the platform on two devices. To use a new device, log into your account and follow the instructions.</p>
                    </div>
                </div>
                <div className="table-content">
                    <Table hover responsive>
                        <thead>
                            <tr>
                            <th>Device</th>
                            <th>Date Added</th>
                            <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {       devices && devices.length > 0 ?
                                    devices.map((device, index) => {
                                        return <tr key={`device-${index}`}>
                                            <td className="device-name"> 
                                                { device.device_name ? device.device_name : device.os_name +'('+ device.device_type+')'}
                                                <span className="actions" onClick={() => updateDeviceName(device.customer_device_info_id, device.device_name)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"/>
                                                    </svg>
                                                </span>
                                            </td>
                                            <td>{device.created}</td>
                                            <td>{ processing && customerDeviceId == device.customer_device_info_id ? <Spinner animation="border" variant="danger" size="sm" /> : <span className="text-link text-2 cp" onClick={() => removeDevice(device.customer_device_info_id)}>Remove</span> }</td>
                                        </tr>
                                    })
                                    : 
                                    ''
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
		
  );
}

function mapStateToProps(state) {
    return {
        devices: state.customer.devices,
        user: state.authentication.user,
        authentication: state.authentication,
        userProfile: state.customer.customerProfile,
        themeType: state.theme.theme,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCustomerProfile: (data) => dispatch(customerAction.getCustomerProfile(data)),
        getUsersDevices: (token) => dispatch(customerAction.getUsersDevices(token)),
        removeUserDevice: (data) => dispatch(customerAction.removeUserDevice(data)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    	hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageDevice);