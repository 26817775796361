import React, {useState} from "react";
import "./mp3Modal.scss";


import Modal from 'react-bootstrap/Modal';

import * as fileContainerAction from '../../store/filecontainer/actions';

import {connect} from 'react-redux';



const SelectSongModal = ({showState, ShowFunction, fileLists, setSong, removeSongFromContainer, getContainerSongsList, themeType}) =>  {
  
    
    const removeUploaderSong = async (song) => {
        await removeSongFromContainer(song.songName.file);
        await getContainerSongsList();
    }

    const handleChange = (item) => {
        setSong(item);
    }

    const closeModel = () => {
        ShowFunction();
    }

    if(fileLists && fileLists.length > 0){
        return(
            <Modal
            show={showState}
            onHide={ShowFunction}
            backdrop="static"
            keyboard={false}
            size="md"
            >
                <Modal.Header>
                <Modal.Title>Select Song</Modal.Title>
                <span aria-label="Close" onClick={closeModel}>
                    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 0.5L9 8.5M9 0.5L1 8.5" stroke="#000" strokeLinecap="round"/>
                    </svg>
                </span>
                </Modal.Header>
                <div className="p-4">
                        {   fileLists && fileLists && fileLists.length > 0 ? fileLists.map((item, i) => {
                                return <div key={i} className="selectsong-row">
                                    <div className="radio-contniner">
                                    
                                        <input type="radio" name="song" value={item.file} onChange={ () => handleChange(item)}  />{' '}
                                    
                                    </div>
                                    <div className="maring-left-auto cp" onClick={() => setSong(item)} dangerouslySetInnerHTML={{__html: item.file}} />
                                    <div className="ml-auto p-2 d-flex">
                                        <span onClick={(e) => removeUploaderSong({songName: item})}  >
                                            <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 0.5L9 8.5M9 0.5L1 8.5" stroke={ themeType == 'light-theme' ? '#D7D7D7' : "#000" } strokeLinecap="round"/>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            }) : ''
                        }
                        
                        
                    </div>
        </Modal>
        )  
    } else {
        return null
    }
}

function mapStateToProps(state) {
  return {
    themeType: state.theme.theme,
  };
}
 
function mapDispatchToProps(dispatch) {
  return {
    getContainerSongsList: () => dispatch(fileContainerAction.getContainerSongsList()),
    removeSongFromContainer: (fileName) => dispatch(fileContainerAction.removeSongFromContainer(fileName)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectSongModal);