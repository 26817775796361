import React, {useEffect, useState} from "react";

//custom components
import MusicDashboard from '../../layouts/musicDashboard';
import SongTrack from '../../components/songTrack/songTrack';
import RightSide from "../../components/rightSide/rightSide";
import Spinner from 'react-bootstrap/Spinner';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import SongTrackLoader from '../../components/loader/songTrackLoader';
import Pagination from "react-js-pagination";

import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

import { useLocation, useParams, useNavigate } from "react-router-dom";
import {connect} from 'react-redux';
import * as songActions from '../../store/song/actions';
import * as genreActions from '../../store/genres/actions';
import * as versionAction from '../../store/version/actions'


const  SearchResultMp3 = ({getSearchResultSongs, searchResult, paginationData, displayLoading, hideLoading, genres, getGenresWithCount, versions, getVersions }) => {
  let match = useParams("/search/result/:searchTerm");
  const location = useLocation();
  const navigate = useNavigate();
  const [ processingFilter, setProcessingFilter ] = useState(false);
  const [ page, setPage ] = useState(1);
  const [ order, setOrder ] = useState('entity_id');
  const [ orderDir, setOrderDir ] = useState('DESC');
  const [ songSectionId, setSongSectionId ] = useState(1);
  const [currentGenreId, setCurrentGenreId] = useState();
  const [ searchTerm, setSearchTerm ] = useState();
  const [ searchModel , setSearchModel ] = useState({
    min_bpm: '',
    max_bpm: '',
    song_key: '',
    year: '',
    title: '',
    artist: '',
    genres_ids: '',
  })
  const [ selectedVersion, setSelectedVersion ] = useState();
  const [ processing, setProcessing ] = useState(false);
  const [ toggleAdnaceSearch, setToggleAdnaceSearch] = useState(false);
  // if(genres && genres.length > 0){
    const [genreCheckboxes, setGenreCheckboxes] = useState();
  // }
  const [genresArraySelected, setGenresArraySelected] = useState([]);
  // console.log(sgCheckboxes)
  useEffect(() => { 
    // load Page Data
    loadPageData();
  },[navigate]);

  useEffect(() =>{
    if(genres.length > 0){
        const selectedGenres = genres.map((item) => ({ ...item, checked: false }));
        setGenreCheckboxes(selectedGenres);
    }
  }, [genres])
  
  
  const loadPageData = async() => { 
    setProcessing(true);
    displayLoading();
    if(match && match.searchTerm){
      setSearchTerm(match.searchTerm);
      await getSearchResultSongs({page: page, order: order, orderDir, song_section_id: songSectionId, searchTerm: match.searchTerm});
    } else {
      setToggleAdnaceSearch(!toggleAdnaceSearch);
    }
    setCurrentGenreId('all');
    setSelectedVersion('0');
   
    await getVersions();
    await getGenresWithCount();    
    hideLoading();
    setProcessing(false);
		
  }
  const handlePageChange = (pageNumber) => {
      setPage(pageNumber);
      setProcessing(true);
      displayLoading();
      if(match){
        if(currentGenreId === 'all'){
          getSearchResultSongs({page: pageNumber, order: order, orderDir, song_section_id: songSectionId, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: selectedVersion}).then((res) => {
            hideLoading();
            window.scrollTo({top: 150});
            setProcessing(false);
          })
        } else {
          getSearchResultSongs({page: pageNumber, order: order, orderDir, song_section_id: songSectionId, genre_id: currentGenreId, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: selectedVersion}).then((res) => {
            hideLoading();
            window.scrollTo({top: 150});
            setProcessing(false);
          })
        }
      }
      
  }

  const addOrRemoveGenres = (value) => {
    const selectedGenres = [...genresArraySelected];
    const index = selectedGenres.indexOf(value); // Check if value exists in the array
    if (index !== -1) {
      // Value already exists in the array, so remove it
      selectedGenres.splice(index, 1);
    } else {
      // Value does not exist in the array, so add it
      selectedGenres.push(value);
    }
    // console.log(selectedGenres, value)
    setGenresArraySelected(selectedGenres);
    setSearchModel({
      ...searchModel,
      genres_ids: selectedGenres.join(',')
    })
  };

  const removeGenres =  async(value, name) => {
    let selectedGenres = [...genresArraySelected];
    if(selectedGenres.length > 0){
      
      for(let i=0; i<value.length;i++){
        const index = selectedGenres.indexOf(value[i]);
        if (index !== -1) {
          // Value already exists in the array, so remove it
          selectedGenres.splice(index, 1);
        }
      }
      console.log(selectedGenres);
      setGenresArraySelected(selectedGenres);
      
    }
  }

  const genresSong = async(genre_id) => {
    
    const updatedGennresCheckboxes = genreCheckboxes.map((checkbox) =>
          checkbox.genre_id === genre_id ? { ...checkbox, checked: !checkbox.checked } : checkbox
    );
    setGenreCheckboxes(updatedGennresCheckboxes);

    const matchedItems = [];
    updatedGennresCheckboxes.map((checkbox1) => {
      
      if(genre_id == checkbox1.genre_id && !checkbox1.checked){
        checkbox1.subgenre.map((sg) => {
          if(genresArraySelected.includes(sg.genre_id)){
            matchedItems.push(sg.genre_id)
          }
        })
      }
    })
    removeGenres(matchedItems);
    
    if(match){
      // getSearchResultSongs({page: 1, order: 'entity_id', orderDir: 'DESC', song_section_id: songSectionId, genre_id: genre_id, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: selectedVersion}).then((res) => {
      //   hideLoading();
      //   window.scrollTo({top: 150});
      //   setProcessing(false);
      // })
    }
  }
  const getAllSong = () => {
    setProcessing(true);
    setCurrentGenreId('all')
    displayLoading();
    getSearchResultSongs({page: 1, order: 'entity_id', orderDir: 'DESC', song_section_id: songSectionId, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: selectedVersion}).then((res) => {
      hideLoading();
      window.scrollTo({top: 150});
      setProcessing(false);
    });
  }

  const handleChangeSearchTerm = (event) => {
    setSearchTerm(event.target.value);
    
  }

  const handleChangeSearch = (e) => {
    const { name, value } = e.target;
        setSearchModel({
            ...searchModel,
            [name]: value
        });
  }

  const filterResult = async () => {
    displayLoading();
    setProcessing(true);
    setProcessingFilter(true);
    if(currentGenreId === 'all'){
      getSearchResultSongs({page: 1, order: order, orderDir, song_section_id: songSectionId, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: selectedVersion}).then((res) => {
        hideLoading();
        setProcessingFilter(false);
      })
    } else {
      getSearchResultSongs({page: 1, order: order, orderDir, song_section_id: songSectionId, genre_id: currentGenreId, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: selectedVersion}).then((res) => {
        hideLoading();
        setProcessingFilter(false);
      })
    }
    window.scrollTo({top: 150});
    setProcessing(false);
  }

  const getAllVersionSong = async(versionId) => {
    setProcessing(true);
    displayLoading();
    setSelectedVersion(versionId);
   
    getSearchResultSongs({page: 1, order: order, orderDir, song_section_id: songSectionId, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: versionId}).then((res) => {
      hideLoading();
    });
    window.scrollTo({top: 150});
    setProcessing(false);
  }

  return (
  <MusicDashboard rightContent={<RightSide blocks={{ top30TrendingTracks: true}} />} middleSectionCssClass={'account-info-sec search-result-sec'}>
        <div className="content-sec">
            <div className="play-list-sec account-block">
                <div className="top-heading">
                    <h2>Search Results</h2>
                    <span className="text-2 cp btn-primary primary-btn" style={{height: '30px'}} onClick={() => setToggleAdnaceSearch(!toggleAdnaceSearch)}>Advance Search</span>
                </div>
                <div className="inner-block account-inner-block search-result">
                  { toggleAdnaceSearch ?
                  <div>
                    <div className="cardMark mb-3 d-flex justify-content-between align-items-center">
                        <span>Filter Result By</span> 
                        { processingFilter ? <button className="primary-btn"><Spinner animation="border" variant="dark" size="sm" /></button> : <button className="primary-btn" onClick={() => filterResult()}>Apply Filter</button>} 
                    </div> 
                    <div>
                    <div className="form-content ">
                        <InputGroup className="form-group">
                            <Form.Control
                            placeholder="Search for songs or anything..."
                            aria-label="Search"
                            aria-describedby="Search"
                            onChange={handleChangeSearchTerm}
                            value={searchTerm}
                            />
                        </InputGroup>
            
                        <InputGroup className="form-group">
                                <Form.Control
                                placeholder="Search by song name"
                                aria-label="songTitle"
                                aria-describedby="songTitle"
                                onChange={handleChangeSearch}
                                value={searchModel.title}
                                name="title"
                                />
                            </InputGroup>
                    
                    <InputGroup className="form-group">
                            <Form.Control
                            placeholder="Search by artist"
                            aria-label="artist"
                            aria-describedby="artist"
                            onChange={handleChangeSearch}
                            value={searchModel.artist}
                            name="artist"
                            />
                        </InputGroup>
                    <div className="form-group">
                    <InputGroup>
                            <Form.Control
                            placeholder="Min-BPM"
                            aria-label="BPM"
                            aria-describedby="BPM"
                            onChange={handleChangeSearch}
                            value={searchModel.bpm}
                            name="min_bpm" style={{maxWidth: '90%'}}
                            />
                        </InputGroup>
                        <InputGroup>
                            <Form.Control
                            placeholder="Max-BPM"
                            aria-label="BPM"
                            aria-describedby="BPM"
                            onChange={handleChangeSearch}
                            value={searchModel.bpm}
                            name="max_bpm" style={{maxWidth: '98%'}}
                            />
                        </InputGroup>
                    </div>
                    <InputGroup className="form-group">
                            <Form.Control
                            placeholder="Search by year"
                            aria-label="songTitle"
                            aria-describedby="songTitle"
                            onChange={handleChangeSearch}
                            value={searchModel.year}
                            name="year"
                            />
                        </InputGroup>
                    
                    <InputGroup className="form-group">
                            <Form.Control
                            placeholder="Search by song key"
                            aria-label="artist"
                            aria-describedby="artist"
                            onChange={handleChangeSearch}
                            value={searchModel.song_key}
                            name="song_key"
                            />
                        </InputGroup>
                    </div>
                    <div className="cardMark mb-3 d-flex justify-content-between align-items-center"><span>Filter By Genre</span></div>
                        <div className="top-list">
                        { genreCheckboxes && genreCheckboxes.length > 0 ?
                            <ul className="badgeList d-none d-lg-block mb-4">
                                {/* <li className={currentGenreId === 'all' ? 'active cp' : 'cp' }><span onClick={() => getAllSong('0')}>All</span></li> */}
                                {
                                genreCheckboxes.map((genre, index)=>{
                                  if(genre.total > 0){
                                    return <li key={`genre-filter-${genre.genre_id}`} className={genre.checked ? 'active cp' : 'cp' }><span onClick={() => genresSong(genre.genre_id)} dangerouslySetInnerHTML={{__html:genre.name}}></span></li>
                                  } else{
                                    return null;
                                  }
                                })
                                }
                            </ul>
                            : ''
                        }
                        </div>
                        <div className="top-list">
                        { genreCheckboxes && genreCheckboxes.length > 0 ?
                            <ul className="badgeList d-none d-lg-block mb-4">
                                {/* <li className={currentGenreId === 'all' ? 'active cp' : 'cp' }><span onClick={() => getAllSong('0')}>All</span></li> */}
                                {
                                genreCheckboxes.map((genre, index)=>{
                                  return genre.checked ? genre.subgenre.map((sg, sindex) => {
                                    if(sg.total > 0){
                                      return <li key={`genre-filter-${sg.genre_id}`} className={ genresArraySelected.includes(sg.genre_id) ? 'active cp' : 'cp' } onClick={() => addOrRemoveGenres(sg.genre_id)}><span dangerouslySetInnerHTML={{__html:sg.name}}></span></li>
                                    } else {
                                      return null;
                                    }
                                  }) : null
                                    // return <li key={`genre-filter-${genre.genre_id}`} className={genre.checked ? 'active cp' : 'cp' }><span onClick={() => genresSong(genre.genre_id)} dangerouslySetInnerHTML={{__html:genre.name}}></span></li>
                                })
                                }
                            </ul>
                            : ''
                        }
                        </div>
                    </div>
                    <div className="cardMark mb-3 d-flex justify-content-between align-items-center"><span>Filter By Version</span></div>
                    <div className="top-list">
                    { 
                        versions && versions.length > 0 ?
                            <ul className="">
                                <li className={selectedVersion === '0' ? 'active cp' : 'cp' }><span onClick={() => getAllVersionSong('0')}>All</span></li>
                                {
                                versions.map((version, index)=>{
                                    return <li key={`version-filter-${version.version_id}`} className={selectedVersion === version.version_id ? 'active cp' : 'cp' }><span  onClick={() => getAllVersionSong(version.version_id)}>{version.name}</span></li>
                                })
                                }
                            </ul>
                        : ''
                    }
                    </div>
                  </div>
                : ''}
                  { processing ? <SongTrackLoader itemCount={20} /> : <SongTrack songs={searchResult}   /> }
                </div>
            </div>
        
            <div className="pagingnation-sec">
            {
            paginationData ? 
                <Pagination
                    activePage={paginationData.page}
                    itemsCountPerPage={paginationData.perPageLimit}
                    totalItemsCount={paginationData.totalCount}
                    // pageRangeDisplayed={pageRangeDisplayed}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
            /> : ''
            }
            </div>
        </div>
    </MusicDashboard>
  );
}


function mapStateToProps(state) {
  return {
    searchResult: (state.song.searchResult && state.song.searchResult.songs ) ? state.song.searchResult.songs : [],
    paginationData:  (state.song.searchResult && state.song.searchResult.pagination) ? state.song.searchResult.pagination : '',
    genres: state.genre.genresWithCount,
    top20Albums: state.song.top20Albums,
    versions: state.version.versions
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getSearchResultSongs: (post) => dispatch(songActions.getSearchResultSongs(post)),
    getGenresWithCount: () => dispatch(genreActions.getGenresWithCount()),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    getVersions: () => dispatch(versionAction.getVersions())
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultMp3);