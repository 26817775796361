import React, { useState, useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import {  useNavigate } from "react-router-dom";
import config from '../../config/config';
import { checkCustomer } from '../../components/utlity/checkCustomer';
import {connect} from 'react-redux';
import * as dropboxActions from '../../store/dropbox/actions';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const DownloadAllButton = ({ addSongEntityToDropBox, userProfile, entity_id, dropbox, themeType }) => {

    const MySwal = withReactContent(Swal)
    const navigate = useNavigate();
    const [ downloadInProcess, setDownloadInProcess ] = useState(false);
    const [ canAccess, setCanAccess ] = useState(false);
    
    useEffect(() => { 
        if(checkCustomer(userProfile)){
            setCanAccess(true);
        }
    },[userProfile])

    const addSongToDropBox = (entity_id) => { 
        setDownloadInProcess(true);
        if(canAccess){
            processAddRemove(entity_id);
        } else {
            navigate('/subscription/plans')
        }
        setDownloadInProcess(false);
    }

    const processAddRemove = async (entity_id) => {
        await addSongEntityToDropBox({ entity_id: entity_id}).then(res => {
            setDownloadInProcess(false);
            if(res.payload.response && res.payload.response.status === 403){
              MySwal.fire({
                title: "Error",
                text: res.payload.response.data.message,
                type: "info",
                allowOutsideClick: false,
            });
            } else if(res.payload.data) {
                
            }
        });
    }
    
    if(downloadInProcess){
        return <Spinner animation="border" variant="danger" size="sm" />
    } else {
        return (
            <span onClick={() => addSongToDropBox(entity_id)} className="cp" title={ dropbox && dropbox.songs && dropbox.songs.length > 0 && dropbox.songs.indexOf(entity_id.toString()) >= 0 ? "Remove from Dropbox Queue" : "Add to Dropbox Queue"}>
                <svg width="24" height="25" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.5 6.16667L5.66667 8.825L9.83333 6.16667L5.66667 3.5L1.5 6.16667ZM9.83333 6.16667L14 8.825L18.1667 6.16667L14 3.5L9.83333 6.16667ZM1.5 11.4583L5.66667 14.1167L9.83333 11.4583L5.66667 8.79167L1.5 11.4583ZM14 8.79167L9.83333 11.4583L14 14.1167L18.1667 11.4583L14 8.79167ZM5.69167 15L9.86667 17.6667L14.0333 15L9.86667 12.3417L5.69167 15Z" fill={ dropbox && dropbox.songs && dropbox.songs.length > 0 && dropbox.songs.indexOf(entity_id.toString()) >= 0 ? '#EB4E27' :  themeType == 'dark-theme' ? "#D7D7D7": '#282A2D' } />
                </svg>  
            </span>
        )
    }
    
}
function mapStateToProps(state) {
    return {
        userProfile: state.customer.customerProfile,
        dropbox: state.dropbox.dropboxSongs.dropbox,
        themeType: state.theme.theme,
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
        addSongEntityToDropBox: (data) => dispatch(dropboxActions.addSongEntityToDropBox(data)),
        getDropboxSongs: () => dispatch(dropboxActions.getDropboxSongs()),
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(DownloadAllButton);
  