import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';
import CoverImage from './../../../assets/images/make-the-kut/Cover.png';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as actions from '../../../store/mtk/actions';
import MTKSongTrack from "../../songTrack/mtkSongTrack";
import { Link } from "react-router-dom";
const  LatestUploadThisWeek = ({ displayLoading, hideLoading, getLatestUploadsThisWeek, latestUploadThisWeek }) => {

	
	useEffect(() => { 
        displayLoading();
        getLatestUploadsThisWeek()
        hideLoading();
    },[]);
    // console.log('latestUploadThisWeek', latestUploadThisWeek)
    return (
        <div class="bg-block">
            <div className="heading-sec">
                <h2><span>Latest uploads</span> this week </h2>
                <Link class="view-more" to={`/make-the-kut/latest`}>View more 
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 14L11.9038 10.2154C11.9342 10.1878 11.9584 10.1544 11.9749 10.1174C11.9915 10.0803 12 10.0404 12 10C12 9.95963 11.9915 9.91969 11.9749 9.88265C11.9584 9.8456 11.9342 9.81224 11.9038 9.78462L8 6" stroke="#F4FD84" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </Link>
            </div>
            {
                latestUploadThisWeek && latestUploadThisWeek.songs &&  latestUploadThisWeek.songs.length > 0 ?
                    <MTKSongTrack songs={latestUploadThisWeek.songs} />
                    
                : ''
            }
        </div>
    );
  
}


function mapStateToProps(state) {
	return {
        latestUploadThisWeek: state.mtk.latestUploadThisWeek
	};
 }
 
 function mapDispatchToProps(dispatch) {
	return {
        getLatestUploadsThisWeek:  ()  => dispatch(actions.getLatestUploadsThisWeek()),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(LatestUploadThisWeek);
