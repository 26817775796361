import React, { useEffect, useState} from "react";

//custom components
import Table from 'react-bootstrap/Table';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import AccountNav from '../../elements/accountElements/accountNav';
import Pagination from "react-js-pagination";
import MusicDashboard from '../../layouts/musicDashboard';
import {connect} from 'react-redux';
import * as actions from '../../store/song/actions';
import { useNavigate, useParams } from "react-router-dom";

const  SearchReport = ({ getSongReport, songReport, displayLoading, hideLoading}) => {

    let match = useParams("/mp3/download-report/genre/:genre_id");
    const navigate = useNavigate();

    const [genreId, setGenreId] = useState();
    const [ page, setPage ] = useState(1);
    const [ order, setOrder ] = useState('id');
    const [ orderDir, setOrderDir ] = useState('DESC');
    const [ pageLimit, setPageLimit ] = useState(20);
    const [ songSectionId, setSongSectionId ] = useState(1);
    const [ processing, setProcessing] = useState(false);
    const [ currentGenre, setCurrentGenre ] = useState();

    useEffect(() => { 
        setProcessing(true);
            displayLoading();
            getSongReport({page: 1, order: order, orderDir: orderDir, pageLimit: pageLimit, song_sectoin_id: songSectionId}).then((res) => {
                
            });
            setProcessing(false);
            hideLoading();
            window.scrollTo({top: 0});
    },[navigate]);
    

    const handlePageChange = async (pageNumber) => {
        setProcessing(true);
        displayLoading();
        setPage(pageNumber);
        await getSongReport({page: pageNumber, order: order, orderDir: orderDir, song_section_id: songSectionId});
        hideLoading();
        setProcessing(false);
    }
    
    
  return (
    <MusicDashboard accountPages={true} middleSectionCssClass={"account-info-sec"}>
        <div className="content-sec">
            <div className="account-block">
                <div className="top-heading">
                    <h2>Account Setting</h2>
                </div>
                <AccountNav/>
            </div> 
            <div className="play-list-sec">
                <div className="top-heading">
                
                    <div className="back-arrow-head">
                            <span className="cp" onClick={() => {
                                setPage(1);
                                navigate(-1)
                            }}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 6L8.09618 9.78462C8.06582 9.81224 8.04162 9.8456 8.02508 9.88265C8.00853 9.91969 8 9.95963 8 10C8 10.0404 8.00853 10.0803 8.02508 10.1174C8.04162 10.1544 8.06582 10.1878 8.09618 10.2154L12 14" stroke="#080A0D" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                            </span>
                            <h2>SONG SEARCH RESULTS</h2>
                        </div>
                   
                </div>
                <div className="inner-block">
                    <div className="table-content">
                        <Table hover responsive>
                            <thead>
                                <tr>
                                <th>Email</th>
                                <th>Keyword</th>
                                <th>Title</th>
                                <th>Artist</th>
                                <th>Version</th>
                                <th>Genre</th>
                                </tr>
                            </thead>
                            {
                                songReport && songReport.results && songReport && songReport.results.length > 0 ?
                                <tbody>
                                    {
                                        songReport && songReport.results.map((record, index)=>{
                                            return <tr key={`songReport${index}`}>
                                                    <td>{record.email}</td>
                                                    <td>{record.keyword}</td>
                                                    <td>{record.title}</td>
                                                    <td>{record.artist}</td>
                                                    <td>{record.version}</td>
                                                    <td dangerouslySetInnerHTML={{__html: record.category_genre}}></td>
                                            </tr>
                                        })
                                    }	
                                    
                                </tbody>
                                : ''
                            }
                            {
                                songReport && songReport.length == 0 ?
                                    <tbody><tr><td>You have no billing details.</td></tr></tbody>
                                :
                                ''
                            }
                        </Table>
                    </div>
                    
                
                    
                </div>
            </div>
            <div className="pagingnation-sec">
                    {
                        songReport && songReport.pagination ? 
                            <Pagination
                                activePage={songReport.pagination.page}
                                itemsCountPerPage={songReport.pagination.perPageLimit}
                                totalItemsCount={songReport.pagination.totalCount}
                                // pageRangeDisplayed={pageRangeDisplayed}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                        /> : ''
                    }
            </div>
        </div>
    </MusicDashboard>
    )
}

function mapStateToProps(state) {
    return {
        songReport: state.song.songReport,
        loggingIn: state.authentication.loggedIn,
    };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getSongReport: (data) => dispatch(actions.getSongReport(data)),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
	hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(SearchReport);