
import React, {useEffect, useState} from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import AccountNav from '../../elements/accountElements/accountNav'; 
import config from '../../config/config';
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
// import NumberFormat from 'react-number-format';
import { PatternFormat } from 'react-number-format';

import Spinner from 'react-bootstrap/Spinner';   
import InputGroup from 'react-bootstrap/InputGroup';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {connect} from 'react-redux';
import * as subscriptionAction from '../../store/subscription/actions'
import * as couponAction from '../../store/coupon/actions';
import * as commonActions from '../../store/common/actions';

import { CanAddApplePay } from './canAddApplePay';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
// const stripePromise = loadStripe("pk_test_51OLGLVIGZoAJE3zoVNK92g4VQb8NcKFUjvjd2PEF57Wx80WlodBGujIuG6ZCKcwDvmQvZVVUS2aM4R7vhKDMrAMQ00ZvCI53G7");
// const stripePromise = loadStripe("pk_live_51OLGLVIGZoAJE3zoNZ9pjfcJOh35nAjKJgpySzh30wPuu6OVWxr5h2ZAclDRESR8QnkY0HI5HTvzzrZDfqvJUukw00C8ax3Qs2");


const PaymentMethod1 = ({ displayLoading, hideLoading, getServices, plans, validateCoupon, getCustomerAgreement, agreement, processCardPaymentMethod, processPaypalPayment, getPlanDetails, customerProfile, subscriptionDetail, processApplePayment, getCountryList, countryList }) => {
    
    let match = useParams("/subscription/payment-method/:plan_id");
    const location = useLocation();
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const [ planId, setPlanId ] = useState();
    const [ subscriptionData, setSubscriptionData ] = useState();
    const [ paymentMethod, setPaymentMethod ] = useState();
    const [ couponCode, setCouponCode ] = useState();
    const [inputValue, setInputValue] = useState('');
    const [ coupon, setCoupon ] = useState();
    const [region, setRegion] = useState();
    const [isCountrySelected, setIsCountrySelected] = useState(false);
    const [formDataEnter, setFormDataEnter] = useState(false);
    const [ formSubmited, setForrmSubmited ] = useState(false);
    const [formData, setFormData] = useState({
        card_number: '',
        card_type: '',
        expire_date: '',
        ccv: '', 
        payment_method: '', 
        terms_condition: '',
        street:'',
        city:'',
        state: '',
        zip_code:'',
        country:''
    });
    const visaTypeOptions = [
        {label: "Visa", value: "Visa"},
        {label: "MasterCard", value: "MasterCard"},
        {label: "Discover", value: "Discover"},
        {label: "Amex", value:"Amex"}
    ];
    const [ processCardPayment, setProcessCardPayment ] = useState(false);
    const [ errors, setErrors ] = useState({});
    const [ validated, setValidated] = useState(false);
    const [ couponApplied, setCouponApplied ] = useState(false);
    const [ selectedPlan, setSelectedPlan] = useState();
    const [ value, setValue] = useState();
    const [processCoupon, setProcessCoupon ] = useState(false)
    const [couponValidationError, setCouponValidationError] = useState();
    const [ isStaff, setIsStaff ] = useState(false)

    /* Stripe Card payment */
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const cardElementStyles = {
        style: {
          base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            background: '#303030',
            border: '1px solid #282A2D',
            borderRadius: '5px',
            padding: '12px 5px',
            transition: 'all 0.5s',
            fontSize: '16px',
            '::placeholder': {
              color: '#32325d',
            },
            
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
          },
          complete: {
            color: '#4caf50',
          },
        },
      };
    /* Stripe Card payment end */


    useEffect(() => { 
		// load Page Data
		loadPageData();
    },[]);
    
    useEffect(() => { 
        getCountryList();
    },[]);

    useEffect(() => { 
        if(customerProfile && formDataEnter === false){
            setFormData({
                firstname: customerProfile && customerProfile.firstname ? customerProfile.firstname : '',
                lastname: customerProfile && customerProfile.lastname ? customerProfile.lastname : '',
                street: customerProfile && customerProfile.street ? customerProfile.street : '',
                country_id: customerProfile && customerProfile.country_id ? customerProfile.country_id : '',
                region_id: customerProfile && customerProfile.region_id ? customerProfile.region_id : '',
                city: customerProfile && customerProfile.city ? customerProfile.city : '',
                postcode: customerProfile && customerProfile.postcode ? customerProfile.postcode : '',
                email: customerProfile && customerProfile.email ? customerProfile.email : '',
                dj_name: customerProfile && customerProfile.dj_name ? customerProfile.dj_name : '',
            });
            setFormDataEnter(true);
            setInputValue(customerProfile && customerProfile.city ? customerProfile.city : '')
        }
        if(isCountrySelected == false && customerProfile &&  countryList && countryList.length > 0){
            const selectedCountry = countryList.find((element) => {
                return element.id === customerProfile.country_id;
            });
            if(selectedCountry && selectedCountry.region){
                setRegion(selectedCountry.region)
                setIsCountrySelected(true);
            }
            
            
        }
    },[customerProfile])
    
    useEffect(() => { 
		if(match.plan_id){
            setPlanId(match.plan_id)
        }else{
            navigate("/subscription/plans");
        }
    },[location]);

    useEffect(() => {
        if(customerProfile && customerProfile.group_id == 3){
            
            var d1 = new Date();
            var d2 = new Date(customerProfile.staff_membership_expire_date);
            if(d2.getTime() > d1.getTime()){
                setIsStaff(true);
            }
        }
    },[customerProfile]);

    // Profile is already created and paymment processing then redirect them to plan page
    if(agreement && agreement.profile_status == 'CREATED'){
        navigate('/subscription/plans');
    }

    const loadPageData = async() => {
        
        displayLoading();
        await getServices({subscription_type_id: 1}).then((res) => {
            console.log('aa', res);
            if(res && res.payload && res.payload.data && res.payload.status == 'true'){
                let selectedPlanFromRes = res.payload.data.find(  service  => service.id ==  match.plan_id);
                selectedPlanFromRes = {
                    id: 27
                }
                setSelectedPlan(selectedPlanFromRes);
                getPlanDetails({coupon: coupon,  subscription: selectedPlanFromRes}).then(res => {
                
                })
            }
        });
        await getCustomerAgreement();
        
        hideLoading();
        window.scrollTo({top: 0});
    }

    const setPromoCode = (event) => {
        const { name, value } = event.target;
        setCouponCode(value)
    }

    const checkCoupon = async() => {
        if(couponCode){
            setProcessCoupon(true);
            // call Validate coupon code
            await validateCoupon({coupon_code: couponCode, subscription_id: planId}).then((res)=>{
                if(res && res.payload && res.payload.data && res.payload.data.status && res.payload.status == 'true'){
                    setCoupon(res.payload.data);
                    setCouponApplied(true);
                    getPlanDetails({coupon: res.payload.data,  subscription: selectedPlan}).then(res => {
                    })
                   
                } else {
                    setCoupon('');
                    setCouponApplied(false);
                    setCouponValidationError(res.payload.message.errors[0]);
                    // Swal.fire({
                    //     icon: 'error',
                    //     title: "Invalid Coupon!", 
                    //     text: res.payload.message.errors[0],
                    //     type: "error", 
                    //     closeOnConfirm: true
                    // }, function(){
                        
                    // });
                }
            });
            setProcessCoupon(false);
           
            // setCouponApplied(true)
        } else {
            // Swal.fire({
            //     icon: 'error',
            //     title: "Invalid Coupon!", 
            //     text: 'Please enter coupon code',
            //     type: "error", 
            //     closeOnConfirm: true
            // }, function(){
                
            // });
            setCouponValidationError('Please enter coupon code');
        }
    }

    const handleCreditCardSubscription = async (token) => {
        const response = await fetch('/create-subscription', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });
    
        const data = await response.json();
        setForrmSubmited(false);
        console.log(data);
    };

    const processPayment = async (tokenId) => { 
        displayLoading();
        setProcessCardPayment(true);
        let purchasePlan = selectedPlan;
        purchasePlan.payment_method = paymentMethod;
        
        await processPaypalPayment({subscription: purchasePlan, coupon: coupon}).then((res) => {
            if(res && res.payload && res.payload.data && res.payload.data.status && res.payload.status == 'true'){
                hideLoading();
                window.location.href = res.payload.data.paypal;
                setProcessCardPayment(false);
            }
        });
        
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setFormData({
            ...formData,
            [name]: value
        });  
        
        if(name == 'payment_method'){
            setPaymentMethod(value);
            setForrmSubmited(false);
        }
        if(formSubmited && paymentMethod && paymentMethod == 'credit_card'){
            const newErrors = findFormErrors();
            setErrors(newErrors)
        }
        
    }

    const findFormErrors = () => {
        const { payment_method, terms_condition } = formData
        const newErrors = {}

        if( !payment_method || payment_method === '')  newErrors.payment_method = 'Select a payment method'
        if( !terms_condition || terms_condition === '')  newErrors.terms_condition = 'Terms & Condition is require'

        return newErrors
    }
    const handleSubmit = async (event) => { 
        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        setForrmSubmited(true);
        event.stopPropagation();
        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors);
            setForrmSubmited(false);
        } else {
            console.log(formData)
                if(formData.payment_method == 'credit_card'){
                    setError('');
                    const cardElement = elements.getElement(CardElement);
                    const { error, token } = await stripe.createToken(cardElement);
                    console.log('error:' ,error, 'token:', token);
                    if (!stripe || !elements) {
                        return;
                    }
                    if (error) {
                        setError(error.message);
                        setForrmSubmited(false);
                    } else {
                        handleCreditCardSubscription(token.id);
                    }
                } else {
                    processPayment();
                }
            }
    }

    const displayTermsCondition = () => {
        MySwal.fire({
            title: "<div class='terms-title'>Terms & Condition</div>",
            html: "<div class='terms-content'><p>Before proceeding you must agree to Club Killers, Inc terms and conditions</p>"+
            "<ul>"+
            "<li>You agree each bill is automatically deducted from your checking or savings account based on your selected plan. You’ll never be bothered with having to remember to pay your bill. Club Killers takes care of it for you, even when you’re away on vacation.</li>"+
            "<li>It is your responsibility to: Ensure there are sufficient funds available in your checking or savings account to meet each withdrawal on the due date. If there are insufficient funds in your account, your financial institution may charge you a fee and/or interest. You are solely responsible for paying any fee and/or interest charged by your financial institution.</li>"+
        "</ul></div>",
            type: "info",
            allowOutsideClick: false,
        });
    }

    const processWalletPayment = (event) => {
            processApplePayment({applePay: event, coupon: coupon, subscription: subscriptionDetail}).then( res => {
                if(res && res.payload && res.payload.data && res.payload.data.status && res.payload.data.status == true){
                    event.complete('success');
                    navigate(`/subscription/success/${res.payload.data.agreement.stripe_subscription_id}`);
                } else {
                    event.complete('fail');
                    navigate(`/subscription/failed`);
                }
                
            });
    }
    
    return (
        <MusicDashboard middleSectionCssClass={"account-info-sec full-section"}>
            <div className="content-sec">
                <div className="account-block">
                    <div className="top-heading">
                        <h2>Account Setting</h2>
                    </div>
                    <AccountNav/> 
                    <div className="account-inner-block">
                    {
                            isStaff ? <div className="alert alert-danger">You have landed on wrong page. Please contact to <a href="mailto: info@clubkillers.com" className="text-3">Support</a> for more details</div>
                        :
                        <div className="subscription-sec m-auto payment-method-sec">
                        <div className="top">
								<h5>Hi, {customerProfile.dj_name}! You haven’t subscribed yet</h5>
								<h1>Choose your payment method</h1>
								<p className="text-3">Please review your membership details and billing information to complete your order.</p>
							</div>
							<div className="payment-block">
                                
								<div className="left">
                                    <Form  validated={validated} onSubmit={handleSubmit}>
                                        <div className="pay-sec">
                                            <h5>Select payment method</h5>
                                                {/* <Form.Check 
                                                    name="payment_method"
                                                    type="radio" 
                                                    className=""
                                                    id={`wallet`}
                                                    label={'Google/Apple Pay' }
                                                    onChange={handleChange} 
                                                    value="mobile_wallet" 
                                                    isInvalid={ !!errors.payment_method }
                                                    
                                                /> */}
                                                <Form.Check 
                                                        name="payment_method"
                                                        type="radio"
                                                        id={`paypal`}
                                                        label={'PayPal'}
                                                        onChange={handleChange}
                                                        value="paypal"
                                                        isInvalid={ !!errors.payment_method }
                                                    />
                                                <Form.Check 
                                                        name="payment_method"
                                                        type="radio"
                                                        id={`creditcard`}
                                                        label={'Credit Card'}
                                                        onChange={handleChange}
                                                        value="credit_card" 
                                                        isInvalid={ !!errors.payment_method }
                                                    />
                                                
                                        </div>
                                    
                                            {
                                                paymentMethod && paymentMethod == 'paypal' ?
                                                    <div className="paypal-payment">
                                                        <span>We'll redirect you to PayPal to complete your purchase.</span>
                                                    </div>
                                                : ''
                                            }
                                            <Form.Control.Feedback type="invalid">
                                                { errors.payment_method }
                                            </Form.Control.Feedback>
                                            {/* { 
                                                paymentMethod && paymentMethod == 'credit_card' ?
                                                <CardElement />
                                            : ''
                                            } */}
                                            {/* <CardElement /> */}
                                            
                                        
                                    
                                            {
                                                formData.payment_method == 'credit_card' ?
                                                    <div className="stripe-card-container">
                                                        <div>
                                                            <label htmlFor="card-element">Credit or debit card</label>
                                                            <CardElement id="card-element" options={cardElementStyles} />
                                                        </div>
                                                        {error && <div className="invalid-feedback">{error}</div>}
                                                    </div>
                                                :
                                                    ''
                                            }
                                            
                                            <div className="custominput">
                                                <Form.Check 
                                                        value="1"
                                                        type='checkbox'
                                                        id={`terms-vondition`}
                                                        onChange={handleChange}
                                                        name="terms_condition"
                                                        label={<span className="text-5" >By check you accept our <span onClick={() => displayTermsCondition()}>Terms & Condition</span>.</span>} 
                                                        isInvalid={ !!errors.terms_condition }
                                                    />
                                                <Form.Control.Feedback type="invalid">
                                                    { errors.terms_condition }
                                                </Form.Control.Feedback>
                                            </div>
                                            {
                                                formSubmited ?  <button disabled="disabled" className="primary-btn"><Spinner animation="border" size="sm" variant="dark" /></button> : <button className="primary-btn" disabled={!stripe || loading}>Proceed to payment</button> 
                                            }
                                    </Form>
								</div>
                                
                                
                                
   
                                { 
                                    selectedPlan ? 
								    <div className="right">
                                        <div className="your-plan box">
                                            <div className="top">
                                                <h2>Your plan</h2>
                                { coupon && coupon.discount_amount > 0 ? <h2>Total: ${selectedPlan.price} - ${coupon.discount_amount} = ${selectedPlan.price - coupon.discount_amount}</h2> : <h2>Total: ${selectedPlan.price}</h2> }
                                            </div>
                                            <div className="body-text">
                                                <span className="text-2">Starting Today:</span>
                                                {
                                                    selectedPlan.trial_price ?
                                                        <span className="text-3">${selectedPlan.trial_price} First {selectedPlan.trial_interval} {selectedPlan.trial_period} thereafter ${selectedPlan.price} every {selectedPlan.payment_interval} {selectedPlan.payment_period}.</span>
                                                    : 
                                                        coupon && coupon.discount_amount > 0 ? <span className="text-3">${selectedPlan.price - coupon.discount_amount} every {selectedPlan.payment_interval} {selectedPlan.payment_period}</span> : <span className="text-3">${selectedPlan.price} every {selectedPlan.payment_interval} {selectedPlan.payment_period}</span>
                                                }
                                                <br />
                                                <span className="text-2">Billing detail:</span>
                                                {
                                                    selectedPlan.trial_price ?
                                                        <span className="text-3">${selectedPlan.trial_price} First {selectedPlan.trial_interval} {selectedPlan.trial_period} thereafter ${selectedPlan.price} every {selectedPlan.payment_interval} {selectedPlan.payment_period}.</span>
                                                    :
                                                    coupon && coupon.discount_amount > 0 ? <span className="text-3">${selectedPlan.price - coupon.discount_amount} every {selectedPlan.payment_interval} {selectedPlan.payment_period}.</span> : <span className="text-3">${selectedPlan.price} every {selectedPlan.payment_interval} {selectedPlan.payment_period}.</span>
                                                }
                                            </div>
                                            <p className="text-5">Cancel anytime. <a href="#">Offer term</a> apply</p>
                                        </div>
                                        <div className="box promo-apply">
                                            <h2>Have a promo code?</h2>
                                            <div className="form-content">
                                                {couponValidationError && couponValidationError != '' ? <div class="alert alert-danger">{couponValidationError}</div> : ''}
                                                <InputGroup className="form-group">
                                                    <Form.Label className="text-5">Promotion code</Form.Label>
                                                    <FormControl
                                                        placeholder="Have a Promo Code?"
                                                        aria-label="Promo Code"
                                                        className="text-3 form-control" 
                                                        onChange={setPromoCode}
                                                        onFocus={() => setCouponValidationError('')}
                                                    />
                                                </InputGroup>
                                            </div>
                                            { couponApplied == true && coupon ? <p>Discount <span className="text-danger">${coupon.discount_amount}</span> applied {couponApplied}</p> : ''}
                                            { couponApplied == true ? <InputGroup.Text className="border-0 bg-none px-2 ">{processCoupon ? <button className="secondary-btn"><Spinner animation="border" size="sm" variant="dark" /></button> : <button className="secondary-btn" onClick={() => checkCoupon()}>Change</button>}</InputGroup.Text> : ''}
                                            { couponApplied == false ? processCoupon ? <button className="secondary-btn"><Spinner animation="border" size="sm" variant="dark" /></button> :<button className="secondary-btn" onClick={() => checkCoupon()}>Apply</button> : ''}
                                        </div>
								    </div>
                                     : ''
                                }
							</div>
                        </div>
                    }
                    </div>
                </div>
            </div>
        </MusicDashboard>
    );
}
function mapStateToProps(state) {
  return {
	agreement: state.subscription.agreement,
    plans: state.subscription.plans,
    customerProfile: state.customer.customerProfile,
    subscriptionDetail: state.subscription.subscriptionDetail,
    countryList: state.common.countryList,
  };
}

function mapDispatchToProps(dispatch) {
  	return {
        getCountryList: () => dispatch(commonActions.getCountryList()),
        getServices: (post) => dispatch(subscriptionAction.getServices(post)),
        validateCoupon: (post) => dispatch(couponAction.validateCoupon(post)),
        getCustomerAgreement: () => dispatch(subscriptionAction.getCustomerAgreement()),
        processCardPaymentMethod: (post) => dispatch(subscriptionAction.processCardPaymentMethod(post)),
        processPaypalPayment: (post) => dispatch(subscriptionAction.processPaypalPayment(post)),
        getPlanDetails: (post) => dispatch(subscriptionAction.getPlanDetails(post)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        processApplePayment: (post) => dispatch(subscriptionAction.processApplePayment(post))
  	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod1);