import React from "react";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import SinglePageLayout from "../../layouts/singlePageLayout/singlePageLayout";
import logo from './../../assets/images/cklogo.png';
import { Link } from 'react-router-dom';

import {connect} from 'react-redux';
const NotFound = ({}) => {
  
    


  return (
    <SinglePageLayout>
		<SimpleBar style={{ height: '100vh' }}>
        <div className="sign-in">
			<div className="sign-in-left">
				<div className="top">
					<Link to={`/sign-in`} className="logo"><img src={logo} alt="Clubkillers sign in" title="Clubkiller sing in" style={{maxWidth: '100px' }} /></Link>
				</div>
				<div className="form-sec">
    					<h1>Page Not Found</h1>
					<h1>404</h1>
                    <h2>Oops! Page not found.</h2>
                    <p>We can't seem to find the page you're looking for.</p>
					<Link to="/sign-in" className="btn btn-primary primary-btn">Go Back Home</Link>
				</div>
					
			</div>
			<div className="sign-in-right">
				<div className="inner-bg">
					<div className="content">
						<div className="custom-font">ELEVATING DJ CULTURE ONE BEAT AT A TIME.</div>
						<h2>The world’s most influential DJ community & lifestyle brand.</h2>
                        
					</div>
				</div>
			</div>
		</div>
		</SimpleBar>
    </SinglePageLayout>
  );
}

function mapStateToProps(state) {
    return {
        
    };
}

function mapDispatchToProps(dispatch) {
    return {
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);