import React, {useState, useEffect} from "react";

import singlePageLayout from "../../../layouts/singlePageLayout/singlePageLayout";

import {connect} from 'react-redux';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { Link } from "react-router-dom";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { useParams } from "react-router-dom";

// import logo from './../../assets/images/Logo.svg';
import logo from './../../../assets/images/cklogo.png';
import * as actions from '../../../store/career/actions';

import '../mixes.scss';

const JobDetail = ({ getJobDetail, jobDetail,  displayLoading, hideLoading }) => {

	let match = useParams("/career/detail/:careerId");
    const [ processing, setProcessing ] = useState(false);
    
	useEffect(() => {
        displayLoading();
        setProcessing(true);
        getJobDetail({career_id: match.careerId});
		document.body.classList.add('hideplayer');
        hideLoading();
            setProcessing(false);
	},[]);

	console.log('jobDetail', jobDetail)

	
  return (
    <singlePageLayout>
    {/* // <MusicDashboard middleSectionCssclassName={'account-info-sec'}> */}
		<SimpleBar style={{ height: '100vh' }}>
            <div className="outer-singlelayout-pages" style={{minHeight: 'auto'}}>
                <div className="container-sec">
                    <div className="top">
                        <Link to={`/`} className="logo"><img src={logo} alt="Clubkillers sign in" title="Clubkiller sing in" style={{maxWidth: '100px' }} /></Link>
                        <Link to={`/`} className="close">
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L9 9M9 1L1 9" stroke="white" strokeLinecap="round"/>
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="full-row bg-grey">
                <div className="full-outer-singlelayout-pages">
                    <div className="cj-cnt container-sec">
                    {
                        jobDetail ? 
                            <div className="cj-top top">
                                <div>
                                    <h1>{jobDetail.title}</h1>
                                    <h5 className="text-5">{jobDetail.intro}</h5>
                                </div>
                                <div className="cj-act">
                                    <Link to={`/career/apply/${jobDetail.career_id}`} className="primary-btn" style={{minWidth: '100px', height: '40px'}}>APPLY FOR THIS JOB</Link>
                                </div>
                            </div>
                        : ''
                    }
                    </div>
                </div>
            </div>
            <div className="outer-singlelayout-pages">
                <div className="container-sec">
                    <div className="osp-content-area">
                    {
                        jobDetail ? 
                            <div className="cj-detail">
                                <div dangerouslySetInnerHTML={{__html: jobDetail.description}} />
                                <Link to={`/career/apply/${jobDetail.career_id}`} className="primary-btn">APPLY FOR THIS JOB</Link>
                            </div>
                        : ''
                    }
                    </div>
                </div>
            </div>
		</SimpleBar>
    {/* </MusicDashboard> */}
    </singlePageLayout>
  );
}

function mapStateToProps(state) {
    
  return {
		loggingIn: state.authentication.loggedIn,
        jobDetail: state.career.jobDetail
  };
}

function mapDispatchToProps(dispatch) {
  return {
	getJobDetail: (data) => dispatch(actions.getJobDetail(data)),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(JobDetail);