export const CUSTOMER_AGGREEMENT = 'CUSTOMER_AGGREEMENT';
export const MEMBERSHIP_PLANS = 'MEMBERSHIP_PLANS';
export const CREDIT_CARD_PAYMENT = 'CREDIT_CARD_PAYMENT';
export const PAYPAL_RECURRING_PAYMENT = 'PAYPAL_RECURRING_PAYMENT';
export const GET_CUSTOMER_PLAN = 'GET_CUSTOMER_PLAN';
export const CONFIRM_PAYPAL_PAYMENT = 'CONFIRM_PAYPAL_PAYMENT';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_FEEDBACK = 'CANCEL_SUBSCRIPTION_FEEDBACK';
export const GET_SUBSCRIPTION_DETAILS = 'GET_SUBSCRIPTION_DETAILS';
export const PROCESS_MOBILE_PAYMENTS = 'PROCESS_MOBILE_PAYMENTS';
export const UPDATE_PAYPAL_CARD = 'UPDATE_PAYPAL_CARD';
export const APPLY_CANCEL_DISCOUNT = 'APPLY_CANCEL_DISCOUNT';
export const PAUSED_SUBSCRIPTION = 'PAUSED_SUBSCRIPTION';
export const REACTIVATE_SUBSCRIPTION = 'REACTIVATE_SUBSCRIPTION';