import React , {useState, useEffect} from "react";

import TopHeader from '../components/make-the-kut/header/topHeader';
import FooterMain from '../components/make-the-kut/footer/footerMain';


import {connect} from 'react-redux';

import LoadingBar from 'react-redux-loading-bar';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';



const MTKMusicDashboard = ({children, rightContent}) => {
  useEffect(() => {
      document.body.classList.add('body-bg');
      document.body.classList.add('mtk');
	},[]);

  return (
    <SimpleBar style={{height: '100vh'}}>
      <div className="container">
        <LoadingBar className="loading" style={{ backgroundColor: '#ff07b3', height: '3px', position: 'fixed', zIndex: '999' }} updateTime={100} maxProgress={95} progressIncrease={10} />
		{/* Top Header Start Here */}
        <TopHeader />
		{/* Top Header End Here */}

		
		{children}
      </div>
	  <FooterMain />
    </SimpleBar>
  );
};


function mapStateToProps(state) {
  
    return {
      loggingIn: state.authentication.loggedIn
    };
      
}
 
 function mapDispatchToProps(dispatch) {
  return {
    
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(MTKMusicDashboard);
