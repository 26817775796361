import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import CuratedSpotlightCarousel from '../../components/curatedSpotlightCarousel/curatedSpotlightCarousel';
import RightSide from "../../components/rightSide/rightSide";
import SongTrack from '../../components/songTrack/songTrack';
import SongTrackLoader from '../../components/loader/songTrackLoader';

import MusicDashboard from '../../layouts/musicDashboard';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import * as actions from '../../store/trending/actions';

const TrendingTrackAlbumSongs = ({getTrendingTrackSongs, trendingTrackSongs, displayLoading, hideLoading}) => {
    
    let match = useParams("/trending-tracks/songs/:id");
    const location = useLocation();
    // console.log('top20Songs', top20Songs)
    const [ page, setPage ] = useState(0);
    const [ processing, setProcessing] = useState(false);
      
      useEffect(() => { 
          setProcessing(true);
          displayLoading();
          getTrendingTrackSongs({page: page, trending_track_id: match.id}).then((res) => {
              hideLoading();
              setProcessing(false);
          });
          window.scrollTo({top: 0});
      },[location])
    return (
        <MusicDashboard rightContent={<RightSide blocks={{ top30TrendingTracks: true}} />}>
			    <div className="content-sec">
                    <div className="top-heading">
                        <div className="back-arrow-head">
                            <Link to={`/trending-tracks/albums`}>
                                <span>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 6L8.09618 9.78462C8.06582 9.81224 8.04162 9.8456 8.02508 9.88265C8.00853 9.91969 8 9.95963 8 10C8 10.0404 8.00853 10.0803 8.02508 10.1174C8.04162 10.1544 8.06582 10.1878 8.09618 10.2154L12 14" stroke="#080A0D" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                            </Link>
                            <h2>Trending Tracks</h2>
                        </div>
                        <Link to={`/top20/tracks`} className="text-2">View all</Link>
                    </div>
                    <div className="play-list-sec">
                        {
                            trendingTrackSongs ? 
                                <div className="arrow-heading">
                                    {
                                        trendingTrackSongs.previous_top20_track ? 
                                        <Link to={`/trending-tracks/songs/${trendingTrackSongs.previous_top20_track}`}>
                                            <span>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17 12L7 12" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M9.5 9.5L7 12L9.5 14.5" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M11.9997 22.8337C6.01659 22.8337 1.16634 17.9834 1.16634 12.0003C1.16634 6.01724 6.01659 1.16699 11.9997 1.16699C17.9828 1.16699 22.833 6.01724 22.833 12.0003C22.833 17.9834 17.9828 22.8337 11.9997 22.8337Z" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </span>
                                        </Link> :
                                        <span>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17 12L7 12" stroke="#A3A3A3" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M9.5 9.5L7 12L9.5 14.5" stroke="#A3A3A3" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M11.9997 22.8337C6.01659 22.8337 1.16634 17.9834 1.16634 12.0003C1.16634 6.01724 6.01659 1.16699 11.9997 1.16699C17.9828 1.16699 22.833 6.01724 22.833 12.0003C22.833 17.9834 17.9828 22.8337 11.9997 22.8337Z" stroke="#A3A3A3" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                    }
                                    {
                                        trendingTrackSongs && trendingTrackSongs.trendingTrack && trendingTrackSongs.trendingTrack.track ?
                                            <h3>{trendingTrackSongs.trendingTrack.track.title} </h3>
                                        : ''
                                    }
                                    {
                                        trendingTrackSongs.next_top20_track ? 
                                        <Link to={`/trending-tracks/songs/${trendingTrackSongs.next_top20_track}`}>
                                            <span>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7 12H17" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.5 9.5L17 12L14.5 14.5" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M12.0003 22.8337C17.9834 22.8337 22.8337 17.9834 22.8337 12.0003C22.8337 6.01724 17.9834 1.16699 12.0003 1.16699C6.01724 1.16699 1.16699 6.01724 1.16699 12.0003C1.16699 17.9834 6.01724 22.8337 12.0003 22.8337Z" stroke="#EB4E27" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </span>
                                        </Link>
                                        :
                                        <span>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 12H17" stroke="#A3A3A3" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M14.5 9.5L17 12L14.5 14.5" stroke='#A3A3A3' strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12.0003 22.8337C17.9834 22.8337 22.8337 17.9834 22.8337 12.0003C22.8337 6.01724 17.9834 1.16699 12.0003 1.16699C6.01724 1.16699 1.16699 6.01724 1.16699 12.0003C1.16699 17.9834 6.01724 22.8337 12.0003 22.8337Z" stroke="#A3A3A3" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span> 
                                    }
                                </div>
                                : ''
                            }
                        <div className="inner-block">
                        { processing ? <SongTrackLoader itemCount={20} /> : trendingTrackSongs && trendingTrackSongs.trendingTrack && trendingTrackSongs.trendingTrack.songs ? <SongTrack songs={trendingTrackSongs.trendingTrack.songs} /> : ''}
                        </div>
                    </div>
                </div>
            </MusicDashboard>
        );
}

function mapStateToProps(state) {
    return {
        trendingTrackSongs: state.trending.trendingTrackSongs
    };
}
    
function mapDispatchToProps(dispatch) {
    return {
        getTrendingTrackSongs: (data) => dispatch(actions.getTrendingTrackSongs(data)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrendingTrackAlbumSongs);