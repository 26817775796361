import React, { useState, useEffect } from "react";

import { Link } from 'react-router-dom';
import {  useParams } from "react-router-dom";

import {connect} from 'react-redux';
import * as genreActions from '../../store/genres/actions';

const CuratedGenres = ({getCuratedGenresWithResult, curatedFilterWithResult, curatedGenres}) => {

	let match = useParams("/mp3/spotlight/genre/:id");
    const [ toggle, setToggle ] = useState(false);
	const [ currentGenre, setCurrentGenre ] = useState();
	// const [ curatedGenres, setCuratedGenres ] = useState();

    useEffect(() => { 
        getCuratedGenresWithResult().then(res => {
			if(match && match.params && match.params.genre_id && curatedGenres && curatedGenres.current_genre){
				setCurrentGenre(curatedGenres.current_genre.parent_id);
			} else {
				const genres =  res.payload.data.genres;
				const activeGenre = genres.filter(genre => genre.isActive === true);
				setCurrentGenre(activeGenre[0].genre_id);
			}
			
		});
    },[]);
    
    const makeActiveGenre = (genreId) => {
        console.log(toggle, genreId, currentGenre)

        if(genreId == currentGenre){
            setToggle(!toggle);
        } else { 
            setToggle(true);
        }
        setCurrentGenre(genreId);
    }

	useEffect(() => { 
		if(curatedGenres && curatedGenres.current_genre){
			setCurrentGenre(curatedGenres.current_genre.parent_id);
		}
		
	},[curatedGenres])
	const genreAccordian = (generId) => {

	}

    // console.log(curatedFilterWithResult);

    return (
        <div className="curated-geners">
            <div className="top">
				<h2>Curated Genres</h2>
			</div>
			{
                curatedFilterWithResult && curatedFilterWithResult.genres && curatedFilterWithResult.genres.length > 0 ?
                <div className="curated-gen-block">
				{
					curatedFilterWithResult.genres.map((genre, index) => {
                        return <div className={currentGenre == genre.genre_id && toggle ? 'box active' : "box"} key={`curatedGenre-${genre.genre_id}`}>
                                    <div className="headinggenres" onClick={()=> makeActiveGenre(genre.genre_id)}>
                                        <h3 dangerouslySetInnerHTML={{__html: genre.title}}></h3>
                                        {
                                            currentGenre == genre.genre_id && toggle ?
                                            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 5L5.21538 1.09618C5.18776 1.06582 5.1544 1.04162 5.11735 1.02508C5.08031 1.00853 5.04037 1 5 1C4.95963 1 4.91969 1.00853 4.88265 1.02508C4.8456 1.04162 4.81224 1.06582 4.78462 1.09618L1 5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg> :
                                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 8.5L9.78462 12.4038C9.81224 12.4342 9.8456 12.4584 9.88265 12.4749C9.91969 12.4915 9.95963 12.5 10 12.5C10.0404 12.5 10.0803 12.4915 10.1174 12.4749C10.1544 12.4584 10.1878 12.4342 10.2154 12.4038L14 8.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        }

                                    </div>
                                    { 
                                        genre && genre.subgenres && genre.subgenres.length > 0 ?
                                            <ul>
                                                {
                                                    genre.subgenres.map((subgenre, sIndex) => {
                                                    return subgenre.total >  0 ? <li key={`curatedsubgenres${subgenre.genre_id}`}>
                                                        <Link to={`/mp3/spotlight/genre/${subgenre.genre_id}`} dangerouslySetInnerHTML={{__html: subgenre.name}}></Link>
                                                    </li>  : null
                                                    })
                                                }
                                            </ul>
                                        : ''
                                    }
                            </div>
                        })
                    }
                </div>
                : ''
			}
		</div>
    )
}



function mapStateToProps(state) {
    return {
		curatedFilterWithResult: state.genre.curatedGenres,
		curatedGenres: state.curated.curatedGenres
    };
}
    
function mapDispatchToProps(dispatch) {
    return {
        getCuratedGenresWithResult: () => dispatch(genreActions.getCuratedGenresWithResult())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CuratedGenres);