import React from "react";
import {connect} from 'react-redux';


const  GenresLoader = ({ itemCount }) => {

    var indents = [];
    
    for (var i = 0; i < itemCount; i++) {
    indents.push(
                <div className="wrapper-row" key={`wrapper-row${i}`}>
                        <div className="wrapper-image"  >
                            <div className="image"></div>
                        </div>
                        <div className="">
                            <div className="badge-icon"></div>
                            <div className="badge-icon"></div>
                            <div className="badge-icon"></div>
                            <div className="badge-icon"></div>
                            <div className="badge-icon"></div>
                            <div className="badge-icon"></div>
                            <div className="badge-icon"></div>
                            <div className="badge-icon"></div>
                            <div className="badge-icon"></div>
                        </div>
                </div>    
                );
    }

    return (
        <div className="wrapper-row-image wrapper-multiple-image">{indents}</div>	
    );
  
}


function mapStateToProps(state) {
	return {
		
	};
}
 
function mapDispatchToProps(dispatch) {
	return {
		
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(GenresLoader);
