import React, {useState, useEffect} from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import AccountNav from '../../elements/accountElements/accountNav';
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {connect} from 'react-redux';

import * as customerAction from '../../store/customer/actions';






const  ChangePassword = ({themeType, changePasswordRequest, displayLoading, hideLoading }) => {
  
    const [formData, setFormData] = useState({
        current_password: '',
        password: '',
        confirm_password: ''
    });
    const [ processing, setProcessing ] = useState(false);
    const [ errors, setErrors ] = useState({})
    const [ responseError, SetResponseError ] = useState();
    const [ responseSuccess, SetResponseSuccess ] = useState();
    const [ currentPasswordType, setCurrentPasswordType ] = useState('password')
    const [ showCurrentPass, setShowCurrentPass ] = useState(false)
    const [ newPasswordType, setNewPasswordType ] = useState('password')
    const [ showNewPass, setShowNewPass ] = useState(false)
    const [ repeatPasswordType, setRepeatPasswordType ] = useState('password')
    const [ showRepeatPass, setShowRepeatPass ] = useState(false)
    // const [submited, setSubmited] = useState(false);
  
    const [validated, setValidated] = useState(false);

    const resetMessages = () => {
        SetResponseError('');
        SetResponseSuccess('')
    }
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
          ...formData,
          [name]: value
      });
      findFormErrors();
    }

    const showCurrentPassword = () => {
        if(!showCurrentPass){
            setCurrentPasswordType('text');
          } else {
            setCurrentPasswordType('password');
          }
          setShowCurrentPass(!showCurrentPass);
    }
    const showNewPassword = () => {
        if(!showNewPass){
            setNewPasswordType('text');
          } else {
            setNewPasswordType('password');
          }
          setShowNewPass(!showNewPass);
    }
    const showRepeatPassword = () => {
        if(!showRepeatPass){
            setRepeatPasswordType('text');
          } else {
            setRepeatPasswordType('password');
          }
          setShowRepeatPass(!showRepeatPass);
    }
  
    const findFormErrors = () => {
      const { current_password, password, confirm_password, } = formData
      const newErrors = {}
      
      // password errors
      if ( !current_password || current_password === '' ) newErrors.current_password = 'Current password cannot be blank!'
      if ( !password || password === '' ) newErrors.password = 'Password cannot be blank!'
      if(password && password.length < 8) newErrors.password = 'The password must be at least 8 characters.'
      // confirm password errors
      if ( !confirm_password || confirm_password === '' ) newErrors.confirm_password = 'Confirm password cannot be blank!'
      if ( confirm_password !='' && confirm_password != password ) newErrors.confirm_password = 'Please ensure both passwords match'
      setErrors(newErrors);
      return newErrors
    }

    const handleSubmit = (event) => {
        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
    
        if ( Object.keys(newErrors).length > 0 ) {
            // console.log(newErrors)
          // We got errors!
          setErrors(newErrors)
        } else {
          setProcessing(true);
          displayLoading();
          changePasswordRequest(formData).then(res => {
            // console.log(res)
            if(res.payload && res.payload.status && res.payload.status === 'false'){
                SetResponseError(res.payload.message.errors);
                SetResponseSuccess('');
                setProcessing(false);
                hideLoading();
            } else {
                SetResponseSuccess(res.payload.data.message);
                SetResponseError('');
                setProcessing(false);
                hideLoading();
            }
          });
        }
    }

  return (
    <MusicDashboard  accountPages={true} middleSectionCssClass={'account-info-sec'}>
       	<div className="content-sec">
            <div className="account-block">
                <div className="top-heading">
                    <h2>Account Settings</h2>
                </div>
                <AccountNav />
                <div className="account-inner-block">
                    <div className="mb-1 response-msg" style={{marginTop: '10px'}}>
                        {responseSuccess ? <div className="alert alert-success">{responseSuccess}</div>: ''}
                        {responseError ? <div className="alert alert-danger">{responseError}</div>: ''}
                    </div>
                    <div className="change-password">
                        
                        <Form  validated={validated} onSubmit={handleSubmit}>
                            <div className="form-content">
                                <div className="form-group" controlid="formBasicEmail" >
                                    <Form.Label>Current Password</Form.Label>
                                    <InputGroup className="input-pass" controlid="formCurrentPassword" >
                                        <Form.Control className="text-3 form-control" onFocus={()=> resetMessages()} type={currentPasswordType} placeholder="Current Password" name="current_password"  onChange={handleChange} isInvalid={ !!errors.current_password } autoComplete="off" onBlur={handleChange} />
                                        <InputGroup.Text>
                                            {/* <i onClick={showCurrentPassword} class={ showCurrentPass ? 'lnir lnir-eye' : 'lnir lnir-eye strike'}></i> */}
                                            <svg onClick={showCurrentPassword} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.80658 7.06992C6.03344 1.64336 13.9666 1.64336 17.1934 7.06992C18.2689 8.87846 18.2689 11.1215 17.1934 12.9301C13.9666 18.3566 6.03344 18.3566 2.80658 12.9301C1.73114 11.1215 1.73114 8.87846 2.80658 7.06992Z" stroke={themeType == 'light-theme' ? "#000" : "#D7D7D7"} strokeLinecap="round" strokeLinejoin="round"/>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.1615 10.0531C13.1615 11.7991 11.7455 13.2141 9.9995 13.2141C8.2535 13.2141 6.8385 11.7991 6.8385 10.0531C6.8385 8.30611 8.2535 6.89111 9.9995 6.89111C11.7455 6.89111 13.1615 8.30611 13.1615 10.0531Z" stroke={themeType == 'light-theme' ? "#000" : "#D7D7D7"} strokeLinecap="round" strokeLinejoin="round"/>
                                                { showCurrentPass ? '' : <path d="M17.5 3L2 17" stroke={themeType == 'light-theme' ? "#000" : "#D7D7D7"} strokeLinecap="round"/> }
                                            </svg>
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        { errors.current_password }
                                    </Form.Control.Feedback>
                                </div>
                                <div className="form-group" controlid="formBasicEmail" >
                                    <Form.Label>New Password</Form.Label>
                                    <InputGroup className="input-pass" controlid="formNewPassword" >
                                        <Form.Control type={newPasswordType} placeholder="New Password" name="password"  onChange={handleChange} isInvalid={ !!errors.password } autoComplete="off" onBlur={handleChange} onFocus={()=> resetMessages()} />
                                        <InputGroup.Text>
                                            {/* <i onClick={showNewPassword} class={ showNewPass ? 'lnir lnir-eye' : 'lnir lnir-eye strike'}></i> */}
                                            <svg onClick={showNewPassword} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.80658 7.06992C6.03344 1.64336 13.9666 1.64336 17.1934 7.06992C18.2689 8.87846 18.2689 11.1215 17.1934 12.9301C13.9666 18.3566 6.03344 18.3566 2.80658 12.9301C1.73114 11.1215 1.73114 8.87846 2.80658 7.06992Z" stroke={themeType == 'light-theme' ? "#000" : "#D7D7D7"} strokeLinecap="round" strokeLinejoin="round"/>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.1615 10.0531C13.1615 11.7991 11.7455 13.2141 9.9995 13.2141C8.2535 13.2141 6.8385 11.7991 6.8385 10.0531C6.8385 8.30611 8.2535 6.89111 9.9995 6.89111C11.7455 6.89111 13.1615 8.30611 13.1615 10.0531Z" stroke={themeType == 'light-theme' ? "#000" : "#D7D7D7"} strokeLinecap="round" strokeLinejoin="round"/>
                                                { showNewPass ? '' : <path d="M17.5 3L2 17" stroke={themeType == 'light-theme' ? "#000" : "#D7D7D7"} strokeLinecap="round"/> }
                                            </svg>
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        { errors.password }
                                    </Form.Control.Feedback>
                                </div>
                                <div className="form-group">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <InputGroup className="input-pass" controlid="formConfirmPassword" >
                                        
                                        <Form.Control type={repeatPasswordType} placeholder="Confirm Password" name="confirm_password"  onChange={handleChange} isInvalid={ !!errors.confirm_password } autoComplete="off" onBlur={handleChange} onFocus={()=> resetMessages()} />
                                        <InputGroup.Text>
                                            {/* <i onClick={showRepeatPassword} class={ showRepeatPass ? 'lnir lnir-eye' : 'lnir lnir-eye strike'}></i> */}
                                            <svg onClick={showRepeatPassword} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.80658 7.06992C6.03344 1.64336 13.9666 1.64336 17.1934 7.06992C18.2689 8.87846 18.2689 11.1215 17.1934 12.9301C13.9666 18.3566 6.03344 18.3566 2.80658 12.9301C1.73114 11.1215 1.73114 8.87846 2.80658 7.06992Z" stroke={themeType == 'light-theme' ? "#000" : "#D7D7D7"} strokeLinecap="round" strokeLinejoin="round"/>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.1615 10.0531C13.1615 11.7991 11.7455 13.2141 9.9995 13.2141C8.2535 13.2141 6.8385 11.7991 6.8385 10.0531C6.8385 8.30611 8.2535 6.89111 9.9995 6.89111C11.7455 6.89111 13.1615 8.30611 13.1615 10.0531Z" stroke={themeType == 'light-theme' ? "#000" : "#D7D7D7"} strokeLinecap="round" strokeLinejoin="round"/>
                                                { showRepeatPass ? '' : <path d="M17.5 3L2 17" stroke={themeType == 'light-theme' ? "#000" : "#D7D7D7"} strokeLinecap="round"/> }
                                            </svg>
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        { errors.confirm_password }
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                            <div className="save-btn">
                                { processing ? <button className="primary-btn"><Spinner animation="border" variant="dark" size="sm" /></button> : <button type="submit" className="primary-btn">Save changes</button> }
                            </div>
                            </Form>
                    </div>
                </div>
            </div>
		</div>
		
        
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
    return {
        customer: state.customer,
        countryList: state.common.countryList,
        user: state.authentication.user,
        authentication: state.authentication,
        themeType: state.theme.theme,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changePasswordRequest: (post) => dispatch(customerAction.changePassword(post)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    	hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);