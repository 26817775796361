import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CuratedSpotlightCarousel from '../../components/curatedSpotlightCarousel/curatedSpotlightCarousel';
import RightSide from "../../components/rightSide/rightSide";
import Pagination from "react-js-pagination";

import MusicDashboard from '../../layouts/musicDashboard';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import ImageLoader from '../../components/loader/imageLoader';

import { Link } from "react-router-dom";
import { checkCustomer } from '../../components/utlity/checkCustomer';
import { connect } from 'react-redux';
import * as actions from '../../store/curated/actions';



const  CuratedSpotlightGenre = ({ loggingIn, getGenreCuratedAlbums, curatedGenres, displayLoading, hideLoading, userProfile }) => {

    let match = useParams("/mp3/spotlight/genre/:id");
    const navigate = useNavigate();
    const [ page, setPage ] = useState(1);
    const [ processing, setProcessing] = useState(false);

   useEffect(() => { 
		loadData();
    },[ match.id])
    

	const loadData = async() => {
        setProcessing(true);
        displayLoading();
        await getGenreCuratedAlbums({ curated_genres_id: match.id, page: page, pageLimit: 36 });
        hideLoading();
        setProcessing(false);
        window.scrollTo({top: 0});
    }
    
    const handlePageChange = async (pageNumber) => {
        setProcessing(true);
        setPage(pageNumber);
        displayLoading();
        await getGenreCuratedAlbums({curated_genres_id: match.id, page: pageNumber, pageLimit: 36});
        hideLoading();
        setProcessing(false);
    }
    
    useEffect(() => { 
        if(userProfile){
            if(!checkCustomer(userProfile)){
                navigate('/subscription/plans');
            }
        }
	},[userProfile]);
    
    
	return (
			<MusicDashboard rightContent={<RightSide blocks={{curatedFilter: true }} />}>
			    <div className="content-sec">
                    <div className="most-download">
                        <CuratedSpotlightCarousel />
                    </div>
                    <div className="latest-spotlights">
                        <div className="top-heading">
                            <div className="back-arrow-head">
                                <span onClick={() => navigate(-1)} className="cp">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 6L8.09618 9.78462C8.06582 9.81224 8.04162 9.8456 8.02508 9.88265C8.00853 9.91969 8 9.95963 8 10C8 10.0404 8.00853 10.0803 8.02508 10.1174C8.04162 10.1544 8.06582 10.1878 8.09618 10.2154L12 14" stroke="#080A0D" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                                <h2>{curatedGenres && curatedGenres.current_genre ? curatedGenres.current_genre.title : '' }</h2>
                            </div>
                        </div>
                        <div className="latest-spot-block">
                        {
                            processing ? <ImageLoader itemCount={35} /> :
                            curatedGenres && curatedGenres.curatedlist && curatedGenres.curatedlist.length > 0 ?
                                curatedGenres.curatedlist.map((curatedSpotlight, index) => {
                                        return <Link className="box" key={`newreleasespotlight${curatedSpotlight}`} to={`/mp3/spotlight/detail/${curatedSpotlight.curated_list_id}`}>
                                                    <img src={curatedSpotlight.image ? curatedSpotlight.image : ''} />
                                                    <div className="latest-info"> <h5>{curatedSpotlight.title}</h5></div>            
                                            </Link>
                                })
                            : <div className="alert alert-danger">No Records found</div>
                        }
                        </div>
                    </div>
                    <div className="pagingnation-sec">
                        {
                        curatedGenres && curatedGenres.pagination ? 
                            <Pagination
                                activePage={curatedGenres.pagination.page}
                                itemsCountPerPage={curatedGenres.pagination.perPageLimit}
                                totalItemsCount={curatedGenres.pagination.totalCount}
                                // pageRangeDisplayed={pageRangeDisplayed}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                        /> : ''
                        }
                    </div>
                </div>
            </MusicDashboard>
        );
}

function mapStateToProps(state) {
	return {
        loggingIn: state.authentication.loggedIn,
        curatedGenres: state.curated.curatedGenres,
        userProfile: state.customer.customerProfile,
	};
 }
 
function mapDispatchToProps(dispatch) {
	return {
        getGenreCuratedAlbums: (data) => dispatch(actions.getGenreCuratedAlbums(data)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(CuratedSpotlightGenre);
