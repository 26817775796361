import React, { useState, useEffect } from "react";

import {connect} from 'react-redux';

const SongToggleButton = ({ songId, toggleSong, setSongToggle, themeType, customerProfile }) => {

    
        return (
            toggleSong  ?
                <span onClick={() => setSongToggle(songId)} className="cp">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 8.5L9.78462 12.4038C9.81224 12.4342 9.8456 12.4584 9.88265 12.4749C9.91969 12.4915 9.95963 12.5 10 12.5C10.0404 12.5 10.0803 12.4915 10.1174 12.4749C10.1544 12.4584 10.1878 12.4342 10.2154 12.4038L14 8.5" stroke={themeType == 'dark-theme' ? "#FFFFFF" : "#282A2D"} strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span> 
            :
                <span onClick={() => setSongToggle(songId)} className="cp">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 8.5L9.78462 12.4038C9.81224 12.4342 9.8456 12.4584 9.88265 12.4749C9.91969 12.4915 9.95963 12.5 10 12.5C10.0404 12.5 10.0803 12.4915 10.1174 12.4749C10.1544 12.4584 10.1878 12.4342 10.2154 12.4038L14 8.5" stroke={themeType == 'dark-theme' ? "#FFFFFF" : "#282A2D"} strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
        )
}
function mapStateToProps(state) {
    return {
        themeType: state.theme.theme,
        customerProfile: state.customer.customerProfile,
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
        
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(SongToggleButton);
  