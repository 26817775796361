import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import MtkSongTrack from '../../../components/songTrack/mtkSongTrack';
import Pagination from "react-js-pagination";
import Spinner from 'react-bootstrap/Spinner';
import MTKMusicDashboard from '../../../layouts/MTKMusicDashboard';
import { connect } from 'react-redux';

import * as actions from '../../../store/mtk/actions';
import * as songActions from '../../../store/song/actions';

import { showLoading, hideLoading } from 'react-redux-loading-bar';

const  MTKNewArrivals = ({ getLatestSong, newRelease, paginationData, displayLoading, hideLoading }) => {

    const [ page, setPage ] = useState(1);
    const [ order, setOrder ] = useState('entity_id');
    const [ orderDir, setOrderDir ] = useState('DESC');
    const [ songSectionId, setSongSectionId ] = useState(2);
    const [ currentGenreId, setCurrentGenreId ] = useState();
    const [ processing, setProcessing ] = useState(false);
    const [ selectedGenres, setSelectedGenres ] = useState([]);
	
	useEffect(() => { 
        displayLoading();
        loadData();
        hideLoading();
    },[]);

    const loadData = async() =>{
        // Get genres filter to display on homepage
        displayLoading();
        setProcessing(true);
		setCurrentGenreId('all')
        await getLatestSong({page: page, order: order, limit: 20, orderDir, song_section_id: songSectionId});
        hideLoading();
        setProcessing(false);
        window.scrollTo({top: 0});
    } 

    const handlePageChange = (pageNumber) => {
        displayLoading();
        setProcessing(true);
        setPage(pageNumber);
        if(currentGenreId === 'all'){
          getLatestSong({page: pageNumber, order: order, limit: 20, orderDir, song_section_id: songSectionId}).then((res) => {
            hideLoading();
            setProcessing(false);
            window.scrollTo({top: 0});
          })
        } else {
          getLatestSong({page: pageNumber, order: order, limit: 20, orderDir, song_section_id: songSectionId, genre_id: selectedGenres}).then((res) => {
            hideLoading();
            setProcessing(false);
            window.scrollTo({top: 0});
          })
        }
        
    }
    
    return (
    <MTKMusicDashboard>
        <div className="top-back">
            <span className="back cp" href="#">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#25282C"/>
                    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#2B2F32"/>
                    <path d="M22 16L18.0962 19.7846C18.0658 19.8122 18.0416 19.8456 18.0251 19.8826C18.0085 19.9197 18 19.9596 18 20C18 20.0404 18.0085 20.0803 18.0251 20.1174C18.0416 20.1544 18.0658 20.1878 18.0962 20.2154L22 24" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                </svg> Back  
            </span>
            <div className="add-song">
                <span className="cp link">
                    <span>Add song</span> 
                    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="22" cy="22" r="22" fill="white"/>
                        <path d="M22 15.75V28.25M28.25 22H15.75" stroke="#EB4E27" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                </span>
            </div>
        </div>

        <div className="heading-sec">
                <h2>Top 10 this week</h2>
                <div className="sorting-sec">
                    <span>Sorting by:</span>
                    <select>
                        <option>New to old</option>
                    </select>
                </div>
            </div>

        <div className="bg-block inner-bg-block">
    {/* {    processing ? <SongTrackLoader itemCount={20} /> : <MtkSongTrack songs={newRelease} /> } */}
            { newRelease ? <MtkSongTrack songs={newRelease} /> : '' }
            <div className="pagingnation-sec">
            {
            paginationData ? 
                <Pagination
                    activePage={paginationData.page}
                    itemsCountPerPage={paginationData.perPageLimit}
                    totalItemsCount={paginationData.totalCount}
                    // pageRangeDisplayed={pageRangeDisplayed}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
            /> : ''
            }
            </div>
        </div>
        </MTKMusicDashboard>
    );
}
function mapStateToProps(state) {
	return {
        loggingIn: state.authentication.loggedIn,
        newRelease: (state.song.homeNewRelease && state.song.homeNewRelease.songs ) ? state.song.homeNewRelease.songs : [],
        paginationData:  (state.song.homeNewRelease && state.song.homeNewRelease.pagination) ? state.song.homeNewRelease.pagination : '',
	};
 }
 
function mapDispatchToProps(dispatch) {
	return {
		displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        getLatestSong: (post) => dispatch(songActions.getNewRelease(post)),
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(MTKNewArrivals);