import React, { useState, useEffect, useRef } from "react";
import {connect} from 'react-redux';
import PlayPauseButton from '../../elements/songElements/playPauseButton';
import { isTemplateElement } from "@babel/types";

const SongPlaylist = ({ playList, themeType }) => {
    const ref = useRef(null);
    const [displayPlayList, setDisplayPlayList ] = useState(false);

    useEffect(() => {
        // Add event listener when component mounts
        document.addEventListener('mousedown', handleClickOutside);
        // Cleanup the event listener when component unmounts
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []); 
    
    const handleClickOutside = (event) => {
        // Close the div if clicked outside of it
        if (ref.current && !ref.current.contains(event.target)) {
            setDisplayPlayList(false);
        }
    };

    return (
        <div className="playlist" ref={ref}>
            {
                playList && playList.length > 0 ?
                <div id="playlist-container" className="playlist-container cp" style={displayPlayList ? {'display': 'block'} : {'display': 'none'}}>
                {
                    playList.map((list, index) => {
                        return <div key={`songPlayList${index}`} className="playlist-item cp">
                            <div className="playlist-item-info">
                                <span className="">{list.name}</span>
                                <span className="">{list.writer}</span>
                                </div>
                            <div className="playlist-item-action"><PlayPauseButton parentComponent={'playlist'} playlistItem={list} playListIndex={index+1} /></div>
                        </div>
                    })
                }
                </div>  
                : ''
            }
            <span onClick={() => setDisplayPlayList(!displayPlayList)} className="cp">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.4284 12.9026C17.4284 12.5995 17.308 12.3088 17.0937 12.0945C16.8794 11.8802 16.5887 11.7598 16.2856 11.7598H12.857V12.9026H7.14272V11.7598H3.71415C3.41104 11.7598 3.12035 11.8802 2.90602 12.0945C2.6917 12.3088 2.57129 12.5995 2.57129 12.9026V16.3312C2.57129 16.6343 2.6917 16.925 2.90602 17.1393C3.12035 17.3536 3.41104 17.4741 3.71415 17.4741H16.2856C16.5887 17.4741 16.8794 17.3536 17.0937 17.1393C17.308 16.925 17.4284 16.6343 17.4284 16.3312V12.9026Z" stroke={themeType == 'dark-theme' ? '#FFFFFF' : '#828282'} strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M3.71387 8.33105H16.2853" stroke={themeType == 'dark-theme' ? '#FFFFFF' : '#828282'} strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M3.71387 4.90234H16.2853" stroke={themeType == 'dark-theme' ? '#FFFFFF' : '#828282'} strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </span>
        </div>
        )
    
}
function mapStateToProps(state) {
    return {
        playList: state.playlist.playList,
        themeType: state.theme.theme,
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
        
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(SongPlaylist);
  