import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';
import * as actions from '../../../store/customer/actions';
import MTKLogo from './../../../assets/images/make-the-kut/mtklogo.svg';
import {isDesktop, isMobile, isTablet, browserName, browserVersion, osName, osVersion, isMobileOnly} from 'react-device-detect';
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';

const  TopHeader = ({ loggingIn, customerData, user, getCustomerProfile }) => {

	const navigate = useNavigate();
	const [ userDeviceInfo, setUserDeviceInfo ] =  useState({
        device_type: isDesktop ? 'desktop' : isMobile ? 'mobile' : 'tablet',
        browser_name: browserName,
        browser_version: browserVersion,
        os_name: osName,
        os_version: osVersion,
        ip_address: '',
        token: localStorage.user,
        device_id: localStorage.getItem('deviceId')
	});
	
	const getIP = () => {
        // const res = await axios.get("https://api.ipify.org/?format=json");
        let deviceInfo = userDeviceInfo;
        // deviceInfo.ip_address = res.data.ip;
        // setUserDeviceInfo(deviceInfo);
        if(!customerData.customerProfile){
            getCustomerProfile(deviceInfo).then(res => {
                if(res.payload.isAxiosError){
                    // window.location.href = '/logout';
                }
            }); 
        }
	}
	
	useEffect(() => { 
		getIP();
		if(loggingIn !== undefined && loggingIn === false){
            // window.location.href = '/sign-in';
		} 
    },[])
    
    return (
        <div className="top-header">
			<div className="logo">
				<a href="/make-the-kut/home">
					<img src={MTKLogo} alt="Clubkillers Make The Kut"/>
				</a>
			</div>
			<div className="top-right">
				<div className="top-search-container top-right-block">
				<div className="search input-group">
					<span className="input-group-text">
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M8.76557 14.9599C12.1866 14.9599 14.9599 12.1866 14.9599 8.76557C14.9599 5.34457 12.1866 2.57129 8.76557 2.57129C5.34457 2.57129 2.57129 5.34457 2.57129 8.76557C2.57129 12.1866 5.34457 14.9599 8.76557 14.9599Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"></path>
						<path d="M17.4283 17.4283L13.1426 13.1426" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"></path></svg>
					</span>
					<input placeholder="Search" className="form-control" value="" />
				</div>
				</div>
				<div className="top-right-block"><Link to={`/make-the-kut/top20`}>Charts</Link></div>
				<div className="top-right-block"><Link to={`/make-the-kut/genres`}>Genres</Link></div>
				<div className="top-right-block"><Link to={`/`}>Club killers</Link></div>
				{
					customerData && customerData.customerProfile ? 
						<div className="profile-name top-right-block">
							
								{
									customerData.customerProfile.profile_picture ? 
									<Link to={`/profile/update`}>
										<img style={{ borderRadius: '50%', maxWidth: '25px'}} src={customerData.customerProfile.profile_picture} alt={customerData.customerProfile.dj_name ? customerData.customerProfile.dj_name : ''} title={customerData.customerProfile.dj_name ? customerData.customerProfile.dj_name : ''}/> 
										<span>{customerData.customerProfile.dj_name}</span>
									</Link>
									: 
									// <div className="circle">
									// 	<p className="circle-inner">{customerData.customerProfile.dj_name && customerData.customerProfile.dj_name[0] ? Array.from(customerData.customerProfile.dj_name)[0] : customerData.customerProfile.firstname && customerData.customerProfile.firstname[0] ? Array.from(customerData.customerProfile.firstname)[0] : ''}</p>
									// </div>
									<Link to={`/profile/update`}>
									<span>{customerData.customerProfile.dj_name}</span>
									</Link>
									
								}
						</div>
					: ''
				}
				
			</div>
        </div>
    );
  
}


function mapStateToProps(state) {
	return {
		loggingIn: state.authentication.loggedIn,
        customerData: state.customer,
        user: state.authentication.user
	};
 }
 
 function mapDispatchToProps(dispatch) {
	return {
		getCustomerProfile: (data) => dispatch(actions.getCustomerProfile(data)),
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(TopHeader);
