import React, { useState, useEffect } from "react";

import { checkCustomer } from '../../components/utlity/checkCustomer';
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import FavouriteButton from '../../elements/songElements/favouriteButton';
import DownloadAllButton from '../../elements/songElements/downloadAllButton';
import DropboxAllButton from '../../elements/songElements/dropboxAllBtn';
import SongToggleButton from '../../elements/songElements/songToggleButton';
import PlayPauseButton from '../../elements/songElements/playPauseButton';
import SongVersion from './songVersion';
import PlaylistPopup from './playlistPopup';

const  MTKTopSongTrack = ({ songs, sectionName, activeBtn, isCuratedSpotlight, customerProfile }) => {

    const [toggleSong, setToggleSong ] = useState(false);
    const [activeSongId, setActiveSongId] = useState(0);
    const [customClassName, setCustomClassName] = useState("block");
    const [oneClickDownload, setOneClickDownload ] = useState(false);
    const [ canAccess, setCanAccess ] = useState(false);

    useEffect(() => { 
        if(checkCustomer(customerProfile)){
            setCanAccess(true);
        }
    },[customerProfile])

    const setSongToggle = (songId) => {
        
        if(activeSongId == songId ){
            setToggleSong(!toggleSong)
        } else {
            setToggleSong(true)
        }
        setActiveSongId(songId);
        
    }

    useEffect(() => { 
        setOneClickDownload(parseInt(customerProfile.one_click_download));
    },[customerProfile]);
    // console.log('songs', songs)
    return (
        <div>
            {
                songs && songs.length > 0 ? 
                    songs.map((song, index)=>{
                        return <div className="top-listing-block" key={`thisweektopsong-${song.entity_id}`}>
                            <div className="top-listing-inner">
                                <div className="song-counter">
                                    <span className="song-number">{index < 9 ? `0${index+1}` : index+1}</span>
                                    <span className="play-pause">
                                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.97168 11.3912C0.974962 11.5817 1.03039 11.7676 1.13194 11.9288C1.23349 12.09 1.37728 12.2203 1.54768 12.3055C1.71784 12.4017 1.90995 12.4522 2.10539 12.4522C2.30083 12.4522 2.49295 12.4017 2.66311 12.3055L10.5717 7.41408C10.7438 7.33118 10.8889 7.2014 10.9906 7.03966C11.0922 6.87793 11.1461 6.6908 11.1461 6.49979C11.1461 6.30879 11.0922 6.12166 10.9906 5.95992C10.8889 5.79819 10.7438 5.66841 10.5717 5.58551L2.66311 0.730651C2.49295 0.634508 2.30083 0.583984 2.10539 0.583984C1.90995 0.583984 1.71784 0.634508 1.54768 0.730651C1.37728 0.815852 1.23349 0.946127 1.13194 1.10732C1.03039 1.26851 0.974962 1.45445 0.97168 1.64494V11.3912Z" fill="#FFFFFF"></path></svg>
                                    </span>
                                </div>
                                <div className="top-listing-content">
                                    <div className="image-name">
                                        {/* <img src={CoverImage} alt="cover" /> */}
                                        <div className="name">
                                            <span className="first" dangerouslySetInnerHTML={{__html: song.title}}></span>
                                            {/* { 
                                                    song.artist_search && song.artist_search && song.artist_search.length > 0 ? 
                                                        song.artist_search.map((as, asIndex) => {
                                                            // return <div key={`as${asIndex}-${song.entity_id}`} ><Link to={`/mp3/artist/${as}`} dangerouslySetInnerHTML={{__html: as.trim()}} className="text-5"></Link> <span className="text-5">&nbsp;{ asIndex < (song.artist_search[0].artist.length -1) ? song.artist_search[0].split_string : ''}&nbsp;</span></div>
                                                            return <Link key={`as${asIndex}-${song.entity_id}`} to={`/mp3/artist/${as}`} className="text-5"><span dangerouslySetInnerHTML={{__html: as.trim()}}></span>{ asIndex != (song.artist_search.length -1) ? ', ' : ''}</Link>
                                                        })
                                                    :
                                                    <div><Link to={`/mp3/artist/${song.artist}`} dangerouslySetInnerHTML={{__html: song.artist}} className="text-5"></Link></div>
                                                } */}
                                            <span className="last" dangerouslySetInnerHTML={{__html: song.artist}}></span>
                                        </div>
                                    </div>
                                    <div className="download-icon">
                                        {/* <img src="images/download-circle-icon.svg" alt="" /> */}
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#25282C"/>
                                            <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#2B2F32"/>
                                            <path d="M23.9994 17.4288H25.1423C25.2939 17.4288 25.4392 17.489 25.5464 17.5962C25.6535 17.7034 25.7137 17.8487 25.7137 18.0003V26.5717C25.7137 26.7232 25.6535 26.8686 25.5464 26.9758C25.4392 27.0829 25.2939 27.1431 25.1423 27.1431H14.8566C14.705 27.1431 14.5597 27.0829 14.4525 26.9758C14.3454 26.8686 14.2852 26.7232 14.2852 26.5717V18.0003C14.2852 17.8487 14.3454 17.7034 14.4525 17.5962C14.5597 17.489 14.705 17.4288 14.8566 17.4288H15.9994" stroke="#F4FD84" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M19.999 12.8574V22.0003" stroke="#F4FD84" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M17.7139 19.7148L19.9996 22.0006L22.2853 19.7148" stroke="#F4FD84" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>

                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                : ''
            }
        </div>
    );
  
}
        
        
function mapStateToProps(state) {
    return {
        activeBtn: state.activeDownloadBtn.activeDownloadBtn,
        customerProfile: state.customer.customerProfile
        };
}
    
function mapDispatchToProps(dispatch) {
    return {
    
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MTKTopSongTrack);