import React, { useEffect, useState} from "react";

import Slider from "react-slick";
import Spinner from 'react-bootstrap/Spinner';
import ImageSliderLoader from "../../components/loader/imageSliderLoader";

import {connect} from 'react-redux';
import { Link } from "react-router-dom";

const  GenresMoods = ({ genresWithCount, showItemCount }) => {
    const settings = {
		className: "slider variable-width",
		dots: false,
		infinite: false,
		centerMode: false,
		slidesToShow: 4,
		slidesToScroll: 4,
		variableWidth: true,
		arrows: true,
		responsive: [
		  {
			breakpoint: 1700,
			settings: {
			  slidesToShow: 4,
			  slidesToScroll: 4,
			}
		  },
		  {
			breakpoint: 1600,
			settings: {
			  slidesToShow: showItemCount ? showItemCount : 4,
			  slidesToScroll: showItemCount ? showItemCount : 4,
			}
		  },
		  {
			breakpoint: 1000,
			settings: {
			  slidesToShow: 1,
			  slidesToScroll: 1,
			}
		  },
		]
	};

  return (
        <div className="slider-sec">
            <div className="top-heading">
                <h2>Genres</h2>
            </div>
            {
               genresWithCount && genresWithCount.length > 0 ?
                    <Slider {...settings}>
                        {
                            genresWithCount.map((genre, index) => {
															if(genre.total > 0){
																		return <div key={`genresimage-${genre.genre_id}`} className="item">
																				<div className="box">
																				<Link to={`/mp3/genres/${genre.genre_id}`}><img src={genre.image} alt={genre.name} title={genre.name} /></Link>
																				<Link to={`/mp3/genres/${genre.genre_id}`}>	<h5 dangerouslySetInnerHTML={{__html: genre.name}}></h5> </Link>
																				</div>
																		</div>
															} else {
																return null;
															}
                            })
                        }
                    </Slider>
                : <ImageSliderLoader itemCount={8} />
            }
            
        </div>
  )
}
function mapStateToProps(state) {
    return {
      genresWithCount: state.genre.genresWithCount,
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(GenresMoods);