import React, {useEffect, useState} from "react";

import Form from 'react-bootstrap/Form';
import {connect} from 'react-redux';
import * as helpdeskAction from '../../store/helpdesk/actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import Spinner from 'react-bootstrap/Spinner';

const SubmitTicket = ({  departments,  priorities, submitTicket, getRecentTickets, getDepartments, getPriorities}) => {
    const [formData, setFormData] = useState({
        department_id: '',
        priority_id: 3,
        details: '',
        subject: '',
        ip: ''
    });

    const [ errors, setErrors ] = useState({});
    const [validated, setValidated] = useState(false);
    const [ successMessage, setSuccessMessage ] = useState();
    const [ processing, setProcessing ] = useState(false);
    const [ isSubmit, setIsSubmit ] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });   
        findFormErrors(); 
    }

    useEffect(() => { 
		// load Page Data
		loadPageData();
    },[]);

    const loadPageData = async() => {
        await getDepartments();
        await getPriorities();
  }

    const findFormErrors = () => {
        const { department_id, priority_id, details, subject } = formData
        const newErrors = {}
        // password errors
        if ( !department_id || department_id === '' ) newErrors.department_id = 'Please select the department'
        // if ( !priority_id || priority_id === '' ) newErrors.priority_id = 'Please select the priority'
        // confirm password errors
        if ( !details || details === '' ) newErrors.details = 'Description cannot be blank!'
        if ( !subject || subject === '' ) newErrors.subject = 'Subject cannot be blank!'
        
        if(isSubmit) {
            setErrors(newErrors);
        }
        return newErrors
    }

    const handleSubmit = (event) => {
        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setIsSubmit(true);
        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors)
        } else {
            setErrors('')
            sendRequest(formData);
        }
    }
    const sendRequest = async(data) => {
        setProcessing(true);
        setSuccessMessage('');
        await submitTicket({HelpdeskTickets:formData}).then(res =>{
            if(res && res.payload && res.payload.data){
                
                setSuccessMessage('Ticket has been submitted successfully');
                document.getElementById("myForm").reset();
                setFormData({
                    department_id: '',
                    priority_id: 3,
                    details: '',
                    subject: '',
                    ip: ''
                });
                getRecentTickets().then(res => {
                    
                })
            }
        });
        setProcessing(false);
    }
    return (
        <Form  validated={validated} onSubmit={handleSubmit} id="myForm">
        <div className="mb-1 response-msg" style={{marginTop: '10px'}}>
            {successMessage ? <div className="alert alert-success">{successMessage}</div>: ''}
        </div>
        <div className="department">
            <h5>Department</h5>
            {
                departments && departments.length > 0 ?
                    <Form.Group className="form-group" controlid="formDepartmentId" >
                        {
                        departments.map((department, index) => {
                            return <Form.Check 
                            inline
                            label={department.name}
                            name="department_id"
                            type="radio"
                            id={`inline-radio-${department.id}`} 
                            onChange={handleChange} 
                            key={`radio-${department.id}`} 
                            isInvalid={ !!errors.department_id }
                            value={department.id}
                            onBlur={findFormErrors}
                            />
                        })
                        
                        }
                        <Form.Control.Feedback type="invalid">
                                { errors.department_id }
                        </Form.Control.Feedback>
                    </Form.Group>
                : ''
            }
        </div>
        <div className="form-content">
            
            <Form.Group className="form-group" controlid="formSubject" >
                <Form.Label className="text-5">Subject</Form.Label>
                <Form.Control className="text-3 form-control"  type="text" placeholder="Subject" name="subject"  onChange={handleChange} isInvalid={ !!errors.subject } />
                <Form.Control.Feedback type="invalid">
                    { errors.subject }
                </Form.Control.Feedback>
            </Form.Group>
            
            {/* <Form.Group className="form-group" controlid="formPriorityId">
                <Form.Label className="text-5">Priority </Form.Label>
                <Form.Control className="text-3 form-control"  as="select" type="select" name="priority_id" onChange={handleChange} isInvalid={ !!errors.priority_id }>
                    <option value="">Select Priorirty </option> 
                    {
                        priorities && priorities.length > 0 ?
                        priorities.map((priority, index)=>{
                            return <option value={priority.id} key={`country-${priority.id}`}>{priority.name}</option>
                        })
                        : ''
                    }
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    { errors.priority_id }
                </Form.Control.Feedback>
            </Form.Group> */}
            <Form.Group className="form-group" controlid="formDetails" >
                <Form.Label className="text-5">Description</Form.Label>
                <Form.Control className="text-3 form-control" as="textarea" placeholder="Please enter the details of your request. A member of our support staff will respond as soon as possible" style={{ height: '100px' }} name="details"  onChange={handleChange} isInvalid={ !!errors.details } />
                <Form.Control.Feedback type="invalid">
                    { errors.details }
                </Form.Control.Feedback>
            </Form.Group>
        </div>
        { processing ? <button className="primary-btn"><Spinner animation="border" variant="dark" size="sm" /></button> : <button type="submit" className="primary-btn">Submit your ticket</button>}
    </Form>                       
    )
}
function mapStateToProps(state) {
    return {
        departments: state.helpdesk.departments,
        priorities: state.helpdesk.priorities
    };
}

function mapDispatchToProps(dispatch) {
    return {
        submitTicket: (post) => dispatch(helpdeskAction.submitTicket(post)),
        getRecentTickets: (post) => dispatch(helpdeskAction.getRecentTickets(post)),
        getDepartments: () => dispatch(helpdeskAction.getDepartments()),
        getPriorities: () => dispatch(helpdeskAction.getPriorities())
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SubmitTicket);