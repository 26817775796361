import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';
import FacebookIcon from './../../../assets/images/make-the-kut/facebook.svg';
import InstagramIcon from './../../../assets/images/make-the-kut/instagram.svg';
import TwitterIcon from './../../../assets/images/make-the-kut/twitter.svg';
import YoutubeIcon from './../../../assets/images/make-the-kut/youtube.svg';

const  FooterMain = ({  }) => {

	
	useEffect(() => { 

    },[]);
    
    return (
        <footer>
            <div className="container">
                <div className="footer-inner">
                    <div className="column">
                        <h6>Popular genres</h6>
                        <ul>
                            <li><a href="#">Curated Spotlights</a></li>
                            <li><a href="#">CK Exclusives</a></li>
                            <li><a href="#">Monthly Top 20</a></li>
                            <li><a href="#">Charts</a></li>
                            <li><a href="#">User Submission Area</a></li>
                        </ul>
                    </div>
                    <div className="column">
                        <h6>Popular searches</h6>
                        <ul>
                            <li><a href="#">Pound town (Deville  Party Boos)</a></li>
                            <li><a href="#">Bongos (Ardent Bootleg)</a></li>
                            <li><a href="#">Gemmie more baby (Smassh Vocal)</a></li>
                            <li><a href="#">Bongos vs Toma (DJ OD Segue Ed)</a></li>
                            <li><a href="#">Paint the town red (Even Steve)</a></li>
                        </ul>
                    </div>
                    <div className="column column-big">
                        <h6>Contributors</h6>
                        <div className="footer-inline">
                            <ul>
                                <li><a href="#">Alex Dreamz</a></li>
                                <li><a href="#">Ango</a></li>
                                <li><a href="#">Ardent</a></li>
                                <li><a href="#">Club Killers</a></li>
                                <li><a href="#">CRG</a></li>
                            </ul>
                            <ul>
                                <li><a href="#">Alex Dreamz</a></li>
                                <li><a href="#">Ango</a></li>
                                <li><a href="#">Ardent</a></li>
                                <li><a href="#">Club Killers</a></li>
                                <li><a href="#">CRG</a></li>
                            </ul>
                            <ul>
                                <li><a href="#">Alex Dreamz</a></li>
                                <li><a href="#">Ango</a></li>
                                <li><a href="#">Ardent</a></li>
                                <li><a href="#">Club Killers</a></li>
                                <li><a href="#">CRG</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            
                <div className="copyright">
                    <p>Copywright (c) 2023 clubkillers.com all rights reserved</p>
                    <ul>
                        <li><a href="#">Request</a></li>
                        <li><a href="#">FAQ</a></li>
                        <li><a href="#">My orders</a></li>
                        <li><a href="#">DMCA</a></li>
                    </ul>
                    <ul className="social-icon">
                        <li><a href="#"><img src={FacebookIcon} alt="CK Facebook"/></a></li>
                        <li><a href="#"><img src={InstagramIcon} alt="CK Insta"/></a></li>
                        <li><a href="#"><img src={TwitterIcon} alt="CK Twitter"/></a></li>
                        <li><a href="#"><img src={YoutubeIcon} alt="CK Youtube"/></a></li>
                    </ul>
                </div>
            
            </div>
        </footer>
    );
  
}


function mapStateToProps(state) {
	return {
	};
 }
 
 function mapDispatchToProps(dispatch) {
	return {
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(FooterMain);
