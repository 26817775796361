import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';
import CoverImage from './../../../assets/images/make-the-kut/Cover.png';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import MTKTopSongTrack from "../../songTrack/mtkTopSongTrack";
import * as actions from '../../../store/mtk/actions';

const  Top10ThisWeek = ({ displayLoading, hideLoading, getTop10ThisWeek, top10ThisWeek }) => {

	
	useEffect(() => { 
        displayLoading();
        getTop10ThisWeek()
        hideLoading();
    },[]);
    // console.log(top10ThisWeek.songs)
    return (
            <div className="left">
                <div className="heading-sec">
                    <h2><span>Top 10</span> this week 
                        {/* <img src="images/down-angle-arrow-gray.svg" alt=""/> */}
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24.6294 14.7593L21.667 25.815L10.6113 22.8526" stroke="#828282" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10.333 6.18506L21.553 25.6187" stroke="#828282" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

                    </h2>
                    {/* <a href="#" className="view-more">View more 
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 14L11.9038 10.2154C11.9342 10.1878 11.9584 10.1544 11.9749 10.1174C11.9915 10.0803 12 10.0404 12 10C12 9.95963 11.9915 9.91969 11.9749 9.88265C11.9584 9.8456 11.9342 9.81224 11.9038 9.78462L8 6" stroke="#F4FD84" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </a> */}
                </div>
                { top10ThisWeek && top10ThisWeek.songs ? <MTKTopSongTrack songs={top10ThisWeek.songs} /> : '' }
                
                
            </div>
        );
  
    }
    
    
    function mapStateToProps(state) {
        return {
            top10ThisWeek: state.mtk.top10ThisWeek
        };
     }
     
     function mapDispatchToProps(dispatch) {
        return {
            getTop10ThisWeek:  ()  => dispatch(actions.getTop10ThisWeek()),
            displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
            hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
        };
     }
    
    export default connect(mapStateToProps, mapDispatchToProps)(Top10ThisWeek);
    