import React, { useState, useEffect } from "react";
import MTKMusicDashboard from '../../../layouts/MTKMusicDashboard';
import UserSubmissionArea from '../../../components/make-the-kut/header/userSubmissionArea';
import TopListing from '../../../components/make-the-kut/home/topListing';
import Champion from '../../../components/make-the-kut/home/champion';
import LatestUploadThisWeek from '../../../components/make-the-kut/home/latestUploadThisWeek';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../store/song/actions';

import { showLoading, hideLoading } from 'react-redux-loading-bar';

const  MTKIndex = ({ displayLoading, hideLoading }) => {

	
	useEffect(() => { 
        loadData();
	},[]);
	
	
	const loadData = async() =>{
		
		displayLoading();
		
		hideLoading();
		window.scrollTo({top: 0});
	}  

	return (
			<MTKMusicDashboard>
				{/* User Submission Area Start Here */}
				<UserSubmissionArea />
				{/* User Submission Area End Here */}
				<div className="make-the-cut">
				<div className="top">
					<h1>Make the kut</h1>
					<Link>Upload your edit 
                    <span>
                        <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.799805 14.4L7.04592 8.34464C7.0945 8.30044 7.13321 8.24706 7.15968 8.18779C7.18615 8.12851 7.19981 8.06461 7.19981 8.00002C7.19981 7.93544 7.18615 7.87154 7.15968 7.81226C7.13321 7.75299 7.0945 7.69961 7.04592 7.65541L0.799805 1.60003" stroke="#080A0D" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

                    </span></Link>
				</div>
				
				<div className="make-the-cut-inner">
					<div className="left">
						<div className="music">
                            <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M42.4092 55.7701H18.3128C11.0839 55.7701 6.26465 52.1063 6.26465 43.5575V31.3448C6.26465 22.796 11.0839 19.1322 18.3128 19.1322H42.4092C49.6381 19.1322 54.4574 22.796 54.4574 31.3448V43.5575C54.4574 52.1063 49.6381 55.7701 42.4092 55.7701Z" fill="#D9D9D9" fillOpacity="0.2" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M15.9033 13.0259H44.819" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M23.1318 6.91943H37.5897" stroke="white" strokeOpacity="0.7" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M22.8666 48.7113C24.6898 48.7113 26.1678 47.2131 26.1678 45.3651C26.1678 43.517 24.6898 42.0188 22.8666 42.0188C21.0434 42.0188 19.5654 43.517 19.5654 45.3651C19.5654 47.2131 21.0434 48.7113 22.8666 48.7113Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M38.2644 43.1424V30.0748C38.2644 27.2903 36.5536 26.8996 34.7945 27.3881L28.192 29.22C26.9872 29.5619 26.168 30.5145 26.168 31.9067V34.2271V35.7904V45.3651" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M34.9633 46.4885C36.7865 46.4885 38.2646 44.9904 38.2646 43.1423C38.2646 41.2942 36.7865 39.796 34.9633 39.796C33.1401 39.796 31.6621 41.2942 31.6621 43.1423C31.6621 44.9904 33.1401 46.4885 34.9633 46.4885Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M26.168 35.8148L38.2644 32.4685" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M26.2783 1H34.7829" stroke="white" strokeOpacity="0.3" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

						</div>
						<div className="discover">
							<div>
								<h2>Discover latest uploads of this week</h2>
								<p>Immerse yourself in the latest sounds, carefully curated to elevate your music experience.</p>
							</div>
							<Link>
                                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="25" cy="25" r="24.5" fill="white" fillOpacity="0.1" stroke="white"/>
                                    <path d="M21.7998 31.4L28.0459 25.3446C28.0945 25.3004 28.1332 25.2471 28.1597 25.1878C28.1861 25.1285 28.1998 25.0646 28.1998 25C28.1998 24.9354 28.1861 24.8715 28.1597 24.8123C28.1332 24.753 28.0945 24.6996 28.0459 24.6554L21.7998 18.6" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </Link>
						</div>
					</div>
					<div class="right">
						<Champion />
					</div>
					
				</div>
			</div>
            <TopListing />
            <LatestUploadThisWeek />
        </MTKMusicDashboard>
    );
}

function mapStateToProps(state) {
	return {
		loggingIn: state.authentication.loggedIn
	};
 }
 
function mapDispatchToProps(dispatch) {
	return {
		displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(MTKIndex);
