import * as types from './type';

const initialState = {
    homeGenreList: '',
    genresWithCount:'',
    genresData: '',
    editSongGenres: '',
    curatedGenres: '',
    genresDownloadReport: '',
    downloadReportBySongs: ''
};



export default function genreReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.GET_HOME_GENRES: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          homeGenreList: action.payload.data.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.GET_GENRES_DOWNLOAD_REPORT: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          genresDownloadReport: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.GET_DOWNLOAD_REPORT_BY_GENRES_SONG: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          downloadReportBySongs: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.GET_GENRES_WITH_COUNT: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          genresWithCount: action.payload.data.data.genres
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.GET_GENRES_SONG: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          genresData: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    case types.GET_CURATED_GENRES: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          curatedGenres: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    case types.GET_EDIT_SONG_GENRES: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          editSongGenres: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    default: {
      return {
        ...state
      };
    }
    
  }

}