import React, {useState, useEffect} from "react";
import { Link, useLocation } from 'react-router-dom'

import {connect} from 'react-redux';



const AccountNav = ({ userProfile }) => {

const location  = useLocation();
useEffect(() => { 
},[]);

  return (
        <ul className="tab-sec">
            <li><Link to={`/profile/update`}><span className={location.pathname === '/profile/update' ? 'active' : ''}>Edit Profile</span></Link></li>
            <li ><Link to={`/profile/change-password`}><span className={location.pathname === '/profile/change-password' ? 'active divide' : 'divide'}>Change Password</span></Link></li>
            <li ><Link to={`/subscription/plans`}><span className={location.pathname === '/subscription/plans' ? 'active' : ''}>Subscription</span></Link></li>
            { 
              userProfile && userProfile.group_id == 2 && userProfile.payment_profile_status != 'unpaid' ? 
                <li ><Link to={`/subscription/billing-history`}><span className={location.pathname === '/subscription/billing-history' ? 'active' : ''}>Billing History</span></Link></li>
              : ''
            }
            <li ><Link to={`/helpdesk/faqs`}><span className={location.pathname === '/helpdesk/faqs' ? 'active' : ''}>FAQ</span></Link></li>
            <li ><Link to={`/helpdesk/home`}><span className={location.pathname === '/helpdesk/home' || (location.pathname.includes('helpdesk') && location.pathname !== '/helpdesk/faqs') ? 'active' : ''}>Contact Us</span></Link></li>
            { 
              userProfile && userProfile.user_song && userProfile.user_song == 1  ? 
                <li ><Link to={`/uploads/list`}><span className={location.pathname === '/uploads/list' || location.pathname.includes('uploads') ? 'active' : ''}>Uploads</span></Link></li>
              : ''
            }
            <li ><Link to={`/account/setting`}><span className={location.pathname === '/account/setting' ? 'active' : ''}>Settings</span></Link></li>
            { userProfile && userProfile.user_song && userProfile.user_song == 1 ? <li ><Link to={`/mp3/download-reports`}><span className={location.pathname === '/mp3/download-reports' ? 'active' : ''}>Download Report </span></Link></li> : ''}
            { userProfile && userProfile.user_song && userProfile.user_song == 1 ? <li ><Link to={`/mp3/search-report`}><span className={location.pathname === '/mp3/search-report' ? 'active' : ''}>Search Report</span></Link></li> : '' }
        </ul>
    );
}

function mapStateToProps(state) {
  return {
    userProfile: state.customer.customerProfile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
	
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountNav);