import React, { useState, useEffect } from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import DownloadSongTrack from '../../components/songTrack/downloadSongTrack';
import { Link, useNavigate } from "react-router-dom";
import CuratedSpotlightCarousel from '../../components/curatedSpotlightCarousel/curatedSpotlightCarousel';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import Pagination from "react-js-pagination";
import config from '../../config/config';
import {connect} from 'react-redux';
import * as downloadActions from '../../store/download/actions';
import SongTrackLoader from '../../components/loader/songTrackLoader';
import RightSide from "../../components/rightSide/rightSide";
import { checkPremiumMember } from '../../components/utlity/checkPremiumMember';

const DownloadRescue = ({ displayLoading, hideLoading, getDownloadRescue, downloadHistory, userProfile}) => {
    const navigate = useNavigate();
    const [  page, setPage ] = useState(1);
    const perPageLimit =  config.itemsCountPerPage;
    const [ currentGenreId, setCurrentGenreId ] = useState(0);
    const [ startDate, setStartDate ] = useState();
    const [ endDate, setEndDate ] = useState();
    const [ activeBadge, setActiveBadge ] = useState('all');
    const [ activeBadgeYear, setActiveBadgeYear ] = useState();
    const [years, setYears] = useState();
    const currentYear = new Date().getFullYear();
    const [ processing, setProcessing ] = useState(false);
  
    useEffect(() => { 
        if(checkPremiumMember(userProfile)) {
            loadPageData();
        } else {
            navigate('/subscription/plans');
        }
    },[userProfile]);
    // console.log('downloadHistory', downloadHistory)
    const loadPageData = async () => {
        setProcessing(true);
        displayLoading();
      var yearArray = []
      for (var i=currentYear; i > (currentYear-3); i--) {
        yearArray.push(i);
      }
      setYears(yearArray)
        await getDownloadRescue({ page: page, pageLimit: perPageLimit, page_visit: 'Download Rescue' }, () => {
      })
      hideLoading();
      setProcessing(false);
      window.scrollTo({top: 0});
    }
    
  
    const filterDownloadRescue = async(YY) => {
        setProcessing(true);
        displayLoading();
        setActiveBadge(YY);
        if(YY === 'all'){
            setActiveBadgeYear()
            await getDownloadRescue({page: 1, pageLimit: perPageLimit});
        } else {
            const start_date = {"day":1, "month":1, "year": YY};
            setStartDate(start_date);
            const end_date = {"day":31, "month":12, "year": YY}
            setPage(1)
            await getDownloadRescue({page: 1, pageLimit: perPageLimit, startDate: start_date, endDate: end_date}).then((res) => {});
            setEndDate(end_date);
            setActiveBadgeYear(YY)
        }
        hideLoading();
        setProcessing(false);
        window.scrollTo({top: 0});
    }
  
    const handlePageChange = async (pageNumber) => {
        setProcessing(true)
        displayLoading();
        setPage(pageNumber);
        await getDownloadRescue({page: pageNumber, pageLimit: perPageLimit, startDate: startDate, endDate: endDate}).then((res) => {});
        hideLoading();
        setProcessing(false);
        window.scrollTo({top: 0});
    }
  
    // const filterDownloadRescue = async() => {
    //     displayLoading();
    //     setPage(1)
    //     await getDownloadRescue({page: 1, pageLimit: perPageLimit, startDate: startDate, endDate: endDate}).then((res) => {});
    //     hideLoading();
    // }
    return (
        <MusicDashboard rightContent={<RightSide blocks={{ top30TrendingTracks: true, recentPlayedSong: true, suggestedCollection: true}} />}>
                <div className="content-sec">
                    <div className="play-list-sec">
                        <div className="top-heading">
                            <h2>My Library</h2>
                        </div>
                        
                       <div className="inner-block">
                            <div className="top-list ">
                                <ul className="tab-sec">
                                    <li><Link to={`/my-library/favourite`}>Favorites</Link></li>
                                    <li className="divide"><Link to={`/playlists/home`}>Playlists</Link></li>
                                    <li className='active'><span >Download Rescue</span></li>
                                </ul>
                                
                                <ul className="top-bages">
                                <li className={activeBadge === 'all' ? 'active' : ''} onClick={() => filterDownloadRescue('all')}><span className="cp">All</span></li>
                                {
                                    years && years.length > 0 ? 
                                        years.map((year, index) => {
                                        return <li className={activeBadgeYear == year ? 'active' : ''} key={`year${year}`} onClick={() => filterDownloadRescue(year)}><span className="cp">{year}</span></li>
                                        })
                                    : ''
                                }
                                </ul>
                            </div>
                            {
                                processing ?  <SongTrackLoader itemCount={20} /> :
                                downloadHistory &&  downloadHistory.results && downloadHistory.results.length > 0 ?
                                <DownloadSongTrack songs={downloadHistory.results}    />
                                : <div className="alert alert-danger" style={{paddingLeft: '10px'}}>No records found.</div>
                            }
                        </div>
                        <div className="pagingnation-sec">
                        {
                            downloadHistory && downloadHistory.pagination ? 
                                <Pagination
                                    activePage={downloadHistory.pagination.page}
                                    itemsCountPerPage={downloadHistory.pagination.perPageLimit}
                                    totalItemsCount={downloadHistory.pagination.totalCount}
                                    // pageRangeDisplayed={pageRangeDisplayed}
                                    onChange={handlePageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                            /> : ''
                            }
                        </div>
                    </div>
                    <div style={{marginTop: '35px'}}> <CuratedSpotlightCarousel title={'Suggested for you'}/></div>
                </div>
            </MusicDashboard>
    )
}

function mapStateToProps(state) {
    return {
        downloadHistory: state.download.downloadRescue,
        userProfile: state.customer.customerProfile,
    };
}
      
function mapDispatchToProps(dispatch) {
    return {
        getDownloadRescue: (post) => dispatch(downloadActions.getDownloadRescue(post)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(DownloadRescue);