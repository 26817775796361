import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
export const checkCustomer = (userProfile) => {
    if(userProfile && userProfile.group_id == 2){
        if(userProfile.payment_profile_status == 'paid' && (userProfile.profile_status == 'Active' || userProfile.profile_status == 'ACTIVE')){
            return true;
            // console.log('return true paid active');
        } else if(userProfile.payment_profile_status == 'unpaid'){
            // console.log('unpaid');
            return false;
        } else if(userProfile.payment_profile_status == "pending"){
            return false;
            // console.log('pending');
        } else if(userProfile.payment_profile_status == 'canceled' && userProfile.profile_status == 'Cancelled'){
            var d1 = new Date();
            var d2 = new Date(userProfile.cancel_download_date);
            var same = d1.getTime() === d2.getTime();
            // console.log('checked', userProfile.cancel_download_date)
            if(d2.getTime() > d1.getTime()){
                // console.log('return true cancel');
                return true;
            } else {
                // console.log('canceled');
                return false;
            }
        } else if(userProfile.payment_profile_status == 'paused' && userProfile.profile_status == 'Suspend'){
            var d1 = new Date();
            var d2 = new Date(userProfile.pause_download_date);
            var same = d1.getTime() === d2.getTime();
            // console.log('checked', userProfile.cancel_download_date)
            if(d2.getTime() > d1.getTime()){
                // console.log('return true cancel');
                return true;
            } else {
                // console.log('canceled');
                return false;
            }
        } else {
            // console.log('grroup 2 else');
            return false;
        }
    } else if(userProfile && userProfile.group_id == 3){
        var d1 = new Date();
        var d2 = new Date(userProfile.staff_membership_expire_date);
        // var d2 = new Date('2124-04-12 00:00:00');
        // console.log(d2.getTime(), d1.getTime() )
        if(d2.getTime() > d1.getTime()){
            return true;
            // console.log('return true staaff');
        } else {
            // console.log('staff date', userProfile.staff_membership_expire_date);
            return false;
        }
    }
}