import React, { useState, useEffect } from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import { Link, useNavigate, useParams } from "react-router-dom";
import CuratedSpotlightCarousel from '../../components/curatedSpotlightCarousel/curatedSpotlightCarousel';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import PlaylistSongTrack from '../../components/songTrack/playlistSongTrack';
import SongTrackLoader from '../../components/loader/songTrackLoader';
import Spinner from 'react-bootstrap/Spinner';
import config from '../../config/config';
import { checkPremiumMember } from '../../components/utlity/checkPremiumMember';
import {connect} from 'react-redux';
import * as actions from '../../store/customer/actions';

const PlaylistDetail = ({ displayLoading, hideLoading, getPlayListSongs, albumSongs, clearCustomerPlaylist, deleteUserPlaylist, userProfile }) => {
    
    let match = useParams("/playlists/detail/:id");
    const navigate = useNavigate();
    const [isRemoveProcess, setIsRemoveProcess ] = useState(false);
    const [isDeleteProcess, setIsDeleteProcess ] = useState(false);
    const [processing, setProcessing ] = useState(false);
    const [ isPremium, setIsPremium ] = useState(false);  

    useEffect(() => { 
        if(checkPremiumMember(userProfile)){
            setIsPremium(true);
        } else {
            navigate('/subscription/plans');
        }
    },[userProfile])
  
    useEffect(() => { 
      loadPageData()
    },[]);
    
    const loadPageData = async () => {
        setProcessing(true);
        displayLoading();
        await getPlayListSongs({album_id: match.id});
        hideLoading();
        setProcessing(false);
        window.scrollTo({top: 0});
    }

    const clearPlaylist = async() => {
        setProcessing(true)
        displayLoading();
        setIsRemoveProcess(true);
        await clearCustomerPlaylist({album_id: match.id}).then(res => {
             
        });
        await getPlayListSongs({album_id: match.id});
        setIsRemoveProcess(false);
        hideLoading();
        setProcessing(false);
        window.scrollTo({top: 0});
    }

    const deletePlaylist = async() => {
        displayLoading();
        setIsDeleteProcess(true);
        await deleteUserPlaylist({album_id: match.id}).then(res => {
             navigate('/playlists/home')
        });
        window.scrollTo({top: 0});
    }
    

    return (
        <MusicDashboard accountPages={true} middleSectionCssClass={"single-version-section"}>
                <div className="content-sec">
                    <div className="play-list-sec">
                        <div className="top-heading">
                            <div className="back-arrow-head">
                                <span onClick={() => navigate(-1)} className="cp">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 6L8.09618 9.78462C8.06582 9.81224 8.04162 9.8456 8.02508 9.88265C8.00853 9.91969 8 9.95963 8 10C8 10.0404 8.00853 10.0803 8.02508 10.1174C8.04162 10.1544 8.06582 10.1878 8.09618 10.2154L12 14" stroke="#080A0D" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                                { albumSongs.album ? <h2>Playlist {albumSongs.album.title}</h2> : ''}
                            </div>
                            <div className="btn-sec">
                            {
                                isRemoveProcess ? 
                                    <button className="secondary-btn" style={{height: '36px'}}><Spinner animation="border" variant="dark" size="sm" /></button>
                                :
                                    <button className="secondary-btn" style={{height: '36px'}} onClick={() => clearPlaylist()}>Clear Playlist Song</button>
                            }
                            {
                                isDeleteProcess ? 
                                    <button className="secondary-btn" style={{height: '36px'}}><Spinner animation="border" variant="dark" size="sm" /></button>
                                :
                                    <button className="secondary-btn" style={{height: '36px'}} onClick={() => deletePlaylist()}>Delete Playlist</button>
                            }
                            </div>
                        </div>
                        <div className="inner-block">
                            <div className="top-list ">
                                <ul className="tab-sec">
                                    <li><Link to={`/my-library/favourite`}>Favorites</Link></li>
                                    <li className="divide active"><span>Playlists</span></li>
                                    <li><Link to={`/my-library/download-rescue`}>Download Rescue</Link></li>
                                </ul>
                                {
                                    processing ?  <SongTrackLoader itemCount={20} /> :
                                    albumSongs && albumSongs.songs && albumSongs.songs.length > 0 ?
                                        <PlaylistSongTrack songs={albumSongs.songs} sectionName={'playlist'} albumId={match.id} />
                                    : <div className="alert alert-danger" style={{paddingLeft: '10px'}}>No songs added to the playlist. <Link to="/" className="text-3">here</Link> to add song to your playlist.</div>
                                }
                            </div>
                        </div>
                    </div>
                    <CuratedSpotlightCarousel title={'Suggested for you'}/>
                </div>
            </MusicDashboard>
    )
}

function mapStateToProps(state) {
    return {
        userProfile: state.customer.customerProfile,
        albumSongs: state.customer.playlistAlbumSongs
    };
}
      
function mapDispatchToProps(dispatch) {
    return {
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        getPlayListSongs: (post) => dispatch(actions.getPlayListSongs(post)),
        clearCustomerPlaylist: (post) => dispatch(actions.clearCustomerPlaylist(post)),
        deleteUserPlaylist: (post) => dispatch(actions.deletePlaylist(post)),
        
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PlaylistDetail);