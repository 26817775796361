import React, { useState, useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import {  useNavigate } from "react-router-dom";
import config from '../../config/config';
import { checkCustomer } from '../../components/utlity/checkCustomer';
import { checkPremiumMember } from '../../components/utlity/checkPremiumMember';
import { openPremiumMemberSwal } from '../../components/utlity/openPremiumMemberSwal';
import {connect} from 'react-redux';
import * as downloadActions from '../../store/download/actions';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MtkDownloadBundleButton = ({ downloadBundleSong, userProfile, entity_id, getCustomerDownloads, themeType, isCkExclusive }) => {

    const MySwal = withReactContent(Swal)
    const navigate = useNavigate();
    const [ downloadInProcess, setDownloadInProcess ] = useState(false);
    const [ canAccess, setCanAccess ] = useState(false);
    const [ isPremium, setIsPremium ] = useState(false);  
    
    useEffect(() => { 
        if(userProfile){
            if(checkCustomer(userProfile)){
                setCanAccess(true);
            }
            if(checkPremiumMember(userProfile)){
                setIsPremium(true);
            }
        }
    },[userProfile])

    const processDownload = (entity_id) => {
        downloadBundleSong({ entity_id: entity_id}).then(res => {
            setDownloadInProcess(false);
            // if(res.payload.response && res.payload.response.status === 403){
            //     // alert(res.payload.response.data.message);
            //     navigate('/subscription/plans');
            // } else 
            if(res.payload.response && res.payload.response.status === 403){
                MySwal.fire({
                    title: "Need Attention",
                    text: res.payload.response.data.message[0],
                    type: "danger",
                    allowOutsideClick: false,
                });
            } else if(res.payload.data) {
                getCustomerDownloads();
                const data = res.payload.data;
                const zipUrl = `${config.downloadSelfUrl}tmp/${encodeURIComponent(data.zip)}`;
                window.location.assign(zipUrl);
            }
        });
    }

    const downloadBundle = (entity_id) => { 
        setDownloadInProcess(true);
        // if(isCkExclusive){
        //     if(isPremium){
        //         processDownload(entity_id);
        //     } else {
        //         setDownloadInProcess(false);
        //         openPremiumMemberSwal();
        //     }
        // } else {
            if(canAccess){ 
                processDownload(entity_id);
            } else {
                setDownloadInProcess(false);
                navigate('/subscription/plans')
            }
        // }
      }
    
    if(downloadInProcess){
        return <Spinner animation="border" variant="light" size="lg" />
    } else {
        return (
            <span onClick={() => downloadBundle(entity_id)} className="cp" title="Download Bundle">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white" fillOpacity="0.1"/>
                    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="white"/>
                    <path d="M23.9994 17.4288H25.1423C25.2939 17.4288 25.4392 17.489 25.5464 17.5962C25.6535 17.7034 25.7137 17.8487 25.7137 18.0003V26.5717C25.7137 26.7232 25.6535 26.8686 25.5464 26.9758C25.4392 27.0829 25.2939 27.1431 25.1423 27.1431H14.8566C14.705 27.1431 14.5597 27.0829 14.4525 26.9758C14.3454 26.8686 14.2852 26.7232 14.2852 26.5717V18.0003C14.2852 17.8487 14.3454 17.7034 14.4525 17.5962C14.5597 17.489 14.705 17.4288 14.8566 17.4288H15.9994" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M19.999 12.8574V22.0003" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M17.7139 19.7148L19.9996 22.0006L22.2853 19.7148" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </span>
        )
    }
    
}
function mapStateToProps(state) {
    return {
        userProfile: state.customer.customerProfile,
        themeType: state.theme.theme,
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
        downloadBundleSong: (data) => dispatch(downloadActions.downloadBundleSong(data)),
        getCustomerDownloads: () => dispatch(downloadActions.getCustomerDownloads())
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(MtkDownloadBundleButton);
  