import React, {useState, useEffect, createRef} from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import AccountNav from '../../elements/accountElements/accountNav';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form'
import { PatternFormat } from 'react-number-format';
import  API_BASE_URL  from '../../config/config';
import { FilePond, File, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';  
// Import FilePond styles
import 'filepond/dist/filepond.min.css'

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {connect} from 'react-redux';
import * as commonActions from '../../store/common/actions';
import * as customerAction from '../../store/customer/actions';
import { logout } from "../../store/authentication/actions";






const EditProfile = ({customer, getCountryList, countryList, authentication, updateProfile, getUserProfile, user, displayLoading, hideLoading}) => {
    registerPlugin(FilePondPluginFileValidateType);
    const fileInstance = createRef();
    const [formData, setFormData] = useState();
    const [formDataEnter, setFormDataEnter] = useState(false);
    const [isCountrySelected, setIsCountrySelected] = useState(false);
    const [ processing, setProcessing ] = useState(false);
    const [ responseError, SetResponseError ] = useState();
    const [ responseSuccess, SetResponseSuccess ] = useState();
    const [ errors, setErrors ] = useState({});
    const [validated, setValidated] = useState(false);
    const [region, setRegion] = useState();
    const [files, setFiles] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const uploadURL = API_BASE_URL.apiUrl+"/customer/upload-profile-image.json?token="+authentication.user+"&store_id=1&lang_code=en";

    useEffect(() => { 
        getCountryList();
    },[]);

    useEffect(() => { 
        if(customer && customer.customerProfile && formDataEnter === false){
            setFormData({
                firstname: customer && customer.customerProfile && customer.customerProfile.firstname ? customer.customerProfile.firstname : '',
                lastname: customer && customer.customerProfile && customer.customerProfile.lastname ? customer.customerProfile.lastname : '',
                street: customer && customer.customerProfile && customer.customerProfile.street ? customer.customerProfile.street : '',
                country_id: customer && customer.customerProfile && customer.customerProfile.country_id ? customer.customerProfile.country_id : '',
                region_id: customer && customer.customerProfile && customer.customerProfile.region_id ? customer.customerProfile.region_id : '',
                city: customer && customer.customerProfile && customer.customerProfile.city ? customer.customerProfile.city : '',
                postcode: customer && customer.customerProfile && customer.customerProfile.postcode ? customer.customerProfile.postcode : '',
                email: customer && customer.customerProfile && customer.customerProfile.email ? customer.customerProfile.email : '',
                dj_name: customer && customer.customerProfile && customer.customerProfile.dj_name ? customer.customerProfile.dj_name : '',
            });
            setFormDataEnter(true);
            setInputValue(customer && customer.customerProfile && customer.customerProfile.city ? customer.customerProfile.city : '')
        }
        if(isCountrySelected == false && customer && customer.customerProfile &&  countryList && countryList.length > 0){
            const selectedCountry = countryList.find((element) => {
                return element.id === customer.customerProfile.country_id;
            });
            if(selectedCountry && selectedCountry.region){
                setRegion(selectedCountry.region)
                setIsCountrySelected(true);
            }
            
            
        }
    },[customer])
  
    const handleChange = (e) => { 
        const { name, value } = e.target;
        if(name == 'city'){
            if (/^[a-zA-Z]*$/.test(value) || value === '') {
                setInputValue(value);
                setFormData({
                    ...formData,
                    [name]: value
                });  
            }
        } else {
            setFormData({
                ...formData,
                [name]: value
            });  
        }
          
        
        if(name === 'country_id'){
            const selectedCountry = countryList.find((element) => {
                return element.id === value;
            });
            if(value != ''){
                setRegion(selectedCountry.region);
            }
        }
        
        if(name == 'postcode'){
            if ( value.length < 3  || value.length > 7){
                setErrors({
                    ...errors,
                    postcode: 'Zip Code should be atleast 3 and maximum 7 digit!'
                });
            } else {
                setErrors({
                    ...errors,
                    postcode: ''
                });
            } 
            
            
        }  else {
            findFormErrors();
        }
    }

    const findFormErrors = () => {
        const { firstname, lastname, street, country_id, region_id, postcode, email, city, dj_name } = formData
        let newErrors = {}

        
        // password errors
        if ( !firstname || firstname === '' ) newErrors.firstname = 'First Name cannot be blank!'
        if ( !lastname || lastname === '' ) newErrors.lastname = 'Last Name cannot be blank!'
        if ( !email || email === '' ) newErrors.email = 'Enter email address'
        if ( !country_id || country_id === '' ) newErrors.country_id = 'Country cannot be blank!'
        if ( !region_id || region_id === '' ) newErrors.region_id = 'State cannot be blank!'
        if ( !city || city === '' ) newErrors.city = 'City cannot be blank!'
        if ( !postcode || postcode === '' ) newErrors.postcode = 'Zip Code cannot be blank!'
        if ( postcode && postcode.length < 3  || postcode.length > 7){
            newErrors.postcode = 'Zip Code should be atleast 3 and maximum 7 digit!'
        } 
        if ( !street || street === '' ) newErrors.street= 'Address cannot be blank!'
        if ( !dj_name || dj_name === '' ) newErrors.dj_name= 'DJ Name cannot be blank!'
        if(email && !validateEmail(email)){
            newErrors.email = 'Your e-mail has been entered in the wrong format. Please check and try again';
          }
        // console.log('newErrors', newErrors)
        setErrors(newErrors);
        return newErrors
    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    }

    const handleSubmit = (event) => { 
        

        const checkErrors = findFormErrors();
        // console.log('check 1',checkErrors);
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        // console.log('check 2', Object.keys(checkErrors).length)
        if ( Object.keys(checkErrors).length > 0 ) {
            // We got errors!
            setErrors(checkErrors);
            // console.log('check 3');
        } else {
            displayLoading(); 
            setProcessing(true);
            SetResponseError('');
            SetResponseSuccess('');
            updateProfile(formData).then(res => {
                if(res && res.payload && res.payload.message && res.payload.message.errors){
                    SetResponseError(res.payload.message.errors[0]);
                    SetResponseSuccess('');
                    hideLoading();
                    setProcessing(false);
                } else {
                    SetResponseError('');
                    SetResponseSuccess('Profile successfully updated');
                    hideLoading();
                    setProcessing(false);
                    setTimeout(resetMessaage, 4000);
                }
            });
            
        }
    }

    const resetMessaage = () => {
        SetResponseSuccess('');
    }

    const getProfile =  async() => {
        
        await getUserProfile({token: user}).then(res => {
            // window.location.href= "/";
        })
    }

    const logout = () => {
        window.location.href='/logout';
    }

  return (
    <MusicDashboard  accountPages={true} middleSectionCssClass={'account-info-sec'}>
       	<div className="content-sec account-info-sec">
            <div className="account-block">
                <div className="top-heading">
                    <h2>Account Settings</h2>
                </div>
                <AccountNav />
                <div className="account-inner-block">
                    <div className="edit-profile">
                        <div className="title-heading">
                            {/* <h3> */}
                            <div className="edit-profile-img">
                            <div className="edit-photo">
                                {
                                    customer && customer.customerProfile ?
                                        <div className="photo">
                                            {
                                                customer.customerProfile.profile_picture ?
                                                    <img src={customer.customerProfile.profile_picture} title={customer.customerProfile.dj_name}/>
                                                :
                                                    <div className="circle">
                                                        
                                                        <p className="circle-inner">
                                                            {/* {customer.customerProfile.dj_name ? Array.from(customer.customerProfile.dj_name)[0] : Array.from(customer.customerProfile.firstname)[0]} */}
                                                            {customer.customerProfile.dj_name && customer.customerProfile.dj_name[0] ? Array.from(customer.customerProfile.dj_name)[0] : customer.customerProfile.firstname && customer.customerProfile.firstname[0] ? Array.from(customer.customerProfile.firstname)[0] : ''}
                                                        </p> 
                                                       
                                                        {/* <p className="circle-inner">
                                                            {customer.customerProfile.dj_name ? customer.customerProfile.dj_name : customer.customerProfile.firstname}
                                                        </p> */}
                                                    </div>
                                            }
                                       
                                            
                                        </div>
                                    : ''
                                            }
                                
                            </div>
                                <FilePond
                                ref={fileInstance}
                                            files={files}
                                            allowFileTypeValidation={true}
                                            acceptedFileTypes={'image/*'}
                                            onupdatefiles={setFiles}
                                            allowMultiple={true}
                                            maxFiles={1}
                                            server={uploadURL}
                                            name="UploadFilesForm[files]" 
                                            labelIdle='Edit photo'
                                            onprocessfile={(error, file) => {
                                                // Set currently active file objects to this.state
                                                getProfile(); 
                                                var el = document.querySelectorAll(".filepond--file-action-button"); // this element contains more than 1 DOMs.
                                                for(var i =0; i < el.length; i++) {
                                                    el[i].onclick = function() { 
                                                        console.log("target name should be here")};
                                                }
                                                // console.log('ref', fileInstance.restoreElement())
                                            }}
                                        />
                                {/* </h3> */}

                            </div>
                            <span onClick={() => logout()} className="log-link text-5 cp">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 14V16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17H5C4.73478 17 4.48043 16.8946 4.29289 16.7071C4.10536 16.5196 4 16.2652 4 16V5C4 4.73478 4.10536 4.48043 4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4H12C12.2652 4 12.5196 4.10536 12.7071 4.29289C12.8946 4.48043 13 4.73478 13 5V7" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M10 10.5H17" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M15 8.5L17 10.5L15 12.5" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                Log Out
                            </span> 
                        </div>
                        <Form  validated={validated} onSubmit={handleSubmit}>
                            <div className="form-content">
                            <div className="mt-1 mb-1 response-msg">
                                {responseSuccess ? <div className="alert alert-success" style={{width: '100%'}}>{responseSuccess}</div>: ''}
                                {responseError ? <div className="alert alert-danger" style={{width: '100%'}}>{responseError}</div>: ''}
                            </div>
                                <div>
                                    <Form.Group className="form-group" controlid="formBasicEmail">
                                        <Form.Label>Email Address<span className="required">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Email" name="email" defaultValue={customer.customerProfile.email} onChange={handleChange} isInvalid={ !!errors.email } onBlur={handleChange} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.email }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="form-group" controlid="formBasicEmail" >
                                        <Form.Label>DJ Name<span className="required">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="DJ Name" name="dj_name" defaultValue={customer.customerProfile.dj_name} onChange={handleChange} isInvalid={ !!errors.dj_name } onBlur={handleChange} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.dj_name }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div>
                                    <Form.Group className="form-group" controlid="formBasicEmail" >
                                        <Form.Label>First Name<span className="required">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="First Name" name="firstname" defaultValue={customer.customerProfile.firstname} onChange={handleChange} isInvalid={ !!errors.firstname } onBlur={handleChange} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.firstname }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="form-group" controlid="formBasicEmail">
                                        <Form.Label>Last Name<span className="required">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Last Name" name="lastname" defaultValue={customer.customerProfile.lastname} onChange={handleChange} isInvalid={ !!errors.lastname } onBlur={handleChange} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.lastname }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div>
                                    <Form.Group className="form-group" controlid="formBasicEmail">
                                        <Form.Label>Address<span className="required">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Address" name="street" defaultValue={customer.customerProfile.street} onChange={handleChange} isInvalid={ !!errors.street } onBlur={handleChange} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.street }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="form-group" controlid="formBasicEmail">
                                        <Form.Label>Phone Number</Form.Label>
                                        <PatternFormat customInput={Form.Control} name="telephone" format="##########" value={customer.customerProfile.telephone} allowEmptyFormatting={false} mask="_" onChange={handleChange} isInvalid={ !!errors.telephone } onBlur={handleChange} />
                                        {/* <Form.Control type="number" placeholder="Phone Number" name="telephone" defaultValue={customer.customerProfile.telephone} onChange={handleChange} isInvalid={ !!errors.telephone } onBlur={handleChange} /> */}
                                        <Form.Control.Feedback type="invalid">
                                            { errors.telephone }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div>
                                    
                                    <Form.Group className="form-group" controlid="formBasicEmail">
                                        <Form.Label>City<span className="required">*</span></Form.Label>
                                        {/* <Form.Control type="text" placeholder="City" name="city" defaultValue={customer.customerProfile.city} value={inputValue}  onChange={handleChange} isInvalid={ !!errors.city } onBlur={handleChange} /> */}
                                        <Form.Control type="text" placeholder="City" name="city" defaultValue={customer.customerProfile.city}   onChange={handleChange} isInvalid={ !!errors.city } onBlur={handleChange} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.city }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="form-group" controlid="formBasicEmail">
                                        <Form.Label>Zip Code<span className="">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Zip Code" name="postcode" defaultValue={customer.customerProfile.postcode} onChange={handleChange} onBlur={handleChange} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.postcode }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div>
                                {
                                    isCountrySelected && customer.customerProfile && customer.customerProfile.country_id  ? 
                                        <Form.Group className="form-group" controlid="formBasicEmail">
                                            <Form.Label>Country<span className="required">*</span></Form.Label>
                                            <Form.Control  as="select" type="select" name="country_id" onChange={handleChange} defaultValue={parseInt(customer.customerProfile.country_id)} onBlur={handleChange}>
                                                <option value="">Select country</option> 
                                                {
                                                    countryList && countryList.length > 0 ?
                                                    countryList.map((country, index)=>{
                                                        return <option value={country.id} key={`country-${country.id}`} selected={customer.customerProfile.country_id == country.id ? true :  false}>{country.name}</option>
                                                    })
                                                    : ''
                                                }
                                            </Form.Control>
                                            {errors.country_id != '' ? <Form.Control.Feedback type="invalid">
                                                { errors.country_id }
                                            </Form.Control.Feedback> : '' }
                                        </Form.Group> :
                                        <Form.Group className="form-group" controlid="formBasicEmail">
                                            <Form.Label>Country<span className="required">*</span> </Form.Label>
                                            <Form.Control as="select" type="select" name="country_id" onChange={handleChange} defaultValue={customer.customerProfile.country_id} onBlur={handleChange}>
                                                <option value="">Select Country </option> 
                                                {
                                                    countryList && countryList.length > 0 ?
                                                    countryList.map((country, index)=>{
                                                        return <option value={country.id} key={`country-${country.id}`}>{country.name}</option>
                                                    })
                                                    : ''
                                                }
                                            </Form.Control>
                                            { errors.country_id != '' ? <Form.Control.Feedback type="invalid">
                                                { errors.country_id }
                                            </Form.Control.Feedback> : '' }
                                        </Form.Group>
                                }
                                    
                                {
                                    region && region.length > 0 ?
                                        <Form.Group className="form-group" controlid="formBasicEmail">
                                            <Form.Label>State<span className="required">*</span></Form.Label>
                                            <Form.Control as="select" type="select" name="region_id" onChange={handleChange} defaultValue={customer.customerProfile.region_id} onBlur={handleChange}>
                                                <option value="">Select Region</option> 
                                                {
                                                    region.map((reg, index)=>{
                                                    return <option value={reg.id} key={`region-${reg.id}`}>{reg.name}</option>
                                                    })
                                                }
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                { errors.region_id }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    : ''
                                }
                                </div>
                                
                            </div>
                            <div className="save-btn">
                            { processing ? <button className="primary-btn"><Spinner animation="border" variant="dark" size="sm" /></button> : <button type="submit" className="primary-btn">Save changes</button> } 
                            </div>
                        </Form>
                        
                    </div>
                </div>
            </div>
		</div>
		
        
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
    return {
        customer: state.customer,
        countryList: state.common.countryList,
        user: state.authentication.user,
        authentication: state.authentication
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCountryList: () => dispatch(commonActions.getCountryList()),
        updateProfile: (post) => dispatch(customerAction.updateProfile(post)),
        getUserProfile: (token) => dispatch(customerAction.getCustomerProfile(token)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    	hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);