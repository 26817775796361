import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';
import CoverImage from './../../../assets/images/make-the-kut/Cover.png';
import Top10ThisWeek from './top10ThisWeek';
import Top10ThisMonth from './top10ThisMonth';

const  TopListing = ({  }) => {

	
	useEffect(() => { 

    },[]);
    
    return (
        <div className="top-listing">
            <Top10ThisWeek />
            <Top10ThisMonth />
		</div>
    );
  
}


function mapStateToProps(state) {
	return {
	};
 }
 
 function mapDispatchToProps(dispatch) {
	return {
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(TopListing);
