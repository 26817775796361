export const GET_HOME_GENRE_SONG = 'HOME_GENRE_SONG';
export const GET_NEW_RELEASE_SONG = 'NEW_RELEASE_SONG';
export const GET_TOP_20_ALBUMS = 'TOP_20_ALBUMS';
export const ADD_SONG_TO_CRATE = 'ADD_SONG_TO_CRATE';
export const CUSTOMER_CRATES = 'GET_CUSTOMER_CRATE';
export const SET_CURRENT_SONG = 'SET_CURRENT_SONG';
export const SET_IS_PLAYING = 'IS_PLAYING';
export const RENEW_SONG = 'RENEW_SONG';
export const CREATE_NEW_SONG = 'CREATE_NEW_SONG';
export const EDIT_SONG_ENTITY = 'EDIT_SONG_ENTITY';
export const UPDATE_SONG_ENTITY = 'UPDATE_SONG_ENTITY';
export const DELETE_SONG_ENTITY = 'DELETE_SONG_ENTITY';
export const MP3_SEARCH_RESULT = 'MP3_SEARCH_RESULT';
export const MP3_SEARCH_SUGGESTION = 'MP3_SEARCH_SUGGESTION';
export const CHART_SONGS = 'CHART_SONGS';
export const ADD_SONG_TO_FAVOURITE = 'ADD_SONG_TO_FAVOURITE';
export const GET_CUSTOMER_FAVOURITE_SONGS = 'GET_CUSTOMER_FAVOURITE_SONGS';
export const GET_FAVOURITE_SONGS = 'GET_FAVOURITE_SONGS';
export const AUTOCOMPLETE_SEARCH_SONGS = 'AUTOCOMPLETE_SEARCH_SONGS';
export const SET_PLAYER_SONGS = 'SET_PLAYER_SONG';
export const GET_ARTIST_SONGS = 'GET_ARTIST_SONGS';
export const GET_SONG_REPORT = 'GET_SONG_REPORT';