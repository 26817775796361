import React, { useState, useEffect } from "react";
import {axiosReqOther} from "../../services/config";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from "react-router-dom";
import { checkPremiumMember } from '../../components/utlity/checkPremiumMember';
import {connect} from 'react-redux';

import * as commonAction from '../../store/common/actions';

const  ReportLyrics = ({  userProfile }) => {
  const MySwal = withReactContent(Swal)
  const navigate = useNavigate();

    useEffect(() => { 
        if(checkPremiumMember(userProfile)){
//             setIsPremuim(true);
        }
    },[userProfile])

    const reportExplicitLyrics = () => {
        Swal.fire({
            title: 'Report Explicit Lyrics',
            // input: 'text',
            html: '<textarea id="swal-textarea" class="form-control" style="display: flex; width: 100%; min-height: 150px;"  placeholder="Please give exact time & version(s) of songs that need to be cleaned."></textarea>',
            // inputAttributes: {
            //   autocapitalize: 'off'
            // },
            confirmButtonText: 'Submit',
            allowOutsideClick: true,
            preConfirm: (code) => {
  
              return new Promise(function (resolve) {
                // Validate input
                if (document.getElementById('swal-textarea').value == '') {
                    Swal.showValidationMessage("Enter Details"); // Show error when validation fails.
                    Swal.enableButtons(); // Enable the confirm button again.
                    
                } else {
                    Swal.resetValidationMessage(); // Reset the validation message.
                    const data = {
                        token: localStorage.user,
                        details: document.getElementById('swal-textarea').value
                    }
                    // const response = verifyDeviceCode(data);
                    const url = `common/song-report.json?store_id=1&lang_code=en`;
                    return axiosReqOther(url, data, 'post').then(res => { 
                    
                    resolve([
                        document.getElementById('swal-textarea').value
                    ]);
                        Swal.fire("Done!","It was succesfully submitted!","success");
                        return res;  
                    }).catch(err => { 
                        Swal.showValidationMessage(
                            `Request failed: ${err.response.data.message.invalid}`
                        );
                        Swal.enableButtons(); // Enable the confirm button again.
                    });
                    
                }
            })            
            }
          })
    }

    return (
      <button className="report-button" onClick={() => reportExplicitLyrics()}>Report Explicit Lyrics</button>
    );
  
}


function mapStateToProps(state) {
    return {

    };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
   
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(ReportLyrics);
