export const GET_CUSTOMER_PROFILE = 'CUSTOMER_PROFILE';
export const RESET_CUSTOMER_PASSWORD_REQUEST = 'RESET_CUSTOMER_PASSWORD_REQUEST';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CUSTOMER_SIGNUP = 'CUSTOMER_SIGNUP';
export const SUBMIT_DJ_APP = 'SUBMIT_DJ_APP';
export const GET_TOKEN_BY_EMAIL = 'GET_TOKEN';
export const ADD_PLAYLIST_ALBUM = 'ADD_PLAYLIST_ALBUM';
export const ADD_PLAYLIST_ALBUM_IMAGE = 'ADD_PLAYLIST_ALBUM_IMAGE';
export const GET_PLAYLIST_ALBUM_IMAGE = 'GET_PLAYLIST_ALBUM_IMAGE';
export const GET_CUSTOMER_PLAYLIST_ALBUMS = 'CUSTOMER_PLAYLIST_ALBUMS';
export const ADD_SONG_TO_PLAYLIST_ALBUMS='ADD_SONG_TO_PLAYLIST_ALBUMS';
export const GET_CUSTOMER_PLAYLIST_SONGS='GET_CUSTOMER_PLAYLIST_SONGS';
export const REMOVE_SONG_FROM_CUSTOMER_PLAYLIST='REMOVE_SONG_FROM_CUSTOMER_PLAYLIST';
export const CLEAR_CUSTOMER_PLAYLIST='CLEAR_CUSTOMER_PLAYLIST';
export const DELETE_CUSTOMER_PLAYLIST='DELETE_CUSTOMER_PLAYLIST';
export const SEND_DEVICE_VERIFICATION_CODE='SEND_DEVICE_VERIFICATION_CODE';
export const VERIFY_DEVICE_CODE='VERIFY_DEVICE_CODE';
export const UPDATE_PROFILE_DATA='UPDATE_PROFILE_DATA';
export const GET_CUSTOMER_DEVICES='GET_CUSTOMER_DEVICES';
export const REMOVE_CUSTOMER_DEVICE='REMOVE_CUSTOMER_DEVICE';
