import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import SongTrackLoader from '../../components/loader/songTrackLoader';
import MonthlyTopSongs from '../../components/monthlyTopSongs/monthlyTopSongs';
import RightSide from "../../components/rightSide/rightSide";
import SongTrack from '../../components/songTrack/songTrack';
import Pagination from "react-js-pagination";
import Spinner from 'react-bootstrap/Spinner';
import MusicDashboard from '../../layouts/musicDashboard';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { connect } from 'react-redux';

import * as songActions from '../../store/song/actions';
import * as genreActions from '../../store/genres/actions';


const  ArtistSongs = ({ loggingIn, artistSongs, getArtistSongs, paginationData, displayLoading, hideLoading }) => {

    let match = useParams("/mp3/artist/:name");
    const navigate = useNavigate();
    const [ page, setPage ] = useState(1);
    const [ order, setOrder ] = useState('entity_id');
    const [ orderDir, setOrderDir ] = useState('DESC');
    const [ songSectionId, setSongSectionId ] = useState(1);
    const [ processing, setProcessing ] = useState(false);
    const [ artistName, setArtistName ] = useState([]);
	
  	useEffect(() => { 
		loadData()
	},[])
	   
	const loadData = async() =>{
        // Get genres filter to display on homepage
        displayLoading();
        setProcessing(true);
		await getArtistSongs({page: page, order: order, orderDir, song_section_id: songSectionId, artist: match.name});
        hideLoading();
        setProcessing(false);
    } 
    
    const handlePageChange = async(pageNumber) => {
        displayLoading();
        setProcessing(true);
        setPage(pageNumber);
        await getArtistSongs({page: pageNumber, order: order, orderDir, song_section_id: songSectionId, artist: match.name}).then((res) => {
            
        });
        hideLoading();
        setProcessing(false);
    }

	return (
			<MusicDashboard rightContent={<RightSide blocks={{ top30TrendingTracks: true}} />}>
                <div className="content-sec">
                    <MonthlyTopSongs />
                    <div className="play-list-sec">
                        <div className="top-heading">
                            <h2>{match.name}'s Songs</h2>
                        </div>
                        
                        <div className="inner-block">
                            { processing ? <SongTrackLoader itemCount={20} /> : <SongTrack songs={artistSongs} />  }
                        </div>
                    </div>
                    <div className="pagingnation-sec">
                        {
                        paginationData ? 
                            <Pagination
                                activePage={paginationData.page}
                                itemsCountPerPage={paginationData.perPageLimit}
                                totalItemsCount={paginationData.totalCount}
                                // pageRangeDisplayed={pageRangeDisplayed}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                        /> : ''
                        }
                    </div>
                </div>
        </MusicDashboard>
    );
}

function mapStateToProps(state) {
    console.log('dd', state.song.artistSongs)
	return {
        loggingIn: state.authentication.loggedIn,
        artistSongs: (state.song.artistSongs && state.song.artistSongs.songs  ) ? state.song.artistSongs.songs : [],
        paginationData:  (state.song.artistSongs && state.song.artistSongs.pagination) ? state.song.artistSongs.pagination : '',
	};
 }
 
function mapDispatchToProps(dispatch) {
	return {
		getArtistSongs: (post) => dispatch(songActions.getArtistSongs(post)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    	hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(ArtistSongs);
