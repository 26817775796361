import React, {useState, useEffect} from "react";


import MusicDashboard from "../../layouts/musicDashboard";
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import config from '../../config/config';
import SongTrackLoader from '../../components/loader/songTrackLoader';
import CrateSongTrack from '../../components/songTrack/crateSongTrack';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { checkCustomer } from '../../components/utlity/checkCustomer';
import { checkPremiumMember } from '../../components/utlity/checkPremiumMember';
import {connect} from 'react-redux';
import * as crateActions from '../../store/crate/actions';
import * as songActions from '../../store/song/actions';

import { useNavigate } from "react-router-dom";


const CrateIndex = ({ getCrateList, crateSongs, crateGenres, getCustomerCrates, displayLoading, hideLoading, clearAllTrack, downloadBatchFiles, userProfile, downloadCrateZipFiles }) => {
	const navigate = useNavigate();
	const MySwal = withReactContent(Swal) 
	const [page, setPage] = useState(1);
	const perPageLimit =  config.itemsCountPerPage;
	const [currentGenreId, setCurrentGenreId] = useState(0);
	const [processing, setProcessing ] = useState(false);
	const [ dataProcessingDownload, setDataProcessingDownload ] = useState(false);
	const [ dataProcessingClearCrate, setDataProcessingClearCrate ] = useState(false);
	const [ canAccess, setCanAccess ] = useState(false);
	const [ isPremium, setIsPremium ] = useState(false);
	const [ isDownloadButtonEnable, setIsDownloadButtonEnable ] = useState(false);
	const [zipped, setZipped] = useState([]);
	const [ filledZip, setFilledZip] = useState(0);

	useEffect(() => { 
		loadPageData()
	},[]);

	
	useEffect(() => { 
		let fillWidth =  (zipped.length / 10 ) * 100;
		setFilledZip(fillWidth);
	},[zipped]);

	useEffect(() => { 
		if(checkCustomer(userProfile)){
			setCanAccess(true);
		}
		if(checkPremiumMember(userProfile)){
            setIsPremium(true);
        }
	},[userProfile])
	
	const loadPageData = async () => {
		
		setProcessing(true);
		// displayLoading();
		await getCrateList({page: page, pageLimit: perPageLimit, genre_id: currentGenreId}, () => {
			// this.setState({genres: this.props.song.latestSong});
			
		})
		await getCustomerCrates();
		hideLoading();
		setProcessing(false);
		window.scrollTo({top: 0});
	}

	const getGenreCrate = async(genreID) => {
		displayLoading();
		setProcessing(true);
		setCurrentGenreId(genreID);
		await getCrateList({page: page, pageLimit: perPageLimit, genre_id: genreID}, () => {
		
		})
		hideLoading();
		setProcessing(false);
	}

	const emptyCrate = async () => {
		setProcessing(true);
		setDataProcessingClearCrate(true);
		await clearAllTrack();
		await getCustomerCrates();
		setDataProcessingClearCrate(false);
		setProcessing(false);
	}

	const setEnabledDownloadButton = (valueBool) => {
		if(zipped.length <= 9){
			setIsDownloadButtonEnable(valueBool);
		} else if(zipped.length > 9){
			window.scrollTo({top: 0});
			MySwal.fire({
				title: "Need Attention",
				text: 'Please download the full ZIP file first before adding more songs to it.',
				type: "warning",
				allowOutsideClick: true,
			});
		}
	}

	const downloadZip = async() => {
		if(zipped.length > 2){
			setDataProcessingDownload(true);
			if(canAccess){
				// console.log(zipped);
				await downloadCrateZipFiles({versions: zipped}).then(res => {
					if(res.payload.response && res.payload.response.status === 403){
						alert(res.payload.response.data.message);
					} else if(res.payload.data) {
						const data = res.payload.data;
						const zipUrl = `${config.downloadSelfUrl}tmp/${data.zip}`;
						window.location.assign(zipUrl);
					}
				});
			}
			await loadPageData();
			setZipped([]);
			setIsDownloadButtonEnable(false);
			setDataProcessingDownload(false);
			
		}
	}

	const downloadBatch = async () => { 
		setDataProcessingDownload(true);
		if(canAccess){
			const data = {
				genre_id: currentGenreId,
				isPremium: isPremium ? true : false
			}
			await downloadBatchFiles(data).then(res => {
				if(res.payload.response && res.payload.response.status === 403){
					alert(res.payload.response.data.message);
				} else if(res.payload.data) {
					const data = res.payload.data;
					const zipUrl = `${config.downloadSelfUrl}tmp/${data.zip}`;
					window.location.assign(zipUrl);
				}
			});
			await getCrateList({page: 1, pageLimit: perPageLimit, genre_id: 0}, () => {
			
			});
			setCurrentGenreId(0);
			setDataProcessingDownload(false);
		} else {
			navigate('/subscription/plans')
		}
		// if(userProfile && userProfile.group_id == 2){
		// 	const data = {
		// 		genre_id: currentGenreId
		// 	}
		// 	if(userProfile.payment_profile_status == 'paid'){
		// 		await downloadBatchFiles(data).then(res => {
		// 			if(res.payload.response && res.payload.response.status === 403){
		// 				alert(res.payload.response.data.message);
		// 			} else if(res.payload.data) {
		// 				const data = res.payload.data;
		// 				const zipUrl = `${config.downloadSelfUrl}tmp/${data.zip}`;
		// 				window.location.assign(zipUrl);
		// 			}
		// 		});
		// 		await getCrateList({page: 1, pageLimit: perPageLimit, genre_id: 0}, () => {
				
		// 		});
		// 		setCurrentGenreId(0);
		// 	} else if(userProfile.payment_profile_status == 'unpaid'){
		// 		navigate('/subscription/plans')
		// 	} else if(userProfile.payment_profile_status == 'canceled' && userProfile.profile_status == 'Cancelled'){
        //         var d1 = new Date();
        //         var d2 = new Date(userProfile.cancel_download_date);
        //         var same = d1.getTime() === d2.getTime();
        //         // console.log('checked', userProfile.cancel_download_date)
        //         if(d2.getTime() > d1.getTime()){
        //             await downloadBatchFiles(data).then(res => {
		// 				if(res.payload.response && res.payload.response.status === 403){
		// 					MySwal.fire({
		// 						title: "Need Attention",
		// 						text: res.payload.response.data.message,
		// 						type: "success",
		// 						allowOutsideClick: true,
		// 					});
		// 				} else if(res.payload.data) {
		// 					const data = res.payload.data;
		// 					const zipUrl = `${config.downloadSelfUrl}tmp/${data.zip}`;
		// 					window.location.assign(zipUrl);
		// 				}
		// 			});
        //         } else {
        //             navigate('/subscription/plans');
        //         }
        //     } else {
        //         navigate('/subscription/plans');
        //     }
		// } else if(userProfile && userProfile.group_id == 3){
		// 		const data = {
		// 		genre_id: currentGenreId
		// 		}
		// 		await downloadBatchFiles(data).then(res => {
		// 		if(res.payload.response && res.payload.response.status === 403){
		// 			MySwal.fire({
		// 				title: "Need Attention",
		// 				text: res.payload.response.data.message,
		// 				type: "success",
		// 				allowOutsideClick: true,
		// 			});
		// 		} else if(res.payload.data) {
		// 			const data = res.payload.data;
		// 			const zipUrl = `${config.downloadSelfUrl}tmp/${encodeURIComponent(data.zip)}`;
		// 			window.location.assign(zipUrl);
		// 		}
		// 		});
		// 		await getCrateList({page: 1, pageLimit: perPageLimit, genre_id: 0}, () => {
				
		// 		});
		// 		setCurrentGenreId(0);
		// }
		// setDataProcessingDownload(false);
	}
	
  return (
    <MusicDashboard accountPages={true} middleSectionCssClass={"single-version-section"}>
       <div className="content-sec">
            <div className="play-list-sec">
                <div className="top-heading">
                    <h2>My Crate</h2>
                    <div className="btn-sec">
						{
							dataProcessingDownload ? <span className="primary-btn zip-btn" ><Spinner animation="border" variant="dark" size="sm" /></span>
							:
								isDownloadButtonEnable ? 
									<button className="primary-btn progress-btn" onClick={() => downloadZip()}><span className="zip-btn-progress-bar" style={{width: `${filledZip}%`}}><span>Download Zip ({zipped.length})</span></span></button> 
								:
									<span className="primary-btn disabled zip-btn" >Download Zip ({zipped.length})</span>
						}
                        {/* { dataProcessingDownload ? <button className="primary-btn" ><Spinner animation="border" variant="dark" size="sm" /></button> : crateGenres && crateGenres.length > 0 ? <button className="primary-btn" onClick={() => downloadBatch()}>Download All</button> : <button className="primary-btn disabled" disabled>Download All</button> } */}
  						{ dataProcessingClearCrate ? <button className="secondary-btn" ><Spinner animation="border" variant="dark" size="sm" /></button> : crateGenres && crateGenres.length > 0 ? <button className="secondary-btn" onClick={() => emptyCrate()}>Clear All</button> : <button className="secondary-btn disabled" disabled>Clear All</button> }
                    </div>
                </div>
                <div className="inner-block">
                    <div className="top-list">
                        <ul>
                            { crateGenres && crateGenres.length > 0 ? <li className={currentGenreId == 0 ? 'active cp': 'cp'} onClick={()=> getGenreCrate(0)}><span >All</span></li> : ''}
                            {
                                crateGenres && crateGenres.length > 0 ?
                                crateGenres.map((crate, index) => {
                                    return <li className={ currentGenreId == crate.genre_id ? 'active cp' : 'cp'} key={`crategenre${crate.genre_id}`} onClick={()=> getGenreCrate(crate.genre_id)}><span dangerouslySetInnerHTML={{__html: crate.name}} ></span></li>
                                })
                                : ''
                            }
                        </ul>
						{
							processing ? <SongTrackLoader itemCount={20} /> : 
							crateSongs && crateSongs.length > 0 ?
							<CrateSongTrack getParentCustoemrCrates={getGenreCrate} songs={crateSongs} currentGenreId={currentGenreId} perPageLimit={perPageLimit} page={page} setEnabledDownloadButton={setEnabledDownloadButton} setZipped={setZipped}  />
							: <div className="alert alert-danger">Your crate is empty.</div>
						}
                    </div>
                    
                </div>
        
          {/* { crateSongs && crateSongs.length > 0 ?  <Button variant="light" className="primary-bg" onClick={() => downloadBatch()}>Download batch</Button> : '' }
					{ crateSongs && crateSongs.length > 0 ? <Button variant="light" className="ml-4" onClick={() => emptyCrate()}>clear all tracks</Button> : '' } */}
          

        
          
          </div>
        </div>
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
  return {
    crateGenres: state.crate.crateGenres,
	crateSongs: state.crate.crateSongs,
	userProfile: state.customer.customerProfile    
  };
}

function mapDispatchToProps(dispatch) {
  return {
      getCrateList: (post) => dispatch(crateActions.getCrateList(post)),
      getCustomerCrates: () => dispatch(songActions.getCustomerCrates()),
      clearAllTrack: () => dispatch(crateActions.clearAllTrack()),
	  downloadBatchFiles: (data) => dispatch(crateActions.downloadBatchFiles(data)),
	  downloadCrateZipFiles: (data) => dispatch(crateActions.downloadCrateZipFiles(data)),
	  displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
      hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CrateIndex);