import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Link } from "react-router-dom";
const MySwal = withReactContent(Swal) 
export const openPremiumMemberSwal = () => {
    
        MySwal.fire({
            title: "Unlock Premium Benefits: Elevate Your Experience with an Upgrade!",
            html: '<p>To download CK Exclusive content <a href="/subscription/plans" class="text-3 upgrade-link" style="text-decoration: underline">Upgrade</a> to a premium membership</p>',
            type: "success",
            allowOutsideClick: true,
        });
    
}