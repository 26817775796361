import * as types from './type';

const initialState = {
  songInfo: '',
  songList: ''
};



export default function uploadsReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.SELECT_SONG: {
      if (action.payload && action.payload.data) {
        // console.log('action.payload', action.payload)
        return {
          ...state,
          songInfo: action.payload.data,
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.CHANGE_SONG_STATUS: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          songList: action.payload.data,
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    case types.GET_UPOLOADED_SONGS: {
      if (action.payload && action.payload.data) {
        // console.log('action.payload', action.payload)
        return {
          ...state,
          songList: action.payload.data,
        }
      } else {
        return {
          ...state
        }
      }
    }

    default: {
      return {
        ...state
      };
    }
    
  }

}