import {axiosReqGet, axiosReqOther} from "./config";
import { store } from '../store/store';

const USER_TOKEN = store.getState().authentication.user;

export const Crate = {
    getCrateList,
    clearAllTrack,
    downloadBatchFiles,
    downloadCrateZipFiles
}

function getCrateList(data){
    
    const url = 'crate/index.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

function clearAllTrack(){
    
    const url = 'crate/clear-crates.json?store_id=1&lang_code=en';
    const data = {token: USER_TOKEN};
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

function downloadBatchFiles(data){
    
    const url = 'crate/download-crate-bundle.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}


function downloadCrateZipFiles(data){
    
    const url = 'crate/download-crate-zip.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

export default Crate