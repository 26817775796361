import React , {useState, useEffect, createRef} from "react";
import {useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import FavButton from '../../elements/playerCustomElement/favButton';
import PlayerDownloadButton from '../../elements/playerCustomElement/playerDownloadButton';
import SongPlaylist from './songPlaylist';
import playerWaveLoading from './../../assets/images/player-wave.gif';
import AudioPlayer, {RHAP_UI} from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import WaveSurfer from 'wavesurfer.js';
// import { playList } from "./playList";
// import Editor from "./Editor";
import "./player.scss";
import playlistReducer from "../../store/playlist/reducers";
import * as playindexActions from '../../store/playindex/actions';
import * as isplayingActions from '../../store/isplaying/actions';
import * as playerActions from '../../store/player/actions';

const MusicPlayer = ({children, rightContent, playList, playerState, playIndex, setPlayIndex, setIsPlaying, isPlaying, setPlayer, themeType }) => {
    const { pathname } = useLocation()
    const hidePlayerPages = ['/profile/update', '/profile/change-password', '/subscription/plans',
        '/subscription/billing-history', '/helpdesk/faqs', '/helpdesk/home', '/account/setting'
    ];
    
    const audioInstance = createRef();
    const [playerInitState, setPlayerInitState] = useState(playerState);
    const [isPlaylistVisible, setIsPlaylistVisible] = useState(false);
    const [songDuration, setSongDuration] = useState(0);
    const [currentPlayingSong, setCurrentPlayingSong ] = useState();
    const [ displayWaveLoader, setDisplayWaveLoader ] = useState(false);

    const path = document.querySelector('#wave');
    const animation = document.querySelector('#moveTheWave');
    const m = 0.512286623256592433;

    const  buildWaveLoader = (w, h) => {
    
    const a = h / 4;
    const y = h / 2;
    
    const pathData = [
        'M', w * 0, y + a / 2, 
        'c', 
        a * m, 0,
        -(1 - a) * m, -a, 
        a, -a,
        's', 
        -(1 - a) * m, a,
        a, a,
        's', 
        -(1 - a) * m, -a,
        a, -a,
        's', 
        -(1 - a) * m, a,
        a, a,
        's', 
        -(1 - a) * m, -a,
        a, -a,
        
        's', 
        -(1 - a) * m, a,
        a, a,
        's', 
        -(1 - a) * m, -a,
        a, -a,
        's', 
        -(1 - a) * m, a,
        a, a,
        's', 
        -(1 - a) * m, -a,
        a, -a,
        's', 
        -(1 - a) * m, a,
        a, a,
        's', 
        -(1 - a) * m, -a,
        a, -a,
        's', 
        -(1 - a) * m, a,
        a, a,
        's', 
        -(1 - a) * m, -a,
        a, -a,
        's', 
        -(1 - a) * m, a,
        a, a,
        's', 
        -(1 - a) * m, -a,
        a, -a
    ].join(' ');
        if(path){
            path.setAttribute('d', pathData);
        }
    }


    useEffect(() => { 
        
        if(playList && playList.length >= 0){
            
           setCurrentPlayingSong(playList[(playIndex-1)]);
        }
    },[playIndex])

    useEffect(() => { 
        setPlayer(audioInstance);
        buildWaveLoader(90, 60);
    },[audioInstance])
    
    useEffect(() => { 
        if(playList && playerState && playList.length >= playerState.curPlayId){
        }
    },[playList])
    
    // useEffect(() => { 
    //     if(audioInstance && audioInstance.current && audioInstance.audio){
            
    //         if(!isPlaying){
    //             audioInstance.current.audio.current.pause();
    //         } else {
    //             audioInstance.current.audio.current.play();
    //         }
    //     }
    // },[isPlaying])

    

    
    const displayWaves = (musicSrc) => {
        
		document.getElementById('waveform').innerHTML = '';
        var wavesurfer = WaveSurfer.create({
            container: '#waveform',
            height: 80,
            progressColor: '#ff07b3',
            responsive: true,
            waveColor: '#d4d4d4',
            cursorColor: '#4a74a5'
        }); 
        
        
      wavesurfer.load(musicSrc);
    }
    
    const loadWaves = (event) => {
        
        setSongDuration(event.srcElement.duration);
        displayWaves(event.srcElement.currentSrc );
    }

    const onSeeking = (event) => {
        const width = ((event.srcElement.currentTime/event.srcElement.duration)*100);
        var e1 = document.getElementById('waveform').lastChild;
        if(e1 && e1.firstChild){
            var e2 = e1.firstChild;
            e2.style.width= width+'%';
        }
    }

    const changeSongTime = (event) => {
		
		// console.log('audioInstance', audioInstance.current)
        var obj = document.getElementById('waveform');
        var objWidth = obj.offsetWidth / songDuration;
        var offset = obj.getBoundingClientRect();
        // const clickedTime = Math.floor((event.clientX-obj.offsetLeft)  / objWidth);
        const clickedTime = Math.floor((event.clientX-offset.left)  / objWidth);
        const player = audioInstance.current.audio.current; 
        // console.log(clickedTime, obj.offsetWidth, offset.left, objWidth, event.clientX , obj.offsetLeft, songDuration);
        player.currentTime = clickedTime;
        player.play();
    }

    const onChangeCurrentTimeError= (event) => {
        var obj = document.getElementById('waveform');
        var objWidth = obj.offsetWidth / songDuration;
        const clickedTime = Math.floor((event.clientX-obj.offsetLeft)  / objWidth);
        const player = document.getElementsByTagName('audio')[0]; //.currentTime = clickedTime;
        player.currentTime = clickedTime;
        player.play();
    }

    const clickPrevious = (event) => {
        setDisplayWaveLoader(true);
        setTimeout(hidePlayerLoader, 4000);
        const playListLength = playList.length;
        if(playIndex == 1){
            setPlayIndex(playListLength);
        } else {
            setPlayIndex((playIndex-1))
        }
    }

    const cickNext = (event) => {
        setDisplayWaveLoader(true);
        setTimeout(hidePlayerLoader, 4000);
        const playListLength = playList.length;
        if(playListLength == playIndex){
            setPlayIndex(1);
        } else if(playListLength > playIndex){
            setPlayIndex((playIndex+1))
        }
    }

    const ended = (event) => {
        const playListLength = playList.length;
        
        if(playListLength == playIndex && audioInstance.current.props.loop){
            setPlayIndex(1)
        } else if(playListLength > playIndex){
            setPlayIndex((playIndex+1))
        }
    }

    const playerPlay = (e) => {
        
        setDisplayWaveLoader(true);
        setTimeout(hidePlayerLoader, 6000);
        setIsPlaying(true);
    }
    const playerPause = (e) => {
        setIsPlaying(false);
    }

    const hidePlayerLoader = () => {
        setDisplayWaveLoader(false);
    }
    
    const displayLoadingPlayer = () => {
        setDisplayWaveLoader(true);
        setDisplayWaveLoader(true);
        setTimeout(hidePlayerLoader, 6000);
    }
    
    if(hidePlayerPages.includes(pathname)){
        return null;
    } else if(playList && playList.length > 0) {
    return (
        <>
            <div className="play-bar">
                {
                    playList && currentPlayingSong && playList.length > 0 ? 
                    <AudioPlayer autoPlay ref={audioInstance}
                    src={currentPlayingSong.src}
                    volume={0.5}
                    muted={false}
                    showJumpControls={false}
                    showSkipControls={true}
                    showFilledVolume={true}
                    showFilledProgress={true}
                    defaultCurrentTime={'mm:ss'}
                    defaultDuration={'mm:ss'}
                    layout='horizontal'
                    listenInterval={10}
                    onPlay={e => playerPlay()}
                    onPause={e => playerPause()} 
                    onLoadedData={e=>{
                        displayLoadingPlayer();
                        loadWaves(e)
                    }}
                    onListen={e => onSeeking(e)} 
                    // onPlaying={e=>onPlaying(e)}
                    onChangeCurrentTimeError={e => onChangeCurrentTimeError(e)}
                    onClickPrevious={e => clickPrevious(e)} 
                    onClickNext={e => cickNext(e)}
                    onEnded={e => ended(e)}
                    customIcons={{
                        play : <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.97168 11.3912C0.974962 11.5817 1.03039 11.7676 1.13194 11.9288C1.23349 12.09 1.37728 12.2203 1.54768 12.3055C1.71784 12.4017 1.90995 12.4522 2.10539 12.4522C2.30083 12.4522 2.49295 12.4017 2.66311 12.3055L10.5717 7.41408C10.7438 7.33118 10.8889 7.2014 10.9906 7.03966C11.0922 6.87793 11.1461 6.6908 11.1461 6.49979C11.1461 6.30879 11.0922 6.12166 10.9906 5.95992C10.8889 5.79819 10.7438 5.66841 10.5717 5.58551L2.66311 0.730651C2.49295 0.634508 2.30083 0.583984 2.10539 0.583984C1.90995 0.583984 1.71784 0.634508 1.54768 0.730651C1.37728 0.815852 1.23349 0.946127 1.13194 1.10732C1.03039 1.26851 0.974962 1.45445 0.97168 1.64494V11.3912Z" fill={themeType == 'dark-theme' ? "#FFFFFF" : '#828282'}/>
                        </svg>,
                        
                        previous:<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 14.1669L5 5.83355M14.19 15.6111C14.2637 15.6693 14.3523 15.7056 14.4457 15.7158C14.5391 15.726 14.6335 15.7097 14.718 15.6687C14.8026 15.6277 14.8739 15.5638 14.9237 15.4842C14.9736 15.4046 15 15.3125 15 15.2186L15 4.78188C14.9999 4.68801 14.9733 4.59606 14.9234 4.51657C14.8735 4.43708 14.8022 4.37324 14.7177 4.33238C14.6331 4.29151 14.5388 4.27527 14.4455 4.28549C14.3522 4.29572 14.2637 4.33201 14.19 4.39022L7.58083 9.60772C7.52153 9.65452 7.4736 9.71415 7.44065 9.78213C7.4077 9.85011 7.39058 9.92467 7.39058 10.0002C7.39058 10.0758 7.4077 10.1503 7.44065 10.2183C7.4736 10.2863 7.52153 10.3459 7.58083 10.3927L14.19 15.6111Z" stroke={themeType == 'dark-theme' ? "#D7D7D7": '#828282'} strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>,
                        next:<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 5.83312V14.1664M5.81 4.38895C5.73629 4.3307 5.64766 4.2944 5.55427 4.2842C5.46088 4.27401 5.36651 4.29033 5.28197 4.33129C5.19742 4.37226 5.12613 4.43622 5.07626 4.51583C5.02639 4.59545 4.99996 4.6875 5 4.78145V15.2181C5.00012 15.312 5.02666 15.4039 5.07659 15.4834C5.12653 15.5629 5.19783 15.6268 5.28234 15.6676C5.36685 15.7085 5.46116 15.7247 5.55448 15.7145C5.64779 15.7043 5.73635 15.668 5.81 15.6098L12.4192 10.3923C12.4785 10.3455 12.5264 10.2859 12.5593 10.2179C12.5923 10.1499 12.6094 10.0753 12.6094 9.99978C12.6094 9.92424 12.5923 9.84967 12.5593 9.78169C12.5264 9.71371 12.4785 9.65408 12.4192 9.60728L5.81 4.38895Z" stroke={themeType == 'dark-theme' ? "#D7D7D7": '#828282'} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>,

                        volume:<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.3824 13.3456L13.667 13.061C13.7095 13.0185 13.7308 12.9972 13.7486 12.9789C15.09 11.5985 15.09 9.40149 13.7486 8.0211C13.7308 8.00283 13.7095 7.98154 13.667 7.93896L13.3824 7.6544M14.8052 15.4798L15.3032 14.9818C15.7281 14.5569 15.9405 14.3445 16.1035 14.1494C17.869 12.0367 17.869 8.96327 16.1035 6.85062C15.9405 6.65551 15.728 6.44306 15.3032 6.01818L14.8052 5.5202M6.2079 13.4599L6.91645 14.0439C8.63958 15.464 9.50114 16.1741 10.2235 15.9636C10.3669 15.9218 10.5028 15.8577 10.6263 15.7736C11.2482 15.3501 11.2482 14.2336 11.2482 12.0006V8.99936C11.2482 6.76642 11.2482 5.64995 10.6263 5.22641C10.5028 5.14234 10.3669 5.07823 10.2235 5.03644C9.50114 4.82588 8.63958 5.53596 6.91645 6.95612L6.2079 7.54009C6.06995 7.65379 6.00097 7.71064 5.93122 7.76261C5.36472 8.18476 4.68534 8.42864 3.9797 8.46318C3.89282 8.46743 3.80343 8.46743 3.62466 8.46743C3.43718 8.46743 3.34344 8.46743 3.26458 8.47625C2.60407 8.55016 2.08273 9.0715 2.00882 9.73201C2 9.81087 2 9.90462 2 10.0921V10.9079C2 11.0954 2 11.1891 2.00882 11.268C2.08273 11.9285 2.60407 12.4498 3.26458 12.5237C3.34344 12.5326 3.43718 12.5326 3.62466 12.5326C3.80343 12.5326 3.89282 12.5326 3.9797 12.5368C4.68534 12.5714 5.36472 12.8152 5.93122 13.2374C6.00097 13.2894 6.06995 13.3462 6.2079 13.4599Z" stroke={themeType == 'dark-theme' ? "#D7D7D7": '#828282'} strokeLinecap="round"/>
                        </svg>,
                        volumeMute:<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 512 512" fill={themeType == 'dark-theme' ? "#D7D7D7": '#828282'}>
                        <path d="M215 71.1L126.1 160H24c-13.3 0-24 10.7-24 24v144c0 13.3 10.7 24 24 24h102.1l89 89c15 15 41 4.5 41-17V88c0-21.5-26-32-41-17zM461.6 256l45.6-45.6c6.3-6.3 6.3-16.5 0-22.8l-22.8-22.8c-6.3-6.3-16.5-6.3-22.8 0L416 210.4l-45.6-45.6c-6.3-6.3-16.5-6.3-22.8 0l-22.8 22.8c-6.3 6.3-6.3 16.5 0 22.8L370.4 256l-45.6 45.6c-6.3 6.3-6.3 16.5 0 22.8l22.8 22.8c6.3 6.3 16.5 6.3 22.8 0L416 301.6l45.6 45.6c6.3 6.3 16.5 6.3 22.8 0l22.8-22.8c6.3-6.3 6.3-16.5 0-22.8L461.6 256z"/>
                        </svg>,
                        pause:<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="cp">
                        <path d="M12.1429 15C11.75 15 11.4138 14.8602 11.1343 14.5807C10.8543 14.3007 10.7143 13.9643 10.7143 13.5714V6.42857C10.7143 6.03571 10.8543 5.69952 11.1343 5.42C11.4138 5.14 11.75 5 12.1429 5H13.5714C13.9643 5 14.3007 5.14 14.5807 5.42C14.8602 5.69952 15 6.03571 15 6.42857V13.5714C15 13.9643 14.8602 14.3007 14.5807 14.5807C14.3007 14.8602 13.9643 15 13.5714 15H12.1429ZM6.42857 15C6.03571 15 5.69952 14.8602 5.42 14.5807C5.14 14.3007 5 13.9643 5 13.5714V6.42857C5 6.03571 5.14 5.69952 5.42 5.42C5.69952 5.14 6.03571 5 6.42857 5H7.85714C8.25 5 8.58643 5.14 8.86643 5.42C9.14595 5.69952 9.28571 6.03571 9.28571 6.42857V13.5714C9.28571 13.9643 9.14595 14.3007 8.86643 14.5807C8.58643 14.8602 8.25 15 7.85714 15H6.42857Z" fill={themeType == 'dark-theme' ? "#FFF": '#828282'}/>
                        </svg>
                    }}
                    theme="dark"
                    // customAdditionalControls={
                    //     [
                    //         RHAP_UI.LOOP,
                    //       <button> 2 </button>,
                    //       <button> 3 </button>,
                    //       <button> 4 </button>,
                    //     ]
                    //   }
                    customControlsSection={
                        [
                            <div className="song-name">
                                <h3 dangerouslySetInnerHTML={{__html: currentPlayingSong.name}} />
                                <span className="text-5" dangerouslySetInnerHTML={{__html: currentPlayingSong.writer}} />
                            </div>,
                                RHAP_UI.ADDITIONAL_CONTROLS,
                                RHAP_UI.MAIN_CONTROLS,
                            
                                RHAP_UI.CURRENT_TIME,
                                <div>/</div>,
                                RHAP_UI.DURATION,
                                <div className="wave-bar cp">
                                    { displayWaveLoader ? 
                                        <div className="wave-loading">
                                            <svg xmlns="http://www.w3.org/2000/svg" 
                                                width="80px" height="60px"
                                                viewBox="5 0 80 60">
                                                <path id="wave" 
                                                    fill="none" 
                                                    stroke={themeType == 'dark-theme' ? "#FFF": '#828282'} 
                                                    strokeWidth="4"
                                                    strokeLinecap="round">
                                                </path>
                                            </svg>
                                        </div>
                                        : ''
                                    }
                                    <div id="waveform" style={{width: "100%"}} onClick={(e)=>changeSongTime(e)} className="cp"></div>
                                </div>,
                                <div className="play-bar-right-icon ">
                                <SongPlaylist  />
                                <FavButton />
                                <PlayerDownloadButton />
                                </div>,
                                RHAP_UI.VOLUME_CONTROLS,
                        ]
                    }
                    customProgressBarSection={
                        [
                        //   RHAP_UI.PROGRESS_BAR,
                            
                        ]
                    }
                    
                    >
                    
                </AudioPlayer>
            : ''
                    }
            </div>
        </>
    );
    } else {
        return null;
    }
};


function mapStateToProps(state) {
  
    return {
        playList: state.playlist.playList,
        playerState: state.playlist.playerState,
        playIndex: state.playindex.playIndex,
        isPlaying: state.isplaying.isPlaying,
        themeType: state.theme.theme,
    }
      
}
 
 function mapDispatchToProps(dispatch) {
  return {
    setPlayIndex: ( playIndex ) => dispatch(playindexActions.setPlayIndex(playIndex)),
    setIsPlaying: ( isPlaying ) => dispatch(isplayingActions.setIsPlaying(isPlaying) ),
    setPlayer: (player) => dispatch(playerActions.setPlayerInstanceGlobal(player))
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(MusicPlayer);