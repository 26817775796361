import React, { useState, useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';

import {  useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { checkCustomer } from '../../components/utlity/checkCustomer';
import {connect} from 'react-redux';
import * as actions from '../../store/dropbox/actions';

const DropboxButton = ({ songId, version, themeType, addRemoveToDropBox, dropbox, userProfile }) => {

    const MySwal = withReactContent(Swal)
    const navigate = useNavigate();
    const [ downloadInProcess, setDownloadInProcess ] = useState(false);
    const [ canAccess, setCanAccess ] = useState(false);

    useEffect(() => { 
        if(checkCustomer(userProfile)){
            setCanAccess(true);
        }
    },[userProfile])

    const addRemoveSongToDropbox = async() => {
        setDownloadInProcess(true);
        if(canAccess){
            await addRemoveToDropBox({songId: songId, versionId: version.song_version_id});
            setDownloadInProcess(false);
        } else {
            setDownloadInProcess(false);
            navigate('/subscription/plans')
        }
    }
    
        if(downloadInProcess){
            return <Spinner animation="border" variant="danger" size="sm" />
        } else {
            return (
                <span onClick={() => addRemoveSongToDropbox()} className="cp" title={ dropbox && dropbox.verions && dropbox.verions.length > 0 && dropbox.verions.indexOf(version.song_version_id.toString()) >= 0 ? "Remove from Dropbox Queue" : "Add to Dropbox Queue"}>
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 6.16667L5.66667 8.825L9.83333 6.16667L5.66667 3.5L1.5 6.16667ZM9.83333 6.16667L14 8.825L18.1667 6.16667L14 3.5L9.83333 6.16667ZM1.5 11.4583L5.66667 14.1167L9.83333 11.4583L5.66667 8.79167L1.5 11.4583ZM14 8.79167L9.83333 11.4583L14 14.1167L18.1667 11.4583L14 8.79167ZM5.69167 15L9.86667 17.6667L14.0333 15L9.86667 12.3417L5.69167 15Z" 
                        fill={ dropbox && dropbox.verions && dropbox.verions.length > 0 && dropbox.verions.indexOf(version.song_version_id.toString()) >= 0 ? '#EB4E27' : themeType == 'dark-theme' ? "#D7D7D7": '#282A2D' } />
                    </svg>
                </span>
            )
        }
    
   

}
function mapStateToProps(state) {
    return {
        dropbox: state.dropbox.dropboxSongs.dropbox,
        userProfile: state.customer.customerProfile,
        themeType: state.theme.theme,
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
        addRemoveToDropBox: (songId, versionId) => dispatch(actions.addRemoveToDropBox(songId, versionId)),
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(DropboxButton);
  