import {axiosReqGet, axiosReqOther} from "./config";
import { store } from '../store/store';

const USER_TOKEN = store.getState().authentication.user;

export const Uploads = {
    selectSong,
    getUploadedSongs,
    enableDisableDeleteSong
}

function selectSong(data){
    
    const url = 'subscription/get-customer-billing.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}
function getUploadedSongs(data){
    
    const url = 'song/get-uploaded-songs.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    // data.token = 'XM-p3-6TDxx1BiB9_17Y_BOHCwxjLh3x';
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

function enableDisableDeleteSong(data){
    
    const url = 'song/enable-disable-delete-songs.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    // data.token = 'XM-p3-6TDxx1BiB9_17Y_BOHCwxjLh3x';
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

export default selectSong;