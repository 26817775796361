import React, {useState, useEffect} from "react";

import singlePageLayout from "../../layouts/singlePageLayout/singlePageLayout";

import {connect} from 'react-redux';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { Link } from "react-router-dom";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import Pagination from "react-js-pagination";
// import logo from './../../assets/images/Logo.svg';
import logo from './../../assets/images/cklogo.png';
import mixcloud from './../../assets/images/mixcloud.png';
import * as actions from '../../store/mixes/actions';

import './mixes.scss';

const Mixes = ({ getMixes, mixes,  displayLoading, hideLoading }) => {

	const [ page, setPage ] = useState(1);
    const [ order, setOrder ] = useState('entity_id');
    const [ orderDir, setOrderDir ] = useState('DESC');
    const [ songSectionId, setSongSectionId ] = useState(1);
    const [ currentGenreId, setCurrentGenreId ] = useState();
    const [ processing, setProcessing ] = useState(false);
    console.log('mixes', mixes)
	useEffect(() => {
        displayLoading();
        setProcessing(true);
        getMixes({page: page, order: order, orderDir: orderDir});
		document.body.classList.add('hideplayer');
        hideLoading();
            setProcessing(false);
	},[]);

	
    const handlePageChange = (pageNumber) => {
        displayLoading();
        setProcessing(true);
        setPage(pageNumber);
        
        getMixes({page: pageNumber, order: order, orderDir: orderDir}).then((res) => {
            hideLoading();
            setProcessing(false);
            window.scrollTo({top: 0});
        })
       
    }
	
  return (
    <singlePageLayout>
    {/* // <MusicDashboard middleSectionCssclassName={'account-info-sec'}> */}
		<SimpleBar style={{ height: '100vh' }}>
            <div className="outer-singlelayout-pages">
                <div className="container-sec">
                    <div className="top">
                        <Link to={`/`} className="logo"><img src={logo} alt="Clubkillers sign in" title="Clubkiller sing in" style={{maxWidth: '100px' }} /></Link>
                        <Link to={`/`} className="close">
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L9 9M9 1L1 9" stroke="white" strokeLinecap="round"/>
                            </svg>
                        </Link>
                    </div>
                    <div className="osp-content-area">
                        <h1>Mixes</h1>
                        <div className="mix-cloud-banner"><img src={mixcloud} title="Clubkillers Mixcloud" alt="Clubkillers Mixcloud" /></div>
                        {
                            mixes && mixes.mixes ? 
                                mixes.mixes.map((item, index ) => {
                                    return <div key={`mixes-${index}`} className="m-row">
                                        {
                                            item.description != '' ?
                                                <div className="m-i-container" dangerouslySetInnerHTML={{__html: item.description}} />
                                            : 
                                                <div className="m-m-container">
                                                    <div className="m-img"></div>
                                                    <div className="m-dj"></div>
                                                    <div className="m-title"></div>
                                                    <div className="m-dlink"></div>
                                                    <div className="m-dcount"></div>
                                                    <div className="m-date"></div>
                                                </div>
                                        }
                                    </div>
                                })
                            : ''
                        }
                        <div className="pagingnation-sec">
                            {
                            mixes && mixes.pagination ? 
                                <Pagination
                                    activePage={mixes.pagination.page}
                                    itemsCountPerPage={mixes.pagination.perPageLimit}
                                    totalItemsCount={mixes.pagination.totalCount}
                                    // pageRangeDisplayed={pageRangeDisplayed}
                                    onChange={handlePageChange}
                                    itemclassName="page-item"
                                    linkclassName="page-link"
                            /> : ''
                            }
                        </div>
                    </div>
                    
                </div>
            </div>
		</SimpleBar>
    {/* </MusicDashboard> */}
    </singlePageLayout>
  );
}

function mapStateToProps(state) {
    
  return {
		loggingIn: state.authentication.loggedIn,
        mixes: state.mixes.mixes
  };
}

function mapDispatchToProps(dispatch) {
  return {
	getMixes: (data) => dispatch(actions.getMixes(data)),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Mixes);