import React, {useEffect, useState} from "react";


import Card from 'react-bootstrap/Card';

import { Link, useNavigate } from "react-router-dom";
import {connect} from 'react-redux';

import * as helpdeskAction from '../../store/helpdesk/actions'

const RecentTickets = ({  getRecentTickets, recentTickets}) => {
    const navigate = useNavigate();
    useEffect(() => { 
            // load Page Data
            loadPageData();
    },[]);

    const loadPageData = async() => {
            await getRecentTickets({page: 1, pageLimit: 5})
    }
  

  return (
    <div className="right">
        <div className="title-heading">
            <h3>Tickets</h3>
            <span onClick={() => navigate('/helpdesk/tickets/open')} className="cp primary-color">View all tickets</span>
        </div>
        <div className="body-content">
            {
                recentTickets && recentTickets.tickets && recentTickets.tickets.length > 0 ? 
                    recentTickets.tickets.map((ticket, index) => {
                        return <div className="recentTicket" key={`recentTicket${index}`}>
                            <div className="t-sub">{ticket.subject}</div>
                            <div className="d-flex justify-content-between">
                                <div className="text-muted mr-2 ticket-number"><Link to={`/helpdesk/ticket/details/${ticket.ticket_number}`} className="text-3">#{ticket.ticket_number}</Link></div>
                                <div className="mr-2">{ticket.modified_at}</div>
                                <div className="action">
                                    <Link to={`/helpdesk/ticket/details/${ticket.ticket_number}`} className={`status-${ticket.status}`}>{ticket.status}</Link>
                                </div>
                            </div>
                        </div>
                    })
                : 'You have no tickets'
            }
        </div>
    </div>
  );
}
function mapStateToProps(state) {
    return {
        recentTickets: state.helpdesk.recentTickets
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getRecentTickets: (post) => dispatch(helpdeskAction.getRecentTickets(post)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentTickets);