
import React, {useEffect, useState} from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import AccountNav from '../../elements/accountElements/accountNav'; 
import config from '../../config/config';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import {isDesktop, isMobile, isTablet, browserName, browserVersion, osName, osVersion, isMobileOnly} from 'react-device-detect';

import * as subscriptionAction from '../../store/subscription/actions'
import * as billingAction from '../../store/billing/actions';
import * as customerAction from '../../store/customer/actions';
import CalanderSgv from './../../assets/images/calendar-group.svg';
import PlanLoader from '../../components/loader/planLoader'; 

const SubscriptionIndex = ({ displayLoading, hideLoading, getCustomerAgreement, agreement, getServices, plans, getUserProfile, cancelSubscription, cancelCustomerFeedback, customerProfile, reactivateSubscription}) => {

    const MySwal = withReactContent(Swal);
    const [ processing, setProcessing ] = useState(false);
    const [ isStaff, setIsStaff ] = useState(false);
    const [ userDeviceInfo, setUserDeviceInfo ] =  useState({
        device_type: isDesktop ? 'desktop' : isMobile ? 'mobile' : 'tablet',
        browser_name: browserName,
        browser_version: browserVersion,
        os_name: osName,
        os_version: osVersion,
        ip_address: '',
        token: localStorage.user,
        device_id: localStorage.getItem('deviceId')
    });
    
    useEffect(() => { 
		// load Page Data
		loadPageData();
    },[]);
    
    useEffect(() => {
        if(customerProfile && customerProfile.group_id == 3){
            
            var d1 = new Date();
            var d2 = new Date(customerProfile.staff_membership_expire_date);
            if(d2.getTime() > d1.getTime()){
                setIsStaff(true);
            }
        }
    },[customerProfile]);

    const loadPageData = async() => {
        setProcessing(true);
        displayLoading();
        await getCustomerAgreement();
        await getServices({subscription_type_id: 1});
        hideLoading();
        setProcessing(false);
        window.scrollTo({top: 0});
    }

    const cancelCurrentSubscription = async() => {
        
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ffa800',
			cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, cancel it!',
            cancelButtonText: 'No'
		  }).then((result) => {
			if (result.isConfirmed) {
                displayLoading();
				cancelSubscription().then(res => {
					getCustomerAgreement().then(res => {
						hideLoading();
					});
					Swal.fire({
						title: 'Subsciption Cancelled',
						text: "Your subscription has been cancelled.no payment will be charge.",
						input: 'text',
						inputAttributes: {
						  autocapitalize: 'off',
						  placeholder: 'Sad to see you go.We would love some constructive criticism on our site'
						},
						showCancelButton: true,
						confirmButtonText: 'Submit',
						showLoaderOnConfirm: true,
						preConfirm: (message) => {
							if(message != ''){
								sendCustomerCancelFeedback(message)
							}
						},
						allowOutsideClick: () => !Swal.isLoading()
					  }).then((result) => {
						
					  })
				})
			
			} 
		  })
    }

    const unPausedSubscription = async() => {
        await reactivateSubscription().then(res => {
            Swal.fire({
				title: "Unpaused Subscription!", 
				text: "Your subscription reactivated",
				type: "success", 
				closeOnConfirm: true
			}, function(){
                
            });
        })
        await getCustomerAgreement();
        await getUserProfile(userDeviceInfo); 
    }

    const sendCustomerCancelFeedback = (message) => {
        
        cancelCustomerFeedback({message: message}).then(res  => {
			Swal.fire({
				title: "Thank you for your valuable feedback!", 
				text: "Hope to see you soon",
				type: "success", 
				closeOnConfirm: true
			}, function(){
                
            });
        });
    }
    // console.log('agreement', agreement)
    return (
        <MusicDashboard middleSectionCssClass={"account-info-sec full-section"}>
            <div className="content-sec">
                <div className="account-block">
                    <div className="top-heading">
                        <h2>Account Settings</h2>
                    </div>
                    <AccountNav/> 
                    <div className="account-inner-block">
                        {
                            isStaff ? <div className="alert alert-danger">You have landed on wrong page. Please contact to <a href="mailto: info@clubkillers.com" className="primary-color"><b>Support</b></a> for more details</div>
                        :
                            <div className="subscription-sec">
                                {
                                    agreement ?
                                    <div className="top">
                                        {
                                            agreement.payment_method &&  agreement.payment_method == 'Credit Card' && agreement.profile_status == 'Active' ?
                                                <h5><Link className="text-3 cp" to={`/subscription/update-card`}>Click Here</Link> to update your card information.</h5>
                                            : ''

                                        }
                                        {
                                            agreement.profile_status == 'Active' ?
                                                <h5>Hi, {customerProfile.dj_name}! Your current plan is “monthly”.&nbsp;
                                                {/* <span className="text-3 cp" onClick={() => cancelCurrentSubscription()}>Cancel Current Plan</span> */}
                                                <Link className="text-3 cp" to={`/subscription/cancel`}>Cancel Current Plan</Link>
                                                </h5>
                                            :
                                            agreement.profile_status == 'CREATED' ?
                                                <h5>Hi, {customerProfile.dj_name}! Your subscription created successfully. It takes maximum 24 hours time to process payment.</h5>
                                            :
                                            agreement.profile_status == 'Suspend' ?
                                                <h5>Hi, {customerProfile.dj_name}! Your subscription is paused. <span className="text-3 cp" onClick={() => unPausedSubscription()}>Re-activate Current Plan</span></h5>
                                            :
                                            agreement.profile_status == 'Cancelled' ? 
                                                <h5>Hi, {customerProfile.dj_name}! Your subscription has been ended. Subscription again.</h5>
                                            : ''
                                        }
                                    </div>
                                    : 
                                    <div className="top">
                                        <h5>Hi, {customerProfile.dj_name}! You haven’t subscribed yet</h5>
                                        <h1>Choose your plan</h1>
                                        <p className="text-3">Create Your Account To Experience The Club Killers Site</p>
                                    </div>
                                }
                                <div className="subscription-block">
                                {
                                    processing ? <PlanLoader itemCount="1" /> :
                                        plans && plans.length > 0 ?
                                            plans.map((plan, index) =>{
                                                return <div className="subscription-box" key={`subscriptionplan${plan.id}`}>
                                                            <div className="inner-block">
                                                                <span className="icon"><img src={CalanderSgv} title=""/></span>
                                                                <h2>{plan.title}</h2>
                                                                <h1>${plan.price}<span>/month</span></h1>
                                                                {
                                                                    plan.trial_price ?
                                                                        <div>
                                                                            <span>${plan.trial_price}</span> First {plan.trial_interval} {plan.trial_period} thereafter <br /> <span>${plan.price}</span> every {plan.payment_interval} {plan.payment_period}
                                                                        </div>
                                                                    :
                                                                        <div className="plan-text">
                                                                            <span>${plan.price}</span> every {plan.payment_interval} {plan.payment_period}s
                                                                        </div>
                                                                }
                                                                <div dangerouslySetInnerHTML={{__html: plan.features}} className="feature-list"></div>
                                                            </div>
                                                            { 
                                                                plan.is_upgrade_plan == 'yes' ? 
                                                                agreement && agreement.plan_id == plan.id && agreement.profile_status == 'Active' ? <span className="secondary-btn disabled-btn">Active</span> : 
                                                                    <Link to={`/subscription/payment-method/${plan.id}`} className="primary-btn">
                                                                        {
                                                                            agreement ?
                                                                                    agreement.profile_status == 'Active' ?
                                                                                        <span>Subscribe to Monthly</span>
                                                                                    :
                                                                                    agreement.profile_status == 'CREATED' ?
                                                                                        <h5>Payment under process</h5>
                                                                                    :
                                                                                    agreement.profile_status == 'Cancelled' ? 
                                                                                        <span>Subscribe to Monthly</span>
                                                                                    : <span>Subscribe to Monthly</span>
                                                                            : 
                                                                            <span>Subscribe to Monthly</span>
                                                                        }
                                                                    </Link> 
                                                                : 
                                                                agreement && agreement.plan_id == plan.id  && agreement.profile_status == 'Active' ? <span className="secondary-btn disabled-btn">Active</span> : 
                                                                    <Link to={`/subscription/payment-method/${plan.id}`} className="primary-btn">
                                                                        {
                                                                            agreement ?
                                                                                    agreement.profile_status == 'Active' ?
                                                                                        <span>Subscribe to Monthly</span>
                                                                                    :
                                                                                    agreement.profile_status == 'CREATED' ?
                                                                                        <h5>Payment under process</h5>
                                                                                    :
                                                                                    agreement.profile_status == 'Cancelled' ? 
                                                                                        <span>Subscribe to Monthly</span>
                                                                                    : <span>Subscribe to Monthly</span>
                                                                            : 
                                                                            <span>Subscribe to Monthly</span>
                                                                        }
                                                                    </Link>}
                                                        </div>
                                            })
                                        : ''
                                    }
                                    
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </MusicDashboard>
    );
}
function mapStateToProps(state) {
  return {
	agreement: state.subscription.agreement,
    plans: state.subscription.plans,
    customerProfile: state.customer.customerProfile
  };
}

function mapDispatchToProps(dispatch) {
  	return {
      	getCustomerAgreement: () => dispatch(subscriptionAction.getCustomerAgreement()),
        getServices: (post) => dispatch(subscriptionAction.getServices(post)),
        getUserProfile: (token) => dispatch(customerAction.getCustomerProfile(token)),
		downloadBillingEntry: (post) => dispatch(billingAction.downloadBillingEntry(post)),
		cancelSubscription: () => dispatch(subscriptionAction.cancelSubscription()),
        cancelCustomerFeedback: (post) => dispatch(subscriptionAction.cancelCustomerFeedback(post)),
        reactivateSubscription: () => dispatch(subscriptionAction.reactivateSubscription()),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
  	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionIndex);