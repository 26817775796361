import React, { useState, useEffect } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";
import MonthlyTopSongs from '../../components/monthlyTopSongs/monthlyTopSongs';
// import CuratedSpotlightCarousel from '../../components/curatedSpotlightCarousel/curatedSpotlightCarousel';
import RightSide from "../../components/rightSide/rightSide";
import Pagination from "react-js-pagination";

import MusicDashboard from '../../layouts/musicDashboard';
import ImageLoader from '../../components/loader/imageLoader';

import { Link } from "react-router-dom";
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { checkCustomer } from '../../components/utlity/checkCustomer';
import { connect } from 'react-redux';
import * as actions from '../../store/curated/actions';



const  CuratedSpotlightInedx = ({ loggingIn, getCuratedAlbums, curatedAlbums, displayLoading, hideLoading, userProfile }) => {

    const navigate = useNavigate();
    const [ page, setPage ] = useState(1);
    const [ processing, setProcessing] = useState(false);

    useEffect(() => { 
		if(loggingIn !== undefined && loggingIn === false){
			navigate('/sign-in');
		} else {
			loadData()
		}
    },[])

    useEffect(() => { 
        if(userProfile && !checkCustomer(userProfile)){
            navigate('/subscription/plans');
        }
	},[userProfile]);
    
    const loadData = async() => {
        setProcessing(true);
        displayLoading();
        await getCuratedAlbums({page: page, pageLimit: 24});
        hideLoading();
        setProcessing(false);
        window.scrollTo({top: 0});
    }
    
    const handlePageChange = async (pageNumber) => {
        setProcessing(true);
        displayLoading();
		setPage(pageNumber);
        await getCuratedAlbums({page: pageNumber, pageLimit: 24});
        hideLoading();
        setProcessing(false);
	}

    
	return (
			<MusicDashboard rightContent={<RightSide blocks={{curatedFilter: true }} />}>
			    <div className="content-sec">
                    <div className="most-download">
                        <MonthlyTopSongs  />
                    </div>
                    <div className="latest-spotlights">
                        <div className="top-heading">
                            <h2>Latest Spotlight</h2>
                        </div>
                        <div className="latest-spot-block">
                        {
                            processing ? <ImageLoader itemCount={35} /> :
                                curatedAlbums && curatedAlbums.curatedlist && curatedAlbums.curatedlist.length > 0 ?
                                    curatedAlbums.curatedlist.map((curatedSpotlight, index) => {
                                        if(curatedSpotlight.total > 0){
                                            return <Link className="box" key={`newreleasespotlight${curatedSpotlight}`} to={`/mp3/spotlight/detail/${curatedSpotlight.curated_list_id}`}>
                                                        <img src={curatedSpotlight.image ? curatedSpotlight.image : ''} />
                                                        <div className="latest-info"> <h5 dangerouslySetInnerHTML={{__html: curatedSpotlight.title}}></h5></div>            
                                                </Link>
                                        } else {
                                            return null;
                                        }
                                    })
                                : ''
                        }
                        
                        </div>
                    </div>
                    <div className="pagingnation-sec">
                        {
                            
                                curatedAlbums && curatedAlbums.pagination ?
                                    <Pagination
                                        activePage={curatedAlbums.pagination.page}
                                        itemsCountPerPage={curatedAlbums.pagination.perPageLimit}
                                        totalItemsCount={curatedAlbums.pagination.totalCount}
                                        // pageRangeDisplayed={pageRangeDisplayed}
                                        onChange={handlePageChange}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                /> : ''
                        }
                    </div>
                </div> 
            </MusicDashboard>
        );
}

function mapStateToProps(state) {
	return {
        loggingIn: state.authentication.loggedIn,
        curatedAlbums: state.curated.curatedAlbums,
        userProfile: state.customer.customerProfile,
	};
 }
 
function mapDispatchToProps(dispatch) {
	return {
        getCuratedAlbums: (data) => dispatch(actions.getCuratedAlbums(data)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
	    hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(CuratedSpotlightInedx);
