import React, { useState, useEffect } from "react";
import { Link }  from "react-router-dom"
import {connect} from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import SongTrackLoader from '../loader/songTrackLoader';
import SongTrack from '../../components/songTrack/songTrack';
import * as songActions from '../../store/song/actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';


const  HomeNewRelease = ({ getLatestSong, homeNewRelease, homeGenreData, homeGenresList, displayLoading, hideLoading }) => {

	const [activeCategoryGenre, setActiveCategoryGenre] = useState('31');

	useEffect(() => { 
		loadData();
   	},[])

	const loadData = async () => {
		displayLoading();
		const data = {
			pageName: 'home'
		};
		
		// Get Latest Songs
		await getLatestSong(data);
		hideLoading();
	}

	/**
	 * @method getNewReleaseSongByGenre
	 * @param {*} value array
	 */
	const getNewReleaseSongByGenre = (genreId) => {
		displayLoading();
		const data = {
			pageName: 'home',
			genre_id: genreId
		};
		
		getLatestSong(data).then((res) => {
			setActiveCategoryGenre(genreId);
		})
		hideLoading();
	}

	/**
	 * @method getNewReleaseSong
	 * @param 
	 */
	const getNewReleaseSong = () => {
		const data = {
			pageName: 'home'
		};
		
		getLatestSong(data).then((res) => {
			setActiveCategoryGenre('all');
		})
	}
 
    
    return (
        <div className="play-list-sec">
			<div className="top-heading">
				<h2><Link to={`/mp3/new-release`} >New Releases</Link></h2>
				<Link to={`/mp3/new-release`} className="text-2">View all</Link>
			</div>
			{ 
				homeGenreData && homeGenresList && homeGenreData != ''  ? 
					<div className="inner-block">
						{/* <div className="top-list">
						{
							homeGenresList && homeGenresList.length > 0 ? 
							<ul>
									<li><a href="#" className="text-3" onClick={() => getNewReleaseSong()}>all</a></li>
								{
									homeGenresList.map((genre, index)=>{
										return <li key={`homeGenresSong${genre.genre_id}`} className={activeCategoryGenre == genre.genre_id ? 'active' : ''}><a href="#" className="text-3" onClick={() => getNewReleaseSongByGenre(genre.genre_id)} dangerouslySetInnerHTML={{__html: genre.name}}></a></li>
									})
								}
								
							</ul>
							: ""
						}
						</div> */}
						<SongTrack songs={homeNewRelease.songs}  sectionName={'homeNewRelease'} />
					</div>
			: <SongTrackLoader itemCount={5} />
			}
		</div>
    );
  
}


function mapStateToProps(state) {
	return {
		homeNewRelease: state.song.homeNewRelease,
		homeGenresList: state.genre.homeGenreList,
		homeGenreData: state.song.homeGenreData
	};
 }
 
 function mapDispatchToProps(dispatch) {
	return {
		getLatestSong: (post) => dispatch(songActions.getNewRelease(post)),
		displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    	hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(HomeNewRelease);
