import React, {useState, useEffect} from "react";

import singlePageLayout from "../../../layouts/singlePageLayout/singlePageLayout";
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form'

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { Link } from "react-router-dom";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { useParams } from "react-router-dom";
// import logo from './../../assets/images/Logo.svg';
import logo from './../../../assets/images/cklogo.png';

import {connect} from 'react-redux';
import * as actions from '../../../store/career/actions';

import '../mixes.scss';

const ApplyJob = ({ getCareerLists, careerLists, displayLoading, hideLoading }) => {
    let match = useParams("/career/apply/:careerId");
    // const fileInstance = createRef();
    const [formData, setFormData] = useState();
    const [formDataEnter, setFormDataEnter] = useState(false);
    const [ processing, setProcessing ] = useState(false);
    const [ responseError, SetResponseError ] = useState();
    const [ responseSuccess, SetResponseSuccess ] = useState();
    const [ errors, setErrors ] = useState({});
    const [validated, setValidated] = useState(false);
    const [files, setFiles] = useState([]);
    const [inputValue, setInputValue] = useState('');
   
	useEffect(() => {
        displayLoading();
        setProcessing(true);
        // getCareerLists({page: page, order: order, orderDir: orderDir});
		document.body.classList.add('hideplayer');
        hideLoading();
            setProcessing(false);
	},[]);

	const handleChange = (e) => { 
        // const { name, value } = e.target;
        // if(name == 'city'){
        //     if (/^[a-zA-Z]*$/.test(value) || value === '') {
        //         setInputValue(value);
        //         setFormData({
        //             ...formData,
        //             [name]: value
        //         });  
        //     }
        // } else {
        //     setFormData({
        //         ...formData,
        //         [name]: value
        //     });  
        // }
          
        
        // if(name === 'country_id'){
        //     const selectedCountry = countryList.find((element) => {
        //         return element.id === value;
        //     });
        //     if(value != ''){
        //         setRegion(selectedCountry.region);
        //     }
        // }
        
        // if(name == 'postcode'){
        //     if ( value.length < 3  || value.length > 7){
        //         setErrors({
        //             ...errors,
        //             postcode: 'Zip Code should be atleast 3 and maximum 7 digit!'
        //         });
        //     } else {
        //         setErrors({
        //             ...errors,
        //             postcode: ''
        //         });
        //     } 
            
            
        // }  else {
        //     findFormErrors();
        // }
    }

    const findFormErrors = () => {
        // const { firstname, lastname, street, country_id, region_id, postcode, email, city, dj_name } = formData
        // let newErrors = {}

        
        // // password errors
        // if ( !firstname || firstname === '' ) newErrors.firstname = 'First Name cannot be blank!'
        // if ( !lastname || lastname === '' ) newErrors.lastname = 'Last Name cannot be blank!'
        // if ( !email || email === '' ) newErrors.email = 'Enter email address'
        // if ( !country_id || country_id === '' ) newErrors.country_id = 'Country cannot be blank!'
        // if ( !region_id || region_id === '' ) newErrors.region_id = 'State cannot be blank!'
        // if ( !city || city === '' ) newErrors.city = 'City cannot be blank!'
        // if ( !postcode || postcode === '' ) newErrors.postcode = 'Zip Code cannot be blank!'
        // if ( postcode && postcode.length < 3  || postcode.length > 7){
        //     newErrors.postcode = 'Zip Code should be atleast 3 and maximum 7 digit!'
        // } 
        // if ( !street || street === '' ) newErrors.street= 'Address cannot be blank!'
        // if ( !dj_name || dj_name === '' ) newErrors.dj_name= 'DJ Name cannot be blank!'
        // if(email && !validateEmail(email)){
        //     newErrors.email = 'Your e-mail has been entered in the wrong format. Please check and try again';
        //   }
        // // console.log('newErrors', newErrors)
        // setErrors(newErrors);
        // return newErrors
    }

    const validateEmail = (email) => {
        // return email.match(
        //     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //   );
    }

    const handleSubmit = (event) => { 
        

        // const checkErrors = findFormErrors();
        // // console.log('check 1',checkErrors);
        // const form = event.currentTarget;
        // event.preventDefault();
        // event.stopPropagation();
        // // console.log('check 2', Object.keys(checkErrors).length)
        // if ( Object.keys(checkErrors).length > 0 ) {
        //     // We got errors!
        //     setErrors(checkErrors);
        //     // console.log('check 3');
        // } else {
        //     displayLoading(); 
        //     setProcessing(true);
        //     SetResponseError('');
        //     SetResponseSuccess('');
        //     updateProfile(formData).then(res => {
        //         if(res && res.payload && res.payload.message && res.payload.message.errors){
        //             SetResponseError(res.payload.message.errors[0]);
        //             SetResponseSuccess('');
        //             hideLoading();
        //             setProcessing(false);
        //         } else {
        //             SetResponseError('');
        //             SetResponseSuccess('Profile successfully updated');
        //             hideLoading();
        //             setProcessing(false);
        //             setTimeout(resetMessaage, 4000);
        //         }
        //     });
            
        // }
    }
    
	
  return (
    <singlePageLayout>
    {/* // <MusicDashboard middleSectionCssclassName={'account-info-sec'}> */}
		<SimpleBar style={{ height: '100vh' }}>
            <div className="outer-singlelayout-pages">
                <div className="container-sec">
                    <div className="top">
                        <Link to={`/`} className="logo"><img src={logo} alt="Clubkillers sign in" title="Clubkiller sing in" style={{maxWidth: '100px' }} /></Link>
                        <Link to={`/`} className="close">
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L9 9M9 1L1 9" stroke="white" strokeLinecap="round"/>
                            </svg>
                        </Link>
                    </div>
                    <div className="osp-content-area">
                        <h1>Current Opening</h1>
                        <br />
                        <Form  validated={validated} onSubmit={handleSubmit}>
	                        <div class="form-inline page_center">
                                {/* <div class="form-element">
                                        <div class="urban-app-label">Resume/CV </div>
                                        <div class="urban-app-field urban-app-field2">
                                            <div class="file-btn">
                                                <svg xml:space="preserve" enable-background="new 0 0 16 16" viewBox="0 0 16 16" height="16" width="16px" y="0px" x="0px" class="icon icon-paperclip"><path d="M3.036,14.696c-0.614,0-1.219-0.284-1.788-0.853l-0.083-0.082c-0.586-0.578-2.305-2.956,0.008-5.391 c1.165-1.226,2.771-2.813,4.471-4.493C6.558,2.976,7.509,2.036,8.455,1.09c1.708-1.707,2.958-1.317,4.894,0.528 c2.288,2.178,2.707,4.322,1.718,5.463c-1.314,1.515-6.285,6.488-6.496,6.699c-0.278,0.279-0.729,0.279-1.008,0 c-0.278-0.278-0.278-0.729,0-1.008c0.051-0.051,5.146-5.148,6.427-6.625c0.294-0.339,0.339-1.629-1.624-3.498 c-1.13-1.076-1.465-1.989-2.902-0.551c-0.948,0.948-1.901,1.89-2.817,2.793C4.954,6.564,3.355,8.144,2.207,9.353 c-1.349,1.421-0.656,2.788-0.041,3.395l0.089,0.088c0.524,0.523,0.952,0.665,1.718-0.102c0.213-0.213,0.656-0.644,1.213-1.185 C6.729,10.05,9.6,7.26,10.18,6.534c0.184-0.23,0.452-0.787,0.196-1.011c-0.353-0.31-1.002,0.315-1.192,0.514 c-2.012,2.112-4.64,4.643-4.666,4.667c-0.283,0.273-0.734,0.265-1.007-0.02c-0.273-0.284-0.265-0.734,0.019-1.007 c0.026-0.025,2.633-2.535,4.622-4.624c1.291-1.356,2.48-1.201,3.162-0.604c0.832,0.727,0.636,2.154-0.021,2.974 c-0.586,0.734-2.847,2.945-5.113,5.146c-0.55,0.536-0.988,0.96-1.199,1.171C4.346,14.378,3.686,14.696,3.036,14.696L3.036,14.696z" fill="#3F484B"></path></svg>
                                                <span class="filename"></span>
                                                <span class="default-label">ATTACH RESUME/CV</span>
                                                {/* <input id="resume-upload-input" name="resume" class="application-file-input form-control" type="file"> 
                                            </div>
                                    </div>
                                </div> */}
                                <Form.Group className="form-element" controlid="formBasicEmail">
                                    <div className="urban-app-label">
                                        <Form.Label>Full Name<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="urban-app-field">
                                        <Form.Control type="text" placeholder="Full Name" name="full_name"  onChange={handleChange} isInvalid={ !!errors.full_name } onBlur={handleChange} />
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        { errors.full_name }
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="form-element" controlid="formBasicEmail">
                                    <div className="urban-app-label">
                                        <Form.Label>Email Address<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="urban-app-field">
                                        <Form.Control type="text" placeholder="email" name="email"  onChange={handleChange} isInvalid={ !!errors.email } onBlur={handleChange} />
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        { errors.email }
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="form-element" controlid="formBasicEmail">
                                    <div className="urban-app-label">
                                        <Form.Label>Phone<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="urban-app-field">
                                        <Form.Control type="text" placeholder="Phone" name="phone"  onChange={handleChange} isInvalid={ !!errors.phone } onBlur={handleChange} />
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        { errors.phone }
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="form-element" controlid="formBasicEmail">
                                    <div className="urban-app-label">
                                        <Form.Label>Current Company<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="urban-app-field">
                                        <Form.Control type="text" placeholder="Current Company" name="current_company"  onChange={handleChange} isInvalid={ !!errors.current_company } onBlur={handleChange} />
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        { errors.current_company }
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div class="spacer"></div>
                                    <div class="Title mt60">
                                        <h2>Links</h2>
                                    </div>
                                    <div class="spacer"></div>
                                <Form.Group className="form-element" controlid="formBasicEmail">
                                    <div className="urban-app-label">
                                        <Form.Label>LinkedIn URL<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="urban-app-field">
                                        <Form.Control type="text" placeholder="LinkedIn URL" name="linkedin_url"  onChange={handleChange} isInvalid={ !!errors.linkedin_url } onBlur={handleChange} />
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        { errors.linkedin_url }
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="form-element" controlid="formBasicEmail">
                                    <div className="urban-app-label">
                                        <Form.Label>Portfolio URL<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="urban-app-field">
                                        <Form.Control type="text" placeholder="LinkedIn URL" name="portfolio_url"  onChange={handleChange} isInvalid={ !!errors.portfolio_url } onBlur={handleChange} />
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        { errors.portfolio_url }
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="form-element" controlid="formBasicEmail">
                                    <div className="urban-app-label">
                                        <Form.Label>Facebook URL<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="urban-app-field">
                                        <Form.Control type="text" placeholder="Facebook URL" name="facebook_url"  onChange={handleChange} isInvalid={ !!errors.facebook_url } onBlur={handleChange} />
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        { errors.facebook_url }
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="form-element" controlid="formBasicEmail">
                                    <div className="urban-app-label">
                                        <Form.Label>Instagram URL<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="urban-app-field">
                                        <Form.Control type="text" placeholder="Instagram URL" name="instagram_url"  onChange={handleChange} isInvalid={ !!errors.instagram_url } onBlur={handleChange} />
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        { errors.instagram_url }
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="form-element" controlid="formBasicEmail">
                                    <div className="urban-app-label">
                                        <Form.Label>Twitter URL<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="urban-app-field">
                                        <Form.Control type="text" placeholder="Instagram URL" name="twitter_url"  onChange={handleChange} isInvalid={ !!errors.twitter_url } onBlur={handleChange} />
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        { errors.twitter_url }
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="form-element" controlid="formBasicEmail">
                                    <div className="urban-app-label">
                                        <Form.Label>Other Website<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="urban-app-field">
                                        <Form.Control type="text" placeholder="Instagram URL" name="other_website"  onChange={handleChange} isInvalid={ !!errors.other_website } onBlur={handleChange} />
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        { errors.other_website }
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div class="spacer"></div>
                                <div class="form-block">
                                    <Form.Group className="form-element" controlid="formBasicEmail">
                                        <div className="urban-app-label">
                                            <Form.Label>How did you hear about us?<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="urban-app-fieldfull-width">
                                                <div class="application-dropdown">
                                                    <Form.Control as="select" type="select" placeholder="Instagram URL" name="hear_about_job"  onChange={handleChange} isInvalid={ !!errors.hear_about_job } onBlur={handleChange}>
                                                        <option value="">Select country</option> 
                                                        <option value="LinkedIn">LinkedIn</option>
                                                        <option value="Twitter">Twitter</option>
                                                        <option value="Instagram">Instagram</option>
                                                        <option value="Facebook">Facebook</option>
                                                        <option value="jobsDB">jobsDB</option>
                                                        <option value="Others">Others</option>
                                                    </Form.Control>
                                                </div>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            { errors.hear_about_job }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="form-element" controlid="formBasicEmail">
                                        <div className="urban-app-label">
                                            <Form.Label>How did you find out about this job?<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="urban-app-fieldfull-width">
                                                <div class="application-dropdown">
                                                    <Form.Control as="select" type="select" placeholder="Instagram URL" name="find_role"  onChange={handleChange} isInvalid={ !!errors.find_role } onBlur={handleChange}>
                                                        <option value="">Select</option>
                                                        <option value="LinkedIn">LinkedIn</option>
                                                        <option value="Twitter">Twitter</option>
                                                        <option value="Instagram">Instagram</option>
                                                        <option value="Facebook">Facebook</option>
                                                        <option value="jobsDB">jobsDB</option>
                                                        <option value="Others">Others</option>
                                                    </Form.Control>
                                                </div>
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            { errors.find_role }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="form-element" controlid="formBasicEmail">
                                        <div className="urban-app-label">
                                            <Form.Label>What is your current salary?<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="urban-app-field">
                                            <Form.Control type="text" placeholder="Current Salary" name="current_salary"  onChange={handleChange} isInvalid={ !!errors.current_salary } onBlur={handleChange} />
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            { errors.current_salary }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="form-element" controlid="formBasicEmail">
                                        <div className="urban-app-label">
                                            <Form.Label>What is your expected salary?<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="urban-app-field">
                                            <Form.Control type="text" placeholder="Expected Salary" name="expected_salary"  onChange={handleChange} isInvalid={ !!errors.expected_salary } onBlur={handleChange} />
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            { errors.expected_salary }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <div class="spacer"></div>
                                    <div class="form-element">
                                        <div class="urban-app-label full-width">Portfolio if any </div>
                                        {/* <div class="urban-app-field full-width urban-app-field2">
                                                <div class="file-btn">
                                                <svg xml:space="preserve" enable-background="new 0 0 16 16" viewBox="0 0 16 16" height="16" width="16px" y="0px" x="0px" class="icon icon-paperclip"><path d="M3.036,14.696c-0.614,0-1.219-0.284-1.788-0.853l-0.083-0.082c-0.586-0.578-2.305-2.956,0.008-5.391 c1.165-1.226,2.771-2.813,4.471-4.493C6.558,2.976,7.509,2.036,8.455,1.09c1.708-1.707,2.958-1.317,4.894,0.528 c2.288,2.178,2.707,4.322,1.718,5.463c-1.314,1.515-6.285,6.488-6.496,6.699c-0.278,0.279-0.729,0.279-1.008,0 c-0.278-0.278-0.278-0.729,0-1.008c0.051-0.051,5.146-5.148,6.427-6.625c0.294-0.339,0.339-1.629-1.624-3.498 c-1.13-1.076-1.465-1.989-2.902-0.551c-0.948,0.948-1.901,1.89-2.817,2.793C4.954,6.564,3.355,8.144,2.207,9.353 c-1.349,1.421-0.656,2.788-0.041,3.395l0.089,0.088c0.524,0.523,0.952,0.665,1.718-0.102c0.213-0.213,0.656-0.644,1.213-1.185 C6.729,10.05,9.6,7.26,10.18,6.534c0.184-0.23,0.452-0.787,0.196-1.011c-0.353-0.31-1.002,0.315-1.192,0.514 c-2.012,2.112-4.64,4.643-4.666,4.667c-0.283,0.273-0.734,0.265-1.007-0.02c-0.273-0.284-0.265-0.734,0.019-1.007 c0.026-0.025,2.633-2.535,4.622-4.624c1.291-1.356,2.48-1.201,3.162-0.604c0.832,0.727,0.636,2.154-0.021,2.974 c-0.586,0.734-2.847,2.945-5.113,5.146c-0.55,0.536-0.988,0.96-1.199,1.171C4.346,14.378,3.686,14.696,3.036,14.696L3.036,14.696z" fill="#3F484B"></path></svg>
                                                <span class="filename"></span>
                                                <span class="default-label">PORTFOLIO</span>
                                                <input id="portfolio" name="portfolio" class="application-file-input form-control" type="file">
                                            </div>
                                        </div>                       */}
                                    </div>
                                    <div class="spacer"></div>
                                    <Form.Group className="form-element" controlid="formBasicEmail">
                                        <div className="urban-app-label">
                                            <Form.Label>What is your expected salary?<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="urban-app-field">
                                            <Form.Control type="text" placeholder="Expected Salary" name="additional_information"  onChange={handleChange} isInvalid={ !!errors.additional_information } onBlur={handleChange} />
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            { errors.additional_information }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <div class="form-element align-center job-bot-appbutton">
                                        {/* <input type="submit" class="btn btn-black" value="Submit your application" name="submit"> */}
                                    </div>
                                </div>
                                    {/* 
                                
                                <div class="form-block">
                                    <div class="form-element">
                                            <div class="urban-app-label full-width">Additional information</div>
                                            <div class="urban-app-field full-width"><textarea name="additional_information" class="textbox form-control" rows="5"></textarea></div>         
                                    </div>
                                    <div class="form-element align-center job-bot-appbutton">
                                        <input type="submit" class="btn btn-black" value="Submit your application" name="submit">
                                    </div> 
                                </div>
                                */}
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
		</SimpleBar>
    {/* </MusicDashboard> */}
    </singlePageLayout>
  );
}

function mapStateToProps(state) {
    console.log('state.career.careerLists', state.career.careerLists)
  return {
		loggingIn: state.authentication.loggedIn,
        careerLists: state.career.careerLists
  };
}

function mapDispatchToProps(dispatch) {
  return {
	getCareerLists: (data) => dispatch(actions.getCareerLists(data)),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ApplyJob);