import * as types from './type';

import { Career }  from  '../../services/career';

export function getCareerLists(data) {
    return {
      type: types.GET_CAREER_LISTING,
      payload: Career.getCareerLists(data),
    };
};

export function getJobDetail(data) {
  return {
    type: types.GET_JOB_DETAIL,
    payload: Career.getCareer(data),
  };
};