import React, { useState, useEffect } from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import AccountNav from '../../elements/accountElements/accountNav';
import Table from 'react-bootstrap/Table';
import Pagination from "react-js-pagination";
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import config from '../../config/config';
import { checkPremiumMember } from '../../components/utlity/checkPremiumMember';
import {connect} from 'react-redux';
import * as billingAction from '../../store/billing/actions'

const BillingHistory = ({ billing, getBillingHistory, prepareInvoice, downloadBillingEntry, displayLoading, hideLoading, userProfile }) => {
    const navigate = useNavigate();
    const [  page, setPage ] = useState(1);
    const perPageLimit =  config.itemsCountPerPage;
    const [ order, setOrder ] = useState('history_id');
    const [ orderDir, setOrderDir ] = useState('DESC');
    const [ startDate, setStartDate ] = useState();
    const [ endDate, setEndDate ] = useState();
    const [ activeBadge, setActiveBadge ] = useState('all');
    const [ years, setYears] = useState();
    const [ downloadFormat, setDownloadFormat ] = useState();
    const [processingBundle, setProcessingBundle ] = useState(false);
    const currentYear = new Date().getFullYear();
    const [ isPremuim, setIsPremuim ] = useState(false);
  
    useEffect(() => { 
      loadPageData()
    },[]);
    useEffect(() => { 
        if(checkPremiumMember(userProfile)){
            setIsPremuim(true);
        }
      },[userProfile])
    
    const loadPageData = async () => {
        displayLoading();
        var yearArray = []
        for (var i=currentYear; i > (currentYear-3); i--) {
            yearArray.push(i);
        }
        setYears(yearArray)
        await getBillingHistory({page: page, order: order, orderDir});
        hideLoading();
        window.scrollTo({top: 0});
    }

    const handlePageChange = async (pageNumber) => {
        displayLoading();
        setPage(pageNumber);
        await getBillingHistory({page: pageNumber, order: order, orderDir, start_date: startDate, end_date: endDate}).then((res) => {});
        hideLoading();
        window.scrollTo({top: 0});
    }
    
    const filterBillingHistory = async() => {
        displayLoading();
        await getBillingHistory({page: 1, order: order, orderDir, start_date: startDate, end_date: endDate}).then((res) => {});
        hideLoading();
    }
  
    const filterBillingHistoryByYear = async(YY) => {
        displayLoading();
        setActiveBadge(YY);
        if(YY === 'all'){
          await getBillingHistory({page: page, order: order, orderDir});
        } else {
          const start_date = {"day":1, "month":1, "year": YY};
          setStartDate(start_date);
          const end_date = {"day":31, "month":12, "year": YY}
          await getBillingHistory({page: 1, order: order, orderDir, start_date: start_date, end_date: end_date}).then((res) => {});
          setEndDate(end_date);
        }
        hideLoading();
        window.scrollTo({top: 0});
    }
  
      const downloadBillingById = async (historyId, format='') => {
            displayLoading();
            await downloadBillingEntry({ history_id: historyId, format:format }).then((res) => {
                if(res.payload.data && res.payload.data.url){
                downloadFile(res.payload.data)
                }
            });
            hideLoading();
      }
  
      const dowloadInvoice = async(format) => {
          setDownloadFormat(format);
          setProcessingBundle(true);
          displayLoading();
          await prepareInvoice({format: format, startDate: startDate, endDate: endDate}).then((res) => {
              if(res.payload.data && res.payload.data.url){
                  downloadFile(res.payload.data)
              }
          });
          setProcessingBundle(false);
          hideLoading();
      }
  
      const downloadFile = (data) => {
        var downloadurl = `${config.uploadUrl}site/download?url=${data.url}&file=${data.file}`;
        // const downloadurl = data.url;
        window.location.assign(downloadurl);
    }
    
    return (
        <MusicDashboard accountPages={true} middleSectionCssClass={"account-info-sec"}>
                <div className="content-sec">
                    <div className="account-block">
                        <div className="top-heading">
                            <h2>Account Settings</h2>
                        </div>
                        <AccountNav/> 
                        <div className="account-inner-block">
                            <div className="billing-history">
                            { 
                                billing && billing.length == 0 ?
                                    <div className="title-heading">
                                        <div className="back-arrow-head">
                                            <span className="cp" onClick={() => filterBillingHistoryByYear('all')} style={{marginRight: '7px'}}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12 6L8.09618 9.78462C8.06582 9.81224 8.04162 9.8456 8.02508 9.88265C8.00853 9.91969 8 9.95963 8 10C8 10.0404 8.00853 10.0803 8.02508 10.1174C8.04162 10.1544 8.06582 10.1878 8.09618 10.2154L12 14" stroke="#080A0D" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                            </span>
                                            <h3>Billing History</h3>
                                        </div>
                                      
                                    </div>
                                :
                                    <div className="title-heading">
                                        <h3>Billing History</h3>
                                        <div>
                                        {
                                            billing && billing.billing_history && billing.billing_history.length > 0 ?
                                                <div className="billing-button">
                                                    { processingBundle && downloadFormat == 'pdf' ? <button className="primary-btn" disabled="true"><Spinner animation="border" variant="dark" size="sm" /></button> :  isPremuim ? <button className="primary-btn cp" onClick={() => dowloadInvoice('pdf')}>Download All Invoices (PDF)</button> : '' }
                                                    { processingBundle && downloadFormat == 'excel' ? <button className="primary-btn" disabled="true"><Spinner animation="border" variant="dark" size="sm" /></button> : <button className="secondary-btn cp" onClick={() => dowloadInvoice('excel')}>Download All Invoices (Excel)</button> }
                                                </div>
                                            : ''
                                        }
                                        </div>
                                    </div>
                                }
                                <div className="billing-years">
                                    <ul className="top-bages">
                                        <li  onClick={() => filterBillingHistoryByYear('all')} className={activeBadge === 'all' ? 'active cp' : 'cp'}><span >All</span></li>
                                        {
                                            years && years.length > 0 ? 
                                                years.map((year, index) => {
                                                return <li key={`year${year}`} onClick={() => filterBillingHistoryByYear(year)} className={activeBadge === year ? 'active cp' : 'cp'}><span >{year}</span></li>
                                                })
                                            : ''
                                        }
                                    </ul>
                                </div>
                                <div className="table-content">
                                    <Table hover responsive>
                                        <thead>
                                            <tr>
                                            <th>Date</th>
                                            <th>Type</th>
                                            <th>Payment Method</th>
                                            <th>Amount</th>
                                            <th>Invoice</th>
                                            </tr>
                                        </thead>
                                        {
                                            billing && billing.billing_history && billing.billing_history.length > 0 ?
                                            <tbody>
                                                {
                                                    billing.billing_history.map((history, index)=>{
                                                        return <tr key={`billing${index}`}>
                                                                <td>{history.payment_date}</td>
                                                                <td>{history.plan_name}</td>
                                                                <td>{history.payment_method}</td>
                                                                <td>{history.amount}</td>
                                                                <td className="text-uppercase">	
                                                                    <span  onClick={()=> downloadBillingById(history.history_id, 'pdf')} className="primary-color cp">PDF</span> 
                                                                     &nbsp; <span onClick={()=> downloadBillingById(history.history_id, 'excel')} className="primary-color cp">Excel</span>
                                                                </td>
                                                        </tr>
                                                    })
                                                }	
                                                
                                            </tbody>
                                            : ''
                                        }
                                        {
                                            billing && billing.length == 0 ?
                                                <tbody><tr><td>You have no billing details.</td></tr></tbody>
                                            :
                                            ''
                                        }
                                    </Table>
                                </div>
                                
                            
                                <div className="pagingnation-sec">
                                {
                                    billing && billing.pagination ? 
                                        <Pagination
                                            activePage={billing.pagination.page}
                                            itemsCountPerPage={billing.pagination.perPageLimit}
                                            totalItemsCount={billing.pagination.totalCount}
                                            // pageRangeDisplayed={pageRangeDisplayed}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                    /> : ''
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MusicDashboard>
    )
}

function mapStateToProps(state) {
    return {
        billing: state.billing.billing,
        userProfile: state.customer.customerProfile,
    };
}
      
function mapDispatchToProps(dispatch) {
    return {
        getBillingHistory: (post) => dispatch(billingAction.getBilling(post)),
		prepareInvoice: (post) => dispatch(billingAction.prepareInvoice(post)),
        downloadBillingEntry: (post) => dispatch(billingAction.downloadBillingEntry(post)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(BillingHistory);