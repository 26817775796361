import React, {useEffect, useState} from "react";



import AccountNav from '../../elements/accountElements/accountNav'; 
import MusicDashboard from "../../layouts/musicDashboard";
import queryString from 'query-string';
import Spinner from 'react-bootstrap/Spinner';
import {connect} from 'react-redux';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as subscriptionAction from '../../store/subscription/actions'
import { useLocation, useParams, useNavigate } from "react-router-dom";

const ConfirmPayment = ({displayLoading, hideLoading, getCustomerPlan, customerPlan, confirmPaypalPayment, confirmPayment}) => {

    let match = useParams("/subscription/confirm-payment/:customerplan");
    const location = useLocation();
    const navigate = useNavigate();
    const [ coupon, setCoupon ] = useState();
    const [subscription, setSubscription] = useState({
        payment_method: 'paypal',
        service_id: '',
        customer_plan_id: match.customerplan,
        token: ''
    });
    const [ paymentToken, setPaymentToken ] = useState();
    const [processConfirmPayment, setProcessConfirmPayment] = useState(false);
    
    
    
    useEffect(() => { 
		// load Page Data
		loadPageData();
    },[]);
    
    
    
	const loadPageData = async() => {
        var parsed = queryString.parse(location.search);
        setPaymentToken(parsed.token);
        let subscriptionData = subscription;
        subscriptionData.token = parsed.token
        setSubscription(subscriptionData);
   		displayLoading();
        await getCustomerPlan(subscription);
        hideLoading();
        window.scrollTo({top: 0});
    }
    
    const processPayment = async() => {
        displayLoading();
        setProcessConfirmPayment(true);
        let postData = customerPlan;
        postData.payment_token = paymentToken
        await confirmPaypalPayment(postData).then(res=> {
            if(res && res.payload && res.payload.data){
                if(res.payload.data.status){
                    setProcessConfirmPayment(false);
                    navigate('/subscription/success/complete');
                    
                } else {
                    setProcessConfirmPayment(false);
                    navigate('/subscription/failed');
                }
            }
        });
        hideLoading();
    }

    
    
    return (
        <MusicDashboard middleSectionCssClass={"account-info-sec  full-section"}>
            <div className="content-sec">
                <div className="account-block">
                    <div className="top-heading">
                        <h2>Account Setting</h2>
                    </div>
                    <AccountNav/> 
                    <div className="account-inner-block">
                        <div className="subscription-sec">
                        <div className="top">
								<h5>Hi, James! One more step to complete your order</h5>
								<h1>Confirm Payment</h1>
								<p className="text-3">Please review your membership details and billing information to complete your order.</p>
							</div>
							<div className="payment-block mobile-payment">
                                {
                                    customerPlan ?
                                        <div className="right">
                                            <div className="your-plan box">
                                                <div className="top">
                                                    <h2>Your plan</h2>
                                                    <h2>${customerPlan && customerPlan.discount_amount && parseInt(customerPlan.discount_amount) > 0  ? ( parseInt(customerPlan.discount_amount) +  parseInt(customerPlan.amount)).toFixed(2) :  parseInt(customerPlan.amount).toFixed(2) }</h2>
                                                </div>
                                                <div className="body-text">
                                                    <span className="text-2">{customerPlan.plan_name}:</span>
                                                    <span className="text-3">{customerPlan.plan_description}</span>
                                                        
                                                    <br />
                                                    {
                                                        customerPlan && customerPlan.discount_amount && parseInt(customerPlan.discount_amount) > 0  ? 
                                                            <span className="text-2">Discount:</span>
                                                        : ''
                                                    }
                                                    
                                                    {
                                                        customerPlan && customerPlan.discount_amount && parseInt(customerPlan.discount_amount) > 0  ? 
                                                            <span className="text-3">-${parseInt(customerPlan.discount_amount).toFixed(2)}</span>
                                                        : ''
                                                    }
                                                    <span className="text-2">Total:</span>
                                                    <span className="text-3">${ parseInt(customerPlan.amount).toFixed(2) }</span>
                                                    
                                                </div>
                                                {
                                                    processConfirmPayment ? 
                                                        <button className="primary-btn" disabled="disabled"><Spinner animation="border" size="sm" variant="dark" /></button> 
                                                    :
                                                        <button  onClick={() => processPayment()} className="primary-btn">Proceed to Payment</button>
                                                }
                                            </div>
                                        </div>
                                    : ''
                                }
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </MusicDashboard>
    )
}
function mapStateToProps(state) {
    return {
        agreement: state.subscription.agreement,
        customerPlan: state.subscription.customerPlan,
        confirmPayment: state.subscription.confirmPayment
    };
}

function mapDispatchToProps(dispatch) {
  	return {
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        confirmPaypalPayment: (post) => dispatch(subscriptionAction.confirmPaypalPayment(post)),
        getCustomerPlan: (post) => dispatch(subscriptionAction.getCustomerPlan(post))
  	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPayment);