import React, { useEffect, useState} from "react";

//custom components
import SongTrackLoader from '../../components/loader/songTrackLoader';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import RightSide from "../../components/rightSide/rightSide";
import Pagination from "react-js-pagination";
import MusicDashboard from '../../layouts/musicDashboard';
import SongTrack from '../../components/songTrack/songTrack';
import {connect} from 'react-redux';
import * as genreActions from '../../store/genres/actions';
import { useNavigate, useParams } from "react-router-dom";

const  DownloadReportDetail = ({ getDownloadReportBySongs, downloadReportBySongs, displayLoading, hideLoading}) => {

    let match = useParams("/mp3/download-report/genre/:genre_id");
    const navigate = useNavigate();

    const [genreId, setGenreId] = useState();
    const [ page, setPage ] = useState(1);
    const [ order, setOrder ] = useState('entity_id');
    const [ orderDir, setOrderDir ] = useState('DESC');
    const [ songSectionId, setSongSectionId ] = useState(1);
    const [ processing, setProcessing] = useState(false);
    const [ currentGenre, setCurrentGenre ] = useState();

    useEffect(() => { 
        setProcessing(true);
        setGenreId(match.id)
        if(match.genre_id != genreId){
            displayLoading();
            getDownloadReportBySongs({page: 1, order: order, orderDir: orderDir, song_sectoin_id: songSectionId, genre_id: match.genre_id}).then((res) => {
                
            });
            setProcessing(false);
            hideLoading();
            window.scrollTo({top: 0});
        } 
    },[navigate]);
    

    const handlePageChange = async (pageNumber) => 
    {
        setProcessing(true);
        displayLoading();
        setPage(pageNumber);
        await getDownloadReportBySongs({page: pageNumber, order: order, orderDir: orderDir, song_section_id: songSectionId, genre_id: match.genre_id});
        hideLoading();
        setProcessing(false);
    }
    
    // console.log(genresWithCount.indexOf(match.id));
  return (
    <MusicDashboard rightContent={<RightSide blocks={{ top30TrendingTracks: true}} />}>
        <div className="content-sec">
            <div className="play-list-sec">
                <div className="top-heading">
                { downloadReportBySongs && downloadReportBySongs.genre ? 
                    <div className="back-arrow-head">
                            <span className="cp" onClick={() => {
                                setPage(0);
                                navigate(-1)
                            }}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 6L8.09618 9.78462C8.06582 9.81224 8.04162 9.8456 8.02508 9.88265C8.00853 9.91969 8 9.95963 8 10C8 10.0404 8.00853 10.0803 8.02508 10.1174C8.04162 10.1544 8.06582 10.1878 8.09618 10.2154L12 14" stroke="#080A0D" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                            </span>
                            { downloadReportBySongs && downloadReportBySongs.genre ? <h2 dangerouslySetInnerHTML={{__html: downloadReportBySongs.genre.name}}></h2> : ''}
                        </div>
                    : ''
                    //  <h2>{ downloadReportBySongs && downloadReportBySongs.genre ? <span dangerouslySetInnerHTML={{__html: downloadReportBySongs.genre.name}}></span> : ''}</h2>
                }
                </div>
                <div className="inner-block">
                        
                            
                        {
                            processing ? <SongTrackLoader itemCount={20} /> : 
                            downloadReportBySongs && downloadReportBySongs.genre && downloadReportBySongs.genre.songs && downloadReportBySongs.genre.songs.length > 0 ?
                                    <SongTrack songs={downloadReportBySongs.genre.songs}   /> 
                                : ''
                        }
                </div>
            </div>
            <div className="pagingnation-sec">
            {
                downloadReportBySongs && downloadReportBySongs.genre && downloadReportBySongs.genre.pagination ? 
                    <Pagination
                        activePage={downloadReportBySongs.genre.pagination.page}
                        itemsCountPerPage={downloadReportBySongs.genre.pagination.perPageLimit}
                        totalItemsCount={downloadReportBySongs.genre.pagination.totalCount}
                        // pageRangeDisplayed={pageRangeDisplayed}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                /> : ''
            }
            </div>
        </div>
    </MusicDashboard>
    )
}

function mapStateToProps(state) {
  return {
    downloadReportBySongs: state.genre.downloadReportBySongs,
    loggingIn: state.authentication.loggedIn,
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getDownloadReportBySongs: (data) => dispatch(genreActions.getDownloadReportBySongs(data)),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
	hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(DownloadReportDetail);