import React from "react";
import {connect} from 'react-redux';


const  PlanLoader = ({ itemCount }) => {

    var indents = [];
    
    for (var i = 0; i < itemCount; i++) {
    indents.push(
        <div className="subscription-box" key={`plan${i}`}>
            <div className="inner-block">
                <span className="icon">
                <div className="image"></div>
                </span>
                <h2>
                <div className="text-line"></div>
                </h2>
                <h1>
                <div className="text-line"></div>
                </h1>
                <div className="plan-text">
                <div className="text ">
                    <div className="text-line"></div>
                </div>
                </div>
                <div className="feature-list">
                    <div className="text">
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                    </div>
                </div>
            </div>
            <div className="text-line button"></div>
        </div>
                    
                );
    }

    return (
        <div className="loader-wrapper">{indents}</div>	
    );
  
}


function mapStateToProps(state) {
	return {
		
	};
}
 
function mapDispatchToProps(dispatch) {
	return {
		
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanLoader);