import {axiosReqGet, axiosReqOther} from "./config";
import { store } from '../store/store';


export const Career = {
    getCareerLists,
    getCareer,
    uploadJobDocument,
    applyJob
}

function getCareerLists(){
    const url = 'career/index.json?store_id=1&lang_code=en';
    return axiosReqGet()
            .get(url)
            .then(res => {
            return res; 
        })
        .catch(err => { 
            // alert(err.response.data.message)      
            return err; 
        })
}
function getCareer(data){
    
    const url = 'career/get-job.json?store_id=1&lang_code=en';
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}
function uploadJobDocument(data){
    
    const url = 'career/upload-job-document.json?store_id=1&lang_code=en';
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}
function applyJob(data){
    
    const url = 'career/apply-job.json?store_id=1&lang_code=en';
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

export default Career