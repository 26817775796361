import React, {useEffect, useState} from "react";

//custom components

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import MusicDashboard from "../../layouts/musicDashboard";
import AccountNav from '../../elements/accountElements/accountNav';
import Table from 'react-bootstrap/Table';
import Pagination from "react-js-pagination";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Spinner from 'react-bootstrap/Spinner';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import { useNavigate } from "react-router-dom";
import * as uploadsAction from '../../store/uploads/actions';
import * as songAction from '../../store/song/actions';

const UploadsList = ({songLists, getUploadedSongs, displayLoading, hideLoading, renewSong, deleteSong, getSearchResultSongs, userProfile, enableDisableDeleteSong}) => {
    const navigate = useNavigate();
    const [ page, setPage ] = useState(1);
    const [ order, setOrder ] = useState('entity_id');
    const [ orderDir, setOrderDir ] = useState('DESC');
    const [deleteEntityId, setDeleteEntityId ] = useState();
    const [ searchTerm, setSearchTerm ] = useState();
    const [isSubmit, setIsSubmit] = useState(false)
    const MySwal = withReactContent(Swal)
    const [ processingSearch, setProcessingSearch ] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState(null);
    const [checkboxes, setCheckboxes] = useState();

    useEffect(() => {
        if (deleteMessage) {
          // Set a timer to clear the message after 5 seconds
          const timerId = setTimeout(() => {
            setDeleteMessage(null);
          }, 5000);
    
          // Clear the timer when the component unmounts or when a new message is set
          return () => clearTimeout(timerId);
        }
      }, [deleteMessage]);
    
      useEffect(() => {
        if (deleteMessage) {
            setCheckboxes(songLists.data.map((item) => ({ ...item, checked: false })));
        }
      }, [deleteMessage]);        
  
    useEffect(() => { 
        // load Page Data
        if(userProfile && userProfile.user_song && userProfile.user_song == 1){
            loadPageData();
        } else {
            navigate(`/profile/update`);
        }
    },[]);

    useEffect(() => { 
        if(songLists && songLists.data && songLists.data.length > 0){
            const newData =  songLists.data.map((item) => ({ ...item, checked: false }));
            setCheckboxes(newData);
        }
    },[songLists.data]);
    
    
    const loadPageData = async() => {
        displayLoading();
        await getUploadedSongs({song_sectoin_id: 1,  page: 1, pageLimit: 20, orderDir: orderDir, order: order});
        hideLoading();
    }
  
    const handlePageChange = async (pageNumber) => {
        setPage(pageNumber);
        displayLoading();
        
        if(searchTerm){
            await getUploadedSongs({song_sectoin_id: 1,  page: pageNumber, pageLimit: 20, orderDir: orderDir, order: order, searchTerm: searchTerm});
        } else {
            await getUploadedSongs({song_sectoin_id: 1,  page: pageNumber, pageLimit: 20, orderDir: orderDir, order: order});
        }
        window.scrollTo({top: 0});
        hideLoading();
    }
  
    /**
     * Method: renewSong
     */
    const makeSongRenew = async (entity_id, event) => {
          const renewSongConfirm = window.confirm('Are you sure you want to renew this song?');
          if(renewSongConfirm){
              displayLoading();
              await renewSong({entity_id: entity_id, page: 0, pageLimit: 20});
              await getUploadedSongs({song_sectoin_id: 1,  page: 1, pageLimit: 20, orderDir: orderDir, order: order});
              hideLoading();
              window.scrollTo({top: 0});
          }
    }

    const confirmDelete = (entity_id) => {
       
        var entity_id = entity_id;
        setDeleteEntityId(entity_id);
        MySwal.fire({
            title: "Are you sure?",
            text: "Do you want to delete the song?",
            type: "info",
            showCancelButton: true,
            confirmButtonClass: "btn-danger",
            confirmButtonText: "Yes",
            closeOnConfirm: false,
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
        }).then(res => {
            if(res.value){
                deleteSongEntity(entity_id);
                
            }
        });
    }
  
    const deleteSongEntity = async(entity_id) => {
        displayLoading();
        await deleteSong({entity_id: entity_id}).then(res => {
            setDeleteMessage('Song deleted successfully');
        });
        await getUploadedSongs({song_sectoin_id: 1,  page: 1, pageLimit: 20, orderDir: orderDir, order: order});
        hideLoading();
    }
  
    const handleChangeSearch = (e) => {
        const {name, value } = e.target;
        var inputValue = value.trim();
        
        if(inputValue.length > 0){
            setSearchTerm(inputValue); 
        } else {
            document.getElementById('self-search').value = inputValue;
            setSearchTerm(inputValue); 
            if(isSubmit){
                
                displayLoading();
                getUploadedSongs({song_sectoin_id: 1,  page: 1, pageLimit: 20, orderDir: orderDir, order: order}).then((res) => {
                    hideLoading();
                    window.scrollTo({top: 0});
                    setIsSubmit(false);
                })
            }
        }
    }

    
    
    const searchSong = () => {
        setProcessingSearch(true);
        if(searchTerm){
          setIsSubmit(true);
          displayLoading();
          getUploadedSongs({page: 1, order: order, orderDir, song_section_id: 1, searchTerm: searchTerm}).then((res) => {
            hideLoading();
            window.scrollTo({top: 0});
          })
        }
        setProcessingSearch(false);
    }
    
    const handleCheckboxChange = (id) => {
        const updatedCheckboxes = checkboxes.map((checkbox) =>
          checkbox.entity_id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
        );
        setCheckboxes(updatedCheckboxes);
    };

    const handleMasterCheckboxChange = (event) => {
        const updatedCheckboxes = checkboxes.map((checkbox) => ({
          ...checkbox,
          checked: event.target.checked
        }));
        setCheckboxes(updatedCheckboxes);
    };

    const shootEnableDisableDeleteSong = async (status) => {
        const updatedCheckboxes = checkboxes.map((checkbox) =>
          checkbox.checked ? checkbox.entity_id : ''
        );
        const ids = updatedCheckboxes.filter((value) => value !== '' && value !== undefined && value !== null);
        if(ids.length > 0){
            await enableDisableDeleteSong({ids: ids, status: status });
            await handlePageChange(page);
            // const newData =  songLists.data.map((item) => ({ ...item, checked: false }));
            // setCheckboxes(newData);
        } else {
            MySwal.fire({
                title: "Error",
                text: "Please select song to "+status,
                type: "error",
                showCancelButton: false,
               
            })          
        }
    }
    
  
    return (
        <MusicDashboard accountPages={true} middleSectionCssClass={"account-info-sec"}>
            <div className="content-sec">
                <div className="account-block">
                    <div className="top-heading">
                        <h2>Account Settings</h2>
                    </div>
                    <AccountNav/> 
                    <div className="account-inner-block">
                        <div className="billing-history">
                            <div className="title-heading">
                                <h3>Uploads</h3>
                            </div>
                            <div className="top-actions">
                                <div className="search-items form-group">
                                    <input type="text" placeholder="Search Song" name="search" id="self-search" onChange={handleChangeSearch} className="search-input form-control" style={{borderRadius: '10px 0 0 10px'}} />
                                    { processingSearch ? <button className="priary-btn" style={{ height: '40px', padding: '3px 0', maxWidth: '60px', borderRadius: '0 10px 10px 0'}}><Spinner animation="border" variant="dark" size="sm" /></button> : <button className="primary-btn" onClick={searchSong} style={{ height: '40px', padding: '3px 0', maxWidth: '60px', borderRadius: '0 10px 10px 0'}}>Go</button> }
                                </div>
                                <div className="">
                                    <Link to={`/uploads/new`} className="btn primary-btn" style={{height: '33px', minWidth: '100px', padding: '3px 0'}}>Add New</Link>
                                </div>
                            </div>
                            {deleteMessage ? <div className="alert alert-success">{deleteMessage}</div>: ''}
                            <div className="table-content">
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                        <th>
                                            {checkboxes && checkboxes.length>0 ? 
                                            <input type="checkbox"  name="select_deselect"  className="sort-input" checked={checkboxes.every((checkbox) => checkbox.checked)}
                                                onChange={handleMasterCheckboxChange}>
                                            </input>
                                            :''
                                            }
                                        </th>
                                        <th>Title</th>
                                        <th>Artist</th>
                                        <th>Corrupt Versions</th>
                                        <th>Song Status</th>
                                        <th>Action</th>
                                        </tr>
                                    </thead>
                                    {
                                        checkboxes && checkboxes.length > 0 ?
                                        <tbody>
                                        {
                                            checkboxes.map((item, index)=>{
                                            return <tr key={`uploadlist${index}`}>
                                                <td><input type="checkbox" checked={item.checked} name={item.entity_id} value={item.entity_id} className="sort-input" onChange={(event) => handleCheckboxChange(item.entity_id)}></input></td>
                                                <td>{item.title}</td>
                                                <td>{item.artist}</td>
                                                <td>{item.corrupt_version}</td>
                                                <td>{item.is_active == '1' ? 'Active' : 'Disable'}</td>
                                                <td className="text-uppercase">	
                                                    <span className="cp primary-color"  onClick={()=> makeSongRenew(item.entity_id)}>Renew</span> &nbsp;
                                                    <Link to={`/uploads/edit-song/${item.entity_id}`}>Edit</Link> &nbsp;
                                                    <span className="cp primary-color" onClick={()=> confirmDelete(item.entity_id)}>Delete</span>
                                                </td>
                                            </tr>
                                            })
                                        }	
                                        
                                        </tbody>
                                        :  ''
                                    }
                                    {
                                        songLists && songLists.length == 0 ?
                                            <tbody><tr><td><div className="alert alert-danger">You have no uploaded songs.</div></td></tr></tbody>
                                        :
                                        ''
                                    }
                                </Table>
                            </div>
                            <div className="pagingnation-sec">
                            {
                                songLists && songLists.pagination ? 
                                    <Pagination
                                        activePage={songLists.pagination.page}
                                        itemsCountPerPage={songLists.pagination.perPageLimit}
                                        totalItemsCount={songLists.pagination.totalCount}
                                        // pageRangeDisplayed={pageRangeDisplayed}
                                        onChange={handlePageChange}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                /> : ''
                            }
                            </div>
                            <div className="enabledisable" style={{display: 'flex', marginTop: '20px'}}>
                                <span style={{height: '33px', maxWidth: '200px', padding: '3px 15px'}} class="primary-btn cp" onClick={() => shootEnableDisableDeleteSong('enable')}>Enable</span>
                                 &nbsp;&nbsp;<span class="primary-btn cp" style={{height: '33px', maxWidth: '200px', padding: '3px 15px'}} onClick={() => shootEnableDisableDeleteSong('disable')}>Disable</span>
                                 &nbsp;&nbsp;<span class="primary-btn cp" style={{height: '33px', maxWidth: '200px', padding: '3px 15px'}} onClick={() => shootEnableDisableDeleteSong('delete')}>Delete</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MusicDashboard>
    );
  }
  function mapStateToProps(state) {
    return {
        songLists: state.uploads.songList,
        userProfile: state.customer.customerProfile,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        getUploadedSongs: (post) => dispatch(uploadsAction.getUploadedSongs(post)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        renewSong: (post) => dispatch(songAction.renewSong(post)),
        deleteSong: (post) => dispatch(songAction.deleteSong(post)),
        enableDisableDeleteSong: (post) => dispatch(uploadsAction.enableDisableDeleteSong(post))
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(UploadsList);