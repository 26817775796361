import React, { useState, useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
// import Swal from 'sweetalert2';
// import withReactContent from 'sweetalert2-react-content';
import {  useNavigate } from "react-router-dom";
import {connect} from 'react-redux';
import * as actions from '../../store/song/actions';

const FavButton = ({ player, playList, playIndex, addSongToFavourite, getCustomerFavouriteSongs, favouriteSongs, isPlaying, themeType, userProfile }) => {
    // const MySwal = withReactContent(Swal);
    // const navigate = useNavigate();
    const [ customerFavSongs, setCustomerFavSongs ] = useState(favouriteSongs);
    const [ isFav, setIsFav ] = useState(false);
    const [ currentSongId, setCurrentSongId ] = useState();
    const [ downloadInProcess, setDownloadInProcess ] = useState(false);
    const [ canAccess, setCanAccess ] = useState(false);

    useEffect(() => { 
        setCustomerFavSongs(favouriteSongs);
    },[favouriteSongs]);

    useEffect(() => {
        const matchedSong = getPlaylistItem(playList[playIndex-1].src);
        setCurrentSongId(matchedSong.img);
        if(favouriteSongs && favouriteSongs && favouriteSongs.length > 0 && ((favouriteSongs.indexOf(matchedSong.img.toString()) > -1) )){
            setIsFav(true);
        } else {
            setIsFav(false);
        }
    }, [playIndex]);

    useEffect(() => {
        checkCustomer();
    }, [userProfile]);
       
    useEffect(() => { 
        if(player && player.current && player.current.audio && player.current.audio.current){
            const matchedSong = getPlaylistItem(player.current.audio.current.src);
            // console.log('matchedSong', matchedSong)
            if(matchedSong.img){
                setCurrentSongId(matchedSong.img)
            }
            // console.log(matchedSong ,' && ', customerFavSongs, ' && ', customerFavSongs, ' && ' , customerFavSongs.length, '&&', customerFavSongs.indexOf(matchedSong.img.toString()))
            if(matchedSong && customerFavSongs && customerFavSongs && customerFavSongs.length > 0 && ((customerFavSongs.indexOf(matchedSong.img.toString()) > -1))){
                setIsFav(true)
            } else {
                setIsFav(false)
            }
        }
   	},[isPlaying])

    const getPlaylistItem = (songSrc) => {
        var hasMatch = false;
		for (var index = 0; index < playList.length; ++index) {
            var songs = playList[index];
            if(songs.src == songSrc){
                hasMatch = songs;
                break;
            }
        }
        
		return hasMatch;
    }

    const checkCustomer = () => {
        if(userProfile && userProfile.group_id == 2){
            if(userProfile.payment_profile_status == 'paid' && userProfile.profile_status == 'ACTIVE'){
                setCanAccess(true);
            } else if(userProfile.payment_profile_status == 'unpaid'){
                setCanAccess(false);
            } else if(userProfile.payment_profile_status == "pending"){
                setCanAccess(true);
            } else if(userProfile.payment_profile_status == 'canceled' && userProfile.profile_status == 'Cancelled'){
                var d1 = new Date();
                var d2 = new Date(userProfile.cancel_download_date);
                var same = d1.getTime() === d2.getTime();
                // console.log('checked', userProfile.cancel_download_date)
                if(d2.getTime() > d1.getTime()){
                    setCanAccess(true);
                } else {
                    setCanAccess(false);
                }
            } else {
                setCanAccess(false);
            }
        } else if(userProfile && userProfile.group_id == 3){
            var d1 = new Date();
            var d2 = new Date(userProfile.staff_membership_expire_date);
            if(d2.getTime() > d1.getTime()){
                setCanAccess(true);
            } else {
                setCanAccess(false);
            }
        }
    }

    const addRemoveFromFav = async() => {
        setDownloadInProcess(true);
        await addSongToFavourite(currentSongId);
        await  getCustomerFavouriteSongs().then(res => {
            
            let hasMatch = false;
            
            if(res && res.payload && res.payload.data && res.payload.data.songs && res.payload.data.songs.length > 0 ){
                for (var index = 0; index < res.payload.data.songs.length; ++index) {
                    var song = res.payload.data.songs[index];
                    if(song.entity_id == currentSongId){
                        hasMatch = true;
                        break;
                    }
                }
                if(hasMatch){
                    setIsFav(true);
                } else {
                    setIsFav(false);
                }
            }
        });
        setDownloadInProcess(false);
        
    }

    if(canAccess) { 
        if(downloadInProcess){
            return <Spinner animation="border" variant="danger" size="sm" />
        } else {
            return (
                    <span onClick={() => addRemoveFromFav()} className="cp">
                        <svg width="16" height="14" viewBox="0 0 16 14" fill={ isFav ? '#EB4E27' : 'none'} xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.00003 13.2282L1.69146 7.51389C-1.73711 4.08532 3.30289 -2.49754 8.00003 2.82818C12.6972 -2.49754 17.7143 4.10818 14.3086 7.51389L8.00003 13.2282Z" stroke={themeType == 'dark-theme' ? "#D7D7D7": '#828282'} strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </span> 
            )   
        }
    } else {
        return null;
    }
   
    
   

}
function mapStateToProps(state) {
    return {
        player: state.player.audioInstance,
        playList: state.playlist.playList,
        favouriteSongs: state.song.favouriteSongs,
        isPlaying: state.isplaying.isPlaying,
        themeType: state.theme.theme,
        playIndex: state.playindex.playIndex,
        userProfile: state.customer.customerProfile,
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
        addSongToFavourite: (songId) => dispatch(actions.addSongToFavourite(songId)),
        getCustomerFavouriteSongs: () => dispatch(actions.getCustomerFavouriteSongs()),
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(FavButton);
  