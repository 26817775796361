import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as actions from '../../../store/mtk/actions';
import './home.scss';
import MtkDownloadBundleButton from "../../../elements/songElements/mtkDownloadButton";

const  Champion = ({ getPreviousChamionship, previousChampionship, displayLoading, hideLoading}) => {

	
	useEffect(() => { 
        displayLoading();
        getPreviousChamionship()
        hideLoading();
    },[]);

    // console.log('previousChampionship', previousChampionship)
    if(previousChampionship){
        const profileImage = {
            backgroundImage: 'url("'+previousChampionship.profile_picture+'")'
        };
        return (
            <div>
                <div className="dj">
                    <h2>#1 DJ {previousChampionship.name}</h2>
                    <p>of the previous month 
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.3931 9.2245L13.5416 16.1343L6.63184 14.2828" stroke="#EB4E27" stroke-width="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6.45801 3.8656L13.4705 16.0116" stroke="#EB4E27" stroke-width="2" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </p>
                </div>
                <div className="music-bg">
                    <div className="champion-cover-image" style={profileImage}>
                        {/* <img src={previousChampionship.profile_picture} alt="Artist" /> */}
                    </div>
                    <div className="music-bg-inner">
                        <div>
                            {/* <img src={CoverImage} alt="" /> */}
                            {
                                previousChampionship.song ? 
                                    <div>
                                        <span>{previousChampionship.song.title}</span>
                                        <span>{previousChampionship.song.artist}</span>
                                    </div>
                                : ''
                            }
                            
                            
                        </div>
                        {
                            previousChampionship && previousChampionship.song ? 
                                <MtkDownloadBundleButton entity_id={previousChampionship.song.entity_id} />
                            : ''
                        }
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    } 
}


function mapStateToProps(state) {
	return {
        previousChampionship: state.mtk.previousChampionship
	};
 }
 
 function mapDispatchToProps(dispatch) {
	return {
        getPreviousChamionship: () => dispatch(actions.getPreviousChamionship()),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(Champion);

				