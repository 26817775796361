import React, { useState, useEffect } from "react";
import { checkCustomer } from '../../components/utlity/checkCustomer';
import {connect} from 'react-redux';


const ZipSongButton = ({ song, addZipSong, zippedSongs, userProfile, removeZipSong }) => {
    const [ canAccess, setCanAccess ] = useState(false);
    
    

    useEffect(() => { 
        if(checkCustomer(userProfile)){
            setCanAccess(true);
        }
    },[userProfile])
       
   

    
    
    if(canAccess) { 
        if(zippedSongs && zippedSongs.length>0 && zippedSongs.includes(song.version.song_version_id)){
            return (
                <span onClick={() => removeZipSong(song)} className="cp" title="Add to Zip">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="#CCC" viewBox="0 0 384 512"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM96 48c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16zm-6.3 71.8c3.7-14 16.4-23.8 30.9-23.8h14.8c14.5 0 27.2 9.7 30.9 23.8l23.5 88.2c1.4 5.4 2.1 10.9 2.1 16.4c0 35.2-28.8 63.7-64 63.7s-64-28.5-64-63.7c0-5.5 .7-11.1 2.1-16.4l23.5-88.2zM112 336c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H112z"/></svg>
                </span>
            )
        } else {
            return (
                <span onClick={() => addZipSong(song)} className="cp" title="Add to Zip">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="#FFF" viewBox="0 0 384 512"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM96 48c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16zm-6.3 71.8c3.7-14 16.4-23.8 30.9-23.8h14.8c14.5 0 27.2 9.7 30.9 23.8l23.5 88.2c1.4 5.4 2.1 10.9 2.1 16.4c0 35.2-28.8 63.7-64 63.7s-64-28.5-64-63.7c0-5.5 .7-11.1 2.1-16.4l23.5-88.2zM112 336c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H112z"/></svg>
                </span>
            )
        }
    } else {
        return null;
    }
}
function mapStateToProps(state) {
    return {
        themeType: state.theme.theme,
        userProfile: state.customer.customerProfile,
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
        // addSongToFavourite: (songId) => dispatch(actions.addSongToFavourite(songId)),
        // getCustomerFavouriteSongs: () => dispatch(actions.getCustomerFavouriteSongs()),
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ZipSongButton);
  