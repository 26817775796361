import * as types from './type';

import { User }  from  '../../services/user';

export function getCustomerProfile(token) {
    return {
      type: types.GET_CUSTOMER_PROFILE,
      payload: User.getCustomerProfile(token),
    };
};


export function getUsersDevices(token) {
  return {
    type: types.GET_CUSTOMER_DEVICES,
    payload: User.getUsersDevices(token),
  };
};

export function removeUserDevice(data) {
  return {
    type: types.REMOVE_CUSTOMER_DEVICE,
    payload: User.removeUserDevice(data),
  };
};

export function forgetPassword(post) {
    return {
      type: types.RESET_CUSTOMER_PASSWORD_REQUEST,
      payload: User.forgetPassword(post),
    };
};

export function resetPassword(post) {
  return {
    type: types.RESET_PASSWORD,
    payload: User.resetPassword(post),
  };
};

export function updateProfile(post){
  return {
    type: types.UPDATE_PROFILE,
    payload: User.updateProfile(post),
  };
}

export function changePassword(post){
  return {
    type: types.CHANGE_PASSWORD,
    payload: User.changePassword(post),
  };
}

export function signUp(post){
  return {
    type: types.CUSTOMER_SIGNUP,
    payload: User.signUp(post),
  };
}

export function submitDJApp(post){
  return {
    type: types.SUBMIT_DJ_APP,
    payload: User.submitDJApp(post),
  };
}


export function getTokenByEmail(post){
  return {
    type: types.GET_TOKEN_BY_EMAIL,
    payload: User.getTokenByEmail(post),
  };
}

export function addPlaylistAlbum(post){
  return {
    type: types.ADD_PLAYLIST_ALBUM,
    payload: User.addPlaylistAlbum(post),
  };
}

export function addPlaylistAlbumImage(post){
  return {
    type: types.ADD_PLAYLIST_ALBUM_IMAGE,
    payload: User.addPlaylistAlbumImage(post),
  };
}

export function getCustomerPlayListCoverImage(){
  return {
    type: types.GET_PLAYLIST_ALBUM_IMAGE,
    payload: User.getCustomerPlayListCoverImage(),
  };
}

export function getCustomerPlayLists(data){
  return {
    type: types.GET_CUSTOMER_PLAYLIST_ALBUMS,
    payload: User.getCustomerPlayLists(data),
  };
}

export function addSongToPlaylist(post){
  return {
    type: types.ADD_SONG_TO_PLAYLIST_ALBUMS,
    payload: User.addSongToPlaylist(post),
  };
}

export function getPlayListSongs(post){
  return {
    type: types.GET_CUSTOMER_PLAYLIST_SONGS,
    payload: User.getPlayListSongs(post),
  };
}

export function removeSongFromCustomerPlaylist(post){
  return {
    type: types.REMOVE_SONG_FROM_CUSTOMER_PLAYLIST,
    payload: User.removeSongFromCustomerPlaylist(post),
  };
}

export function clearCustomerPlaylist(post){
  return {
    type: types.CLEAR_CUSTOMER_PLAYLIST,
    payload: User.clearCustomerPlaylist(post),
  };
}

export function deletePlaylist(post){
  return {
    type: types.DELETE_CUSTOMER_PLAYLIST,
    payload: User.deletePlaylist(post),
  };
}

export function sendVerifyDeviceCode(data){
  return {
    type: types.SEND_DEVICE_VERIFICATION_CODE,
    payload: User.sendVerifyDeviceCode(data),
  };
}

export function verifyCode(data){
  return {
    type: types.VERIFY_DEVICE_CODE,
    payload: User.verifyCode(data),
  };
}

export function updateProfileData(data){
  return {
    type: types.UPDATE_PROFILE_DATA,
    payload:data,
  };
}

export function updateRequiredFields(data){
  return {
    type: types.UPDATE_PROFILE_DATA,
    payload: User.updateRequiredFields(data),
  };
}