import * as types from './type';

const initialState = {
  mixes: [],
};



export default function mixesReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.GET_MIXES: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          mixes: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }

    default: {
      return {
        ...state
      };
    }
    
  }

}