import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import {connect} from 'react-redux';
import SingleDownloadButton from '../../elements/songElements/singleDownloadButton';
import PlayPauseButton from '../../elements/songElements/playPauseButton'; 
import DropboxButton from '../../elements/songElements/dropboxButton';
// import * as themeAction from '../../store/theme/actions';
import * as songActions from '../../store/song/actions';

const  Search = ({ fetchSuggestions, searchSuggestion, activeBtn, themeType }) => {

    const navigate = useNavigate();
    const [toggleClass, setToggleClass] = useState(false);
    const [searchTerm, setSearchTerm ] = useState();
    const [ searchClass, setSearchClass ] = useState();
    const [toggleSearch, setToggleSearch] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [ activeSongId, setActiveSongId ] = useState(0);
    const [searchBy, setSearchBy ] = useState();
    const [isVisible, setIsVisible] = useState(false);
    const [ showAdvanceSearch, setShowAdvanceSearch ] = useState(false);
    const [ resultText, setResultText ] = useState();
    const ref = useRef(null);
    
    useEffect(() => {
        // Add event listener when component mounts
        document.addEventListener('mousedown', handleClickOutside);
        // Cleanup the event listener when component unmounts
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []); 

    const handleClickOutside = (event) => {
        // Close the div if clicked outside of it
        if (ref.current && !ref.current.contains(event.target)) {
          setIsVisible(false);
          setShowAdvanceSearch(false);
          setSearchClass('')
        }
    };

    const hideAutoComplete = () => {
        setIsVisible(false);
        setShowAdvanceSearch(false);
        setSearchClass('')
    }

    const searchSongBy = (term) => {
        setSearchBy(term);
        fetchSuggestions({searchTerm: inputValue, search_by: term}).then(res => {
            setIsVisible(true);
        });
    }

    const handleInputChange = (event) => {
        
        const value = event.target.value;
        setInputValue(value);
        
        if(value.length == 0){
            setShowAdvanceSearch(true);
        }
        setIsVisible(true);
        setShowAdvanceSearch(false);
        // Fetch suggestions from the server when the input changes
        fetchSuggestions({searchTerm: value, search_by: searchBy}).then(res => {
            if(value.length > 4 && res.payload.data.length == 0){
                setResultText('No Results Found');
            } else {
                setResultText('');
            }
        });
    };
  
    const handleKeyDown = (event) => {
        if(inputValue.length < 5){
            setShowAdvanceSearch(true);
        }
        if(event.key == 'Enter'){
            if(inputValue && inputValue.length > 4){
                navigate(`/search/result/${inputValue}`);
            }
        }
    }

    const goToAdvanceSearch = () => {
        setIsVisible(false);
        setShowAdvanceSearch(false);
        navigate(`/search/result/${inputValue}`)
    }

    const onFocusEve = (e) => {
        setSearchClass('focus');
        if(inputValue.length == 0){
            setShowAdvanceSearch(true);
        }
        if(searchSuggestion){
            setIsVisible(true);
        }
    }
    return (
        <div className="top-search-container" ref={ref}>
            <InputGroup className={toggleSearch ? "search" : "search" }>
                    <InputGroup.Text id="Search" >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.76557 14.9599C12.1866 14.9599 14.9599 12.1866 14.9599 8.76557C14.9599 5.34457 12.1866 2.57129 8.76557 2.57129C5.34457 2.57129 2.57129 5.34457 2.57129 8.76557C2.57129 12.1866 5.34457 14.9599 8.76557 14.9599Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M17.4283 17.4283L13.1426 13.1426" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </InputGroup.Text>
                
                <Form.Control
                  placeholder="Search songs"
                  aria-label="Search"
                  aria-describedby="Search"
                  value={inputValue} 
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  onFocus={onFocusEve} 
                  className={searchClass}
                />
            </InputGroup>
            
            { 
                isVisible && searchSuggestion && searchSuggestion.songs && searchSuggestion.songs.length > 0 ?
                    <div className="autocomplete-result">
                        <span className="autocomplete-close" onClick={() => hideAutoComplete()}>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L9 9M9 1L1 9" stroke={themeType === 'light-theme' ? "#000" : "#FFF"} strokeLinecap="round"/>
                            </svg>
                        </span>
                        <div className="autocomplete-top">
                            <span className={searchBy == 'artist' ? "active cp" : "cp" } onClick={() => searchSongBy('artist')}>Artists</span>
                            <span className={searchBy == 'title' ? "active cp" : "cp" } onClick={() => searchSongBy('title')}>Song</span>
                        </div>
                        <h6>Latest searches:</h6>
                        <ul>
                        {
                            searchSuggestion.songs.map((song, index) => {
                                return <li key={`top30Trendingside${index}`} className={activeSongId == song.entity_id ? 'active' : ''} onClick={() => setActiveSongId(song.entity_id)}>
                                            <div className="song-counter">
                                                <span className="play-pause"><PlayPauseButton parentComponent="songVersion" song={song} version={song.version[0]} /></span>
                                            </div>
                                            <div className="content">
                                                <div>
                                                    <div className="track-name">
                                                        <div className="text-2" dangerouslySetInnerHTML={{__html: song.title}}></div>
                                                        { 
                                                            song.artist_search && song.artist_search[0].artist && song.artist_search[0].artist.length > 0 ? 
                                                                song.artist_search[0].artist.map((as, asIndex) => {
                                                                    return <div key={`as${asIndex}-${song.entity_id}`} ><Link to={`/mp3/artist/${as}`} dangerouslySetInnerHTML={{__html: as}} className="text-5"></Link> <span className="text-5">&nbsp;{ asIndex < (song.artist_search[0].artist.length -1) ? song.artist_search[0].split_string : ''}&nbsp;</span></div>
                                                                })
                                                            :
                                                            <div><Link to={`/mp3/artist/${song.artist}`} dangerouslySetInnerHTML={{__html: song.artist}} className="text-5"></Link></div>
                                                        }
                                                        {/* <div className="text-5" dangerouslySetInnerHTML={{__html: song.artist}}></div> */}
                                                    </div>
                                                </div>
                                                { activeBtn == 'download' ? <SingleDownloadButton songId={song.entity_id} version={song.version[0] ? song.version[0] : ''} songSectionId={song.song_section_id} /> : ''}
                                                { activeBtn == 'dropbox' ? <DropboxButton songId={song.entity_id} version={song.version[0] ? song.version[0] : ''} songSectionId={song.song_section_id} /> : ''}
                                            </div>
                                    </li>
                            })
                            
                        }
                        </ul>
                        <div className="more-result cp"><button className="btn-primary cp" onClick={() => goToAdvanceSearch()}>Advance Search</button></div>
                    </div>
                : ''
            }
            {
                inputValue.length == 0 && isVisible ? 
                <div className="autocomplete-result">
                    <span className="autocomplete-close" onClick={() => hideAutoComplete()}>
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 1L9 9M9 1L1 9" stroke="white" strokeLinecap="round"/>
						</svg>
                    </span>
                     <div className="more-result cp"><button className="btn-primary cp" onClick={() =>  goToAdvanceSearch()}>Advance Search</button></div>
                </div> 
                : ''
            }
            {
                inputValue.length > 0 && isVisible && searchSuggestion.length == 0  ? 
                <div className="autocomplete-result">
                    <span  className="autocomplete-close" onClick={() => hideAutoComplete()}>
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 1L9 9M9 1L1 9" stroke="white" strokeLinecap="round"/>
						</svg>
                    </span>
                    <ul>
                        <li>
                            <span className="text-5">No result found for <strong>{inputValue}</strong></span> 
                        </li>
                    </ul>
                     <div className="more-result cp"><button className="btn-primary cp" onClick={() =>  goToAdvanceSearch()}>Advance Search</button></div>
                </div> 
                : ''
            }
            </div>
            
);
  
}


function mapStateToProps(state) {
    return {
        searchSuggestion: state.song.searchSuggestion,
        activeBtn: state.activeDownloadBtn.activeDownloadBtn,
        themeType: state.theme.theme,
    };
 }
 
 function mapDispatchToProps(dispatch) {
    return {
        fetchSuggestions: (post) => dispatch(songActions.fetchSuggestions(post)),
    };
 }

export default connect(mapStateToProps, mapDispatchToProps)(Search);
