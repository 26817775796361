import React, { useState, useEffect } from "react";

import { checkPremiumMember } from '../../components/utlity/checkPremiumMember';
import {connect} from 'react-redux';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import MusicDashboard from '../../layouts/musicDashboard';
import CuratedSpotlightCarousel from '../../components/curatedSpotlightCarousel/curatedSpotlightCarousel';
import RightSide from "../../components/rightSide/rightSide";
import config from '../../config/config';
import  API_BASE_URL  from '../../config/config';
import ImageLoader from '../../components/loader/imageLoader';
import { Link, useNavigate } from 'react-router-dom';

import PlaylistPopup from '../../components/songTrack/playlistPopup';

import * as actions from '../../store/customer/actions';

const  PlaylistIndex = ({ displayLoading, hideLoading,  getCustomerPlayLists, playlistAlbums, authentication, userProfile }) => {
    const navigate = useNavigate();
    const [ processing, setProcessing ] = useState(false);
    const [ isPremium, setIsPremium ] = useState(false);  

    useEffect(() => { 
        if(checkPremiumMember(userProfile)){
            setIsPremium(true);
        } else {
            navigate('/subscription/plans');
        }
    },[userProfile])

    useEffect(() => { 
        setProcessing(true);
        displayLoading();
        getCustomerPlayLists({visit_page: 'Playlist Page'});
        hideLoading();
        setProcessing(false);
        window.scrollTo({top: 0});
    },[]);

    
    

    return (
        <MusicDashboard  rightContent={<RightSide blocks={{top30TrendingTracks: true} } />} >
       	<div className="content-sec">
			<div className="my-playlist-sec play-list-sec">
				<div className="top-heading">
					<h2>My Library</h2>
                    <PlaylistPopup playlistOpener="button" />
                </div>
				<div className="playlist-block inner-block latest-spotlights">
                    <div className="top-list">
                        <ul className="tab-sec">
                            <li><Link to={`/my-library/favourite`}>Favorites</Link></li>
                            <li className="divide active"><span>Playlists</span></li>
                            <li><Link to={`/my-library/download-rescue`}>Download Rescue</Link></li>
                        </ul>
                    </div>
                    {
                        processing ? <ImageLoader itemCount={20} /> :
                        playlistAlbums && playlistAlbums.length > 0 
                        ? 
                            <div className="playlist-inner-block latest-spot-block">
                            {
                                playlistAlbums.map((album, index) => {
                                    return <div className="box" key={`playlistalbumsIndex${index}`}>
                                        <Link to={`/playlists/detail/${album.playlist_album_id}`}><img src={`${config.downloadSelfUrl}customer-album-images/${album.cover_image}`} alt={album.title} /></Link>
                                        <Link to={`/playlists/detail/${album.playlist_album_id}`}><p>{album.title}</p></Link>
                                </div>
                                })
                            }
                            </div>
                        : <div className="alert alert-danger" style={{paddingLeft: '10px'}}>You do not have created any playlist.</div>
                    }
                </div>
			</div>
			<CuratedSpotlightCarousel title={'Suggested for you'}/>
		</div>
		
    </MusicDashboard>
    );
  
}


function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    playlistAlbums: state.customer.playlistAlbums,
    userProfile: state.customer.customerProfile,
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    addPlaylistAlbum: (post) => dispatch(actions.addPlaylistAlbum(post)),
    getCustomerPlayLists: (data) => dispatch(actions.getCustomerPlayLists(data)),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    getCustomerPlayListCoverImage: () => dispatch(actions.getCustomerPlayListCoverImage()),
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistIndex);
