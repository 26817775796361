import React, {useState, useEffect} from "react";

import SinglePageLayout from "../../layouts/singlePageLayout/singlePageLayout";
import {connect} from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from "react-router-dom";
import logo from './../../assets/images/cklogo.png';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form';

import * as actions from '../../store/customer/actions';

const DjApplication = ({  submitDJApp, customerSignUpSuccess, getTokenByEmail, displayLoading, hideLoading  }) => {
	const MySwal = withReactContent(Swal);
	const navigate = useNavigate();
	const match = useParams("/dj-app/:email");
	// const [fields, errors, form] = useFormInputValidation({
	// 	social_url: "",
	// 	referred_by: "",
	// 	comment: ""
	//   }, {
	// 		social_url: "required",
	// 		referred_by: "required",
	// 		comment:""
	//   });
	
  // const [processing, setProcessing] = useState(false);
	const [ responseError, setResponseError ] = useState();

	const [formData, setFormData] = useState({
		dj_website: '',
		referred_by: '',
		comment: '',
		dj_name: ''
	});
	const [ errors, setErrors ] = useState({});
	const [ validated, setValidated ] = useState(false);
	const [ formSubmitted, setFormSubmitted ] = useState(false);
	const [ processing, setProcessing] = useState(false);
	const [ memberRef, setMemberRef ] = useState(false);
	const [ selfData, setSelfData ] = useState(false);
	const [ djApplicationSuccess, setDjApplicationSuccess ] = useState(false);

  
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value
		}); 
		findFormErrors();
		// setFormSubmitted(false);
	}

	const findFormErrors = () => {
		const { dj_website, referred_by, ref_required, comment, dj_name } = formData;
		const newErrors = {}
		
		if(dj_name){
			if ( !dj_name || dj_name === '' ) newErrors.dj_name = 'DJ Name cannot be blank!'
		}
		if(memberRef){
			if ( !referred_by || referred_by === '' ) newErrors.referred_by = 'Member Refrence Detail cannot be blank!'
		}
		if(selfData){
			if ( !dj_website || dj_website === '' ) newErrors.dj_website = 'Social link url cannot be blank!'
			const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
			if(dj_website && !urlPattern.test(dj_website)){
				newErrors.dj_website = 'Please enter valid url!'
			}
		}
		
		if(!memberRef && !selfData) newErrors.ref_required = 'Please select one option from Member Refrence or Social link!'
		if(formSubmitted){
			setErrors(newErrors);
		}
		return newErrors
	}
	const handleSubmit = (event) => { 
		setFormSubmitted(true);
		const newErrors = findFormErrors();
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		
		if ( Object.keys(newErrors).length > 0 ) {
			// We got errors!
			setErrors(newErrors)
		} else {
			processDjApp({referred_by: formData.referred_by, dj_website: formData.dj_website, comment: formData.comment, dj_name: formData.dj_name});
		}
	}


	useEffect(() => {
		getToken(); 
		
	},[]);
	
	const toggleMemberRef = () => {
		setMemberRef(!memberRef);
	}

  	const toggleSelfData = () => {
		setSelfData(!selfData);
	}
	

	
  	const processDjApp = async(data) => {
		data.token = customerSignUpSuccess.token;
		displayLoading();
		setProcessing(true);
		setResponseError();
		submitDJApp(data).then(res => {
			if(res && res.payload && res.payload.data === null && res.payload.message && res.payload.message.errors && res.payload.message.errors.length>0){
				let errorsHtml = '<ul>';
				for(var i=0; i < res.payload.message.errors.length; i++){
				errorsHtml += '<li>'+res.payload.message.errors[i]+'</li>';
				}
				errorsHtml += '</ul>';
				setProcessing(false);
				hideLoading();
				setResponseError(errorsHtml);
			} else {
				setProcessing(false);
				hideLoading();
				// setDjApplicationSuccess(true);
				MySwal.fire({
					title: 'Account Review in Progress',
					text: "Your account is currently under review. You will receive a notification once the processing is complete. Your Continued Patience is Appreciated.",
					type: "success",
					allowOutsideClick: false,
				}).then(function() {
					window.location = "/sign-in";
				});
				
				// setTim	eout(() => resetSuccessMessage(), 3000);
			}
		});

	}
	
	const getToken = () => {
		getTokenByEmail({email: match.email});
	}

	
  return (
    <SinglePageLayout>
		<div className="dj-section">
			<div className="top">
				<Link to={`/sign-in`} className="logo"><img style={{ maxWidth: '100px' }} src={logo} alt="Clubkillers" title="Clubkillers"/></Link>
				<Link to={`/sign-in`} className="close">
					<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 1L9 9M9 1L1 9" stroke="white" strokeLinecap="round"/>
					</svg>
				</Link>
			</div>
			<div className="dj-inner">
				<div className="dj-block">
					<h2>Are you a DJ?</h2>
					{responseError ? <div className="alert-danger p-1 mb-3" dangerouslySetInnerHTML={{__html: responseError}}></div> : ''}
					<div className="dj-content">
					{
						djApplicationSuccess ? <p className="success-text"><div>Your application submitted successfully.	</div>Each application will be reviewed carefully. One by one to ensure the integrity in our brand & service. If you are a serious DJ you have your own website and/or multiple social network accounts, such as twitter, MySpace, or facebook. We will be visiting your links and making sure you meet our standards to be an official clubkillers member. Once you have completed your application, it will go through a verification process and you will be notified if you are approved via email.</p>
						: <p>Are you a Dj looking to be part of the most cutting edge digital record pool in the industry? Submit an application if your serious about taking your music selection to another level with exclusive edits & promos handpicked by our elite team of official DJ's. If you are a serious DJ, you have your own multiple social network accounts, such as Twitter, Facebook, Soundcloud or your own personal website, please include as many as you can, We will be visiting your links and making sure you meet our standards to be an official ClubKillers.com member. Each application will be reviewed. Not all applications will be approved.</p>
					}
					</div>
					
					{ djApplicationSuccess == false ?
					<div className="dj-form">
						<Form  validated={validated} onSubmit={handleSubmit}>
							
							<span className="text-2">You have not submitted DJ application.<span>*</span></span>
							{ responseError ? <div className="alert alert-danger">{responseError}</div> : ''}
								<InputGroup className="form-group">
								
								<Form.Control type="text" placeholder="DJ Name" name="dj_name" onChange={handleChange} isInvalid={ !!errors.dj_name } maxLength="20" />
								<Form.Control.Feedback type="invalid">
									{errors.dj_name}
								</Form.Control.Feedback>
								</InputGroup>
							<div className="switch-sec">
								<div>
									<Form.Check type="switch" className="switch" id="switchEnabled"  name="member_ref" checked={memberRef} onChange={toggleMemberRef} label="" />
									<span className="text-2">Have member reference?</span>
								</div>
								
								<div>
									<Form.Check type="switch" id="switchEnabledRef" name="self_ref" checked={selfData} onChange={toggleSelfData}  label=""  />
										{/* {errors.ref_required ? <div className="invalid">{errors.ref_required} </div>: ''} */}
									<span className="text-2">Have social link?</span>
								</div>
								<div className="font-yellow">Please select one option from member reference or social link.</div>
							</div>
							{ memberRef ? 
								<InputGroup className="form-group">
								
								<Form.Control type="text" placeholder="Refrence email or Refrence member social link url" name="referred_by" onChange={handleChange} isInvalid={ !!errors.referred_by } />
								<Form.Control.Feedback type="invalid">
									{errors.referred_by}
								</Form.Control.Feedback>
								</InputGroup>
								: ''
							}
							{ selfData ? 
								<InputGroup className="form-group">
								
								<Form.Control type="text" placeholder="Enter your social link url" name="dj_website" onChange={handleChange} isInvalid={ !!errors.dj_website } />
								<Form.Control.Feedback type="invalid">
								{errors.dj_website}
								</Form.Control.Feedback>
								</InputGroup>
								: ''
							}
							<InputGroup className="form-group">
								<Form.Control as="textarea" className="textarea-control" rows="3" placeholder="Additional Comment" name="comment" onChange={handleChange} isInvalid={ !!errors.comment } />
								<Form.Control.Feedback type="invalid">
								{errors.comment}
								</Form.Control.Feedback>
							</InputGroup>
							{ processing ? <button className="primary-btn" disabled={processing}><Spinner animation="border" variant="dark" size="sm" /></button> : <button type="submit" className="primary-btn cp">Submit</button> }
						</Form>
					</div>
					: '' }
				</div>
			</div>
		</div>				
    </SinglePageLayout>
  );
}

function mapStateToProps(state) {
  return {
	customerSignUpSuccess: state.customer.customerSignUpSuccess
  };
}

function mapDispatchToProps(dispatch) {
  return {
		getTokenByEmail: (post) => dispatch(actions.getTokenByEmail(post)),
		submitDJApp: (post) => dispatch(actions.submitDJApp(post)),
		displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    	hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DjApplication);