import React, {useState, useEffect, createRef} from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import AccountNav from '../../elements/accountElements/accountNav';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { Link, useNavigate } from 'react-router-dom'

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {connect} from 'react-redux';
import * as commonActions from '../../store/common/actions';
import * as customerAction from '../../store/customer/actions';
import * as subscriptionAction from '../../store/subscription/actions'
import { logout } from "../../store/authentication/actions";
import {isDesktop, isMobile, isTablet, browserName, browserVersion, osName, osVersion, isMobileOnly} from 'react-device-detect';


const CancelSubscriptionStep1 = ({customerProfile, agreement, plans, getUserProfile, user, displayLoading, hideLoading,  getCustomerAgreement, cancelCustomerFeedback, applyCancelDiscount, pauseSubscription, cancelSubscription}) => {
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const [ isStaff, setIsStaff ] = useState(false)
    const [ processing, setProcessing ] = useState(false);
    const [cancelReason, setCancelReason] = useState('');
    const [cancelOtherReason, setCancelOtherReason] = useState('');
    const [errorOther, setErrorOther] = useState('');
    const [cancelProcessStep, setCancelProcessStep] = useState('step 1');
    const [ formSubmited, setForrmSubmited ] = useState(false);
    const [formData, setFormData] = useState({
        cancel_action: ''
    });
    const [ errors, setErrors ] = useState({});
    const [ validated, setValidated] = useState(false);

    const issueType = [
        {label: "Having issue downloading the music?", value: "Having issue downloading the music?"},
        {label: "Having issue in search?", value: "Having issue in search?"},
        {label: "Want to switch the plan?", value: "Want to switch the plan?"},
        {label: "Plan price is heigher?", value:"Plan price is heigher?"},
        {label: "Others", value:"Others"}
    ];

    const [ userDeviceInfo, setUserDeviceInfo ] =  useState({
        device_type: isDesktop ? 'desktop' : isMobile ? 'mobile' : 'tablet',
        browser_name: browserName,
        browser_version: browserVersion,
        os_name: osName,
        os_version: osVersion,
        ip_address: '',
        token: localStorage.user,
        device_id: localStorage.getItem('deviceId')
    });
    
    useEffect(() => { 
        getCustomerAgreement();
    },[]);

    useEffect(() => {
        if(customerProfile && customerProfile.group_id == 3){
            var d1 = new Date();
            var d2 = new Date(customerProfile.staff_membership_expire_date);
            if(d2.getTime() > d1.getTime()){
                setIsStaff(true);
            }
        }
    },[customerProfile]);

    const getProfile =  async() => {
        
        await getUserProfile({token: user}).then(res => {
            // window.location.href= "/";
        })
    }

    const sendCancelReason = () => {
        
        let data = {
            reason: cancelReason
        };
        if(cancelReason == ''){
            const newErrors = findFormErrors();
            // console.log(newErrors)
            setErrors(newErrors);
            Swal.fire({
                title: 'Error',
                text: 'Please select an option to proceed',
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Ok'
                
            })
        } else if(cancelReason == 'Others' && cancelOtherReason == ''){
            setErrorOther('Please enter the other reason');
        } else if(cancelReason == 'Others' && cancelOtherReason != ''){
            data.otherreason = cancelOtherReason;
            cancelCustomerFeedback(data).then(res  => {
                Swal.fire({
                    title: "Thank you for your valuable feedback!", 
                    text: "Our team will contact you as soon as possible",
                    type: "success", 
                    closeOnConfirm: true
                }).then((result) => {
                    // navigate('/');
                    setCancelProcessStep('step 2');
                });
            });
        } else {
            cancelCustomerFeedback(data).then(res  => {
                Swal.fire({
                    title: "Thank you for your valuable feedback!", 
                    text: "Our team will contact you as soon as possible",
                    type: "success", 
                    closeOnConfirm: true
                }).then((result) => {
                    // navigate('/');
                    setCancelProcessStep('step 2')
                });
            });
        }

        // send admin to reason for cancelling but stil not canceled
        
        
        setProcessing(false);
        hideLoading();
    }

    const applyDicount = () => {
        setProcessing(false);
        hideLoading();
        applyCancelDiscount().then(res => {
            if(res.payload.data && res.payload.data.message){
                Swal.fire({
                    title: 'Discount already applied',
                    text: res.payload.data.message,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    setProcessing(false);
                    hideLoading();
                })
            } else {
                Swal.fire({
                    title: 'Discount applied',
                    text: "50% discount aplied to your subscription",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    setProcessing(false);
                    hideLoading();
                    navigate('/');
                })
            }
            
            setProcessing(false);
            hideLoading();
        })
        
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const findFormErrors = () => {
        const {  cancel_action } = formData
        const newErrors = {}
        
        if( !cancel_action || cancel_action === '')  newErrors.cancel_action = 'Choose your option to proceed'

        return newErrors
    }
    const handleSubmit = (event) => { 
        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setForrmSubmited(true);
        // console.log('formData', formData)
        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors);
            setForrmSubmited(false);
        } else {
            if(formData.cancel_action == 'pause'){
                processPauseAccount();
            } else {
                cancelCurrentSubscription();
            }
        }
    }

    const processPauseAccount = async () => {
        await pauseSubscription().then(res => {
            // console.log(res)
            if(res && res.payload && res.payload.data && res.payload.data.status){
                Swal.fire({
                    title: 'Subsciption Paused',
                    text: "Your subscription has been paused.no payment will be charge.",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                    
                }).then((result) => {
                    setForrmSubmited(false);
                    // navigate('/');
                })
            } else {
                Swal.fire({
                    title: 'Something went wrong',
                    text: res.payload.data.message,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                    
                }).then((result) => {
                    setForrmSubmited(false);
                    // navigate('/');
                })
            }
        })
        await getCustomerAgreement();
        await getUserProfile(userDeviceInfo); 
        navigate('/subscription/plans');
          
    }

    const cancelCurrentSubscription = () => {
        Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ffa800',
			cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, cancel it!',
            cancelButtonText: 'No'
		  }).then((result) => {
			if (result.isConfirmed) {
                displayLoading();
				cancelSubscription().then(res => {
					getCustomerAgreement().then(res => {
						hideLoading();
					});
                    
					Swal.fire({
						title: 'Subsciption Cancelled',
						text: "Your subscription has been cancelled.no payment will be charge.",
						input: 'text',
						inputAttributes: {
						  autocapitalize: 'off',
						  placeholder: 'Sad to see you go.We would love some constructive criticism on our site'
						},
						showCancelButton: true,
						confirmButtonText: 'Submit',
						showLoaderOnConfirm: true,
						preConfirm: (message) => {
							if(message != ''){
								cancelCustomerFeedback({reason: message})
							}
						},
						allowOutsideClick: () => !Swal.isLoading()
					  }).then((result) => {
						navigate('/subscription/plans');
					  })
				})
			
			} 
		  })
          setForrmSubmited(false);
    }

  return (
    <MusicDashboard  accountPages={true} middleSectionCssClass={'account-info-sec'}>
       	<div className="content-sec">
            <div className="account-block">
                <div className="top-heading">
                    <h2>Account Settings</h2>
                </div>
                <AccountNav />
                <div className="account-inner-block">
                        <div className="subscription-sec">
                        {
                            isStaff ? <div className="alert alert-danger">You have landed on wrong page. Please contact to <a href="mailto: info@clubkillers.com" className="text-3">Support</a> for more details</div>
                        :
                            <div className="payment-method-sec" style={{margin: '0 auto'}}>
                                <div className="edit-profile">
                                    <div className="title-heading"><h3>Cancel Subscription</h3></div>
                                </div>
                                {
                                    cancelProcessStep == 'step 1' ? 
                                        <div className="form-content top box-cancel">
                                            <h1>Hey {customerProfile ? customerProfile.dj_name : ''}, Please Stay! <br /> What's the Problem?</h1>
                                            <div style={{margin: '20px 0'}}>Could you please provide more information about the issue you're experiencing? It would be helpful to understand what's going wrong so we can assist you effectively.</div>
                                            <div className="" style={{margin: '20px 0'}}>
                                                <select name="issue_type" className="form-control" onChange={(event) => {
                                                    setCancelReason(event.target.value)
                                                }}>
                                                    <option value="">Please select</option>
                                                    {
                                                        issueType.map((type, index)=>{
                                                        return <option value={type.value} key={`issuetype-${index}`}>{type.label}</option>
                                                        })
                                                    }
                                                </select>
                                                
                                            </div>
                                            <div style={{margin: '20px 0'}}>
                                                {
                                                    cancelReason == 'Others' ? 
                                                        <textarea name="other_reason" className="form-control" style={{minHeight: '150px'}} onChange={(event) => {
                                                            setCancelOtherReason(event.target.value)
                                                        }}></textarea>
                                                    : ''
                                                }
                                                
                                            </div>
                                            {errorOther ? <div className="invalid-feedback" style={{marginBottom: '10px'}}>{errorOther}</div> : ''}
                                            <div className="cancel-action">
                                                <button className="primary-btn btn-small" onClick={() => sendCancelReason()}>Send</button> 
                                                <span className="text-3 cp" onClick={() => {
                                                    setCancelProcessStep('step 2')
                                                }}>Not Intrested</span>
                                            </div>
                                        </div>
                                    :  ''
                                    }
                                    {
                                        cancelProcessStep == 'step 2' ? 
                                        <div className="form-content top box-cancel">
                                            <h1>Before You Go! <br /> Special Offer Inside: <strong>Enjoy 50% Off.</strong></h1>
                                            <div style={{margin: '20px auto', display: 'block'}}>
                                            Thanks for being part of the Clubkillers family! <strong>Enjoy a 50%</strong> discount on your current subscription as a token of our appreciation. Click "Apply" below to redeem this special offer and continue enjoying our service</div>
                                            <div className="cancel-action">
                                                <button className="primary-btn btn-small" onClick={() => applyDicount()}>Apply</button> 
                                                <span className="text-3 cp" onClick={() => {
                                                    setCancelProcessStep('step 3')
                                                }}>I am not intrested</span>
                                            </div>
                                        </div>
                                    : ''
                                    }
                                    { 
                                        cancelProcessStep == 'step 3' ? 
                                        <div className="form-content top box-cancel">
                                            <h1>Before you cancel your subscription, <br />please consider these options:</h1>
                                            <div style={{margin: '20px 0', display: 'block'}}>
                                            <strong>Pause your subscription</strong> <br />Consider pausing your current subscription. This allows you to reactivate at the same price whenever you're ready to return. By clicking 'Pause,' you will not incur any future charges.
                                            <br /><br />
                                            <strong>Contact our support team</strong><br /> See if our support team can help you with any issues you might be facing.
                                            </div>
                                            <div className="cancel-action">
                                                <div className="payment-block">
                                                    <Form  validated={validated} onSubmit={handleSubmit}>
                                                        <div className="pay-sec">
                                                            <h5>Choose your option</h5>
                                                                
                                                                <Form.Check 
                                                                        name="cancel_action"
                                                                        type="radio"
                                                                        id={`pause`}
                                                                        label={'Pause'}
                                                                        onChange={handleChange}
                                                                        value="pause"
                                                                        isInvalid={ !!errors.cancel_action }
                                                                    />
                                                                <Form.Check 
                                                                        name="cancel_action"
                                                                        type="radio"
                                                                        id={`cancel`}
                                                                        label={'Cancel'}
                                                                        onChange={handleChange}
                                                                        value="cancel" 
                                                                        isInvalid={ !!errors.cancel_action }
                                                                    />
                                                                
                                                        </div>
                                                        
                                                        <Form.Control.Feedback type="invalid">
                                                            { errors.cancel_action }
                                                        </Form.Control.Feedback>
                                                        <div className="cancel-action" style={{margin: '20px 0'}}>
                                                            {
                                                            formSubmited ?  <button disabled="disabled" className="primary-btn"><Spinner animation="border" size="sm" variant="dark" /></button> : <button className="primary-btn">Proceed</button> 
                                                            }
                                                            <Link to={`/helpdesk/home`} className="text-3 cp primary-color color-priary" style={{marginLeft: '15px', minWidth: '148px', fontSize: '1.4rem', fontWeight: '900'}}>Contact Us</Link>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div> 
                                    : '' 
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
		</div>
		
        
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
    return {
        agreement: state.subscription.agreement,
        plans: state.subscription.plans,
        customerProfile: state.customer.customerProfile,
        user: state.authentication.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCustomerAgreement: () => dispatch(subscriptionAction.getCustomerAgreement()),
        getUserProfile: (token) => dispatch(customerAction.getCustomerProfile(token)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        cancelCustomerFeedback: (post) => dispatch(subscriptionAction.cancelCustomerFeedback(post)),
        applyCancelDiscount: () => dispatch(subscriptionAction.applyCancelDiscount()),
        pauseSubscription: () => dispatch(subscriptionAction.pauseSubscription()),
        cancelSubscription: () => dispatch(subscriptionAction.cancelSubscription()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelSubscriptionStep1);