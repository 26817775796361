import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import MtkSongTrack from '../../../components/songTrack/mtkSongTrack';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';

import * as actions from '../../../store/mtk/actions';

import { showLoading, hideLoading } from 'react-redux-loading-bar';

const  MTKTop20Tracks = ({ displayLoading, hideLoading, getTop20Tracks, top20Tracks, albumData, user }) => {

    const [ songSectionId, setSongSectionId ] = useState(2);
    const [ processing, setProcessing ] = useState(false);
	
	useEffect(() => { 
        displayLoading();
        loadData();
        hideLoading();
    },[]);

    const loadData = async() =>{
        // Get genres filter to display on homepage
        displayLoading();
        setProcessing(true);
        await getTop20Tracks({ token: user });
        hideLoading();
        setProcessing(false);
        window.scrollTo({top: 0});
    } 

    useEffect(() => { 
        if(albumData){
            displayLoading();
            getTop20Tracks(albumData);
            hideLoading();
        }   
    },[albumData]);

    
    
    return (
        <div className="bg-block inner-bg-block">
            <div className="heading-sec">
                <h2><span>Top 20</span> {top20Tracks && top20Tracks.title ? top20Tracks.title : ''} </h2>
            </div>
            { top20Tracks && top20Tracks.songs && top20Tracks.songs.length > 0 ? <MtkSongTrack songs={top20Tracks.songs} /> : '' }				
        </div>
    );
}
function mapStateToProps(state) {
	return {
        loggingIn: state.authentication.loggedIn,
        top20Tracks: state.mtk.top20Tracks,
        user: state.authentication.user
	};
 }
 
function mapDispatchToProps(dispatch) {
	return {
		displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        getTop20Tracks: (data) => dispatch(actions.getTop20Tracks(data)),
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(MTKTop20Tracks);