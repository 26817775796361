import React, { useState, useEffect } from "react";

import {connect} from 'react-redux';
// import ClipLoader from "react-spinners/ClipLoader";
import Spinner from 'react-bootstrap/Spinner';

import * as actions from '../../store/song/actions';

const CrateButton = ({ version, songId, addSongToCrate, getCustomerCrates, crateSongs, themeType }) => {

    const [ downloadInProcess, setDownloadInProcess ] = useState(false);
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#ffffff");
    const [ customerCrates, setCustomerCrates ] = useState(crateSongs);
    

    useEffect(() => { 
        setCustomerCrates(crateSongs);
   	},[crateSongs])

    const addRemoveFromCrate = async(songId, versionId) => {
        setDownloadInProcess(true);
        await addSongToCrate(songId, versionId);
        // await getCustomerCrates();
        setDownloadInProcess(false);
         
    }
    
    if(downloadInProcess){
        return <Spinner animation="border" variant="danger" size="sm" />
        
    } else { 
        return (
            <span className={customerCrates && customerCrates.version && customerCrates.version.length > 0 && ((customerCrates.version.indexOf(version.song_version_id.toString()) > -1) ) ? " add-crate cp" : "remove-crate cp"} onClick={() => addRemoveFromCrate(songId, version.song_version_id)} title={customerCrates && customerCrates.version && customerCrates.version.length > 0 && ((customerCrates.version.indexOf(version.song_version_id.toString()) > -1) ) ? "Remove from Crate" : "Add to Crate"}>
                {/* <svg width="20" height="20" viewBox="0 0 20 20" fill='none' xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.8002 3.59961H13.2002C13.6245 3.59961 14.0315 3.74009 14.3316 3.99013C14.6316 4.24018 14.8002 4.57932 14.8002 4.93294V5.99961H5.2002V4.93294C5.2002 4.57932 5.36877 4.24018 5.66882 3.99013C5.96888 3.74009 6.37585 3.59961 6.8002 3.59961Z" stroke={customerCrates && customerCrates.version && customerCrates.version.length > 0 && ((customerCrates.version.indexOf(version.song_version_id.toString()) > -1) ) ? '#EB4E27' : themeType == 'dark-theme' ? '#D7D7D7' : "#282A2D"} strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M16.2856 6H3.71415C3.08296 6 2.57129 6.51167 2.57129 7.14286V16.2857C2.57129 16.9169 3.08296 17.4286 3.71415 17.4286H16.2856C16.9168 17.4286 17.4284 16.9169 17.4284 16.2857V7.14286C17.4284 6.51167 16.9168 6 16.2856 6Z" stroke={customerCrates && customerCrates.version && customerCrates.version.length > 0 && ((customerCrates.version.indexOf(version.song_version_id.toString()) > -1) ) ? '#EB4E27' : themeType == 'dark-theme' ? '#D7D7D7' : "#282A2D"} strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6 10H14" stroke={customerCrates && customerCrates.version && customerCrates.version.length > 0 && ((customerCrates.version.indexOf(version.song_version_id.toString()) > -1) ) ? '#EB4E27' : themeType == 'dark-theme' ? '#D7D7D7' : "#282A2D"} strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6 13.4277H14" stroke={customerCrates && customerCrates.version && customerCrates.version.length > 0 && ((customerCrates.version.indexOf(version.song_version_id.toString()) > -1) ) ? '#EB4E27' : themeType == 'dark-theme' ? '#D7D7D7' : "#282A2D"} strokeLinecap="round" strokeLinejoin="round"/>
                </svg> */}
            </span>
        )
    }
}
function mapStateToProps(state) {
    return {
        crateSongs: state.song.customerCurates,
        themeType: state.theme.theme,
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
        addSongToCrate: (songId, versionId) => dispatch(actions.addSongToCrate(songId, versionId)),
        getCustomerCrates: () => dispatch(actions.getCustomerCrates())
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(CrateButton);
  