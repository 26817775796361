import React , {useState, useEffect} from "react";

import {connect} from 'react-redux';
import LeftNav from '../components/leftNav/leftNav';
import MiddleHeader from '../components/header/middleHeader';
import LoadingBar from 'react-redux-loading-bar';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

const MusicDashboard = ({children, rightContent, accountPages, middleSectionCssClass, isLeftmenuExpand }) => {
  useEffect(() => {
    document.body.classList.remove('body-bg');
    document.body.classList.remove('mtk');
  },[]);
  return (
    <SimpleBar style={{height: '100vh'}}>
    <div className="main-page">
      <LoadingBar className="loading" style={{ backgroundColor: '#ff07b3', height: '3px', position: 'fixed', zIndex: '999' }} updateTime={100} maxProgress={95} progressIncrease={10} />
        <LeftNav />
        
        <div className={ accountPages ?  isLeftmenuExpand ? `middle-section full-section active ${middleSectionCssClass}` : `middle-section full-section ${middleSectionCssClass}` :  isLeftmenuExpand ? `middle-section active ${middleSectionCssClass}` : `middle-section ${middleSectionCssClass}` }>
          <MiddleHeader />
          {!rightContent ? '' : <div className="for-mobile">{rightContent}</div> }
          {children}
        </div>
        
        {!rightContent ? '' : <div className="for-desktop">{rightContent}</div> }
    </div>
    </SimpleBar>
  );
};


function mapStateToProps(state) {
  
    return {
      loggingIn: state.authentication.loggedIn,
      isLeftmenuExpand: state.common.isLeftmenuExpand
    };
      
}
 
 function mapDispatchToProps(dispatch) {
  return {
    
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(MusicDashboard);
