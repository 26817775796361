import React, {useState, useEffect} from 'react';
import {PaymentRequestButtonElement, useStripe} from '@stripe/react-stripe-js';
import Spinner from 'react-bootstrap/Spinner'; 

export const CanAddApplePay = ({subscriptionData, onConfirmPayment}) => {
    const stripe = useStripe();
    const [paymentRequest, setPaymentRequest] = useState(null);
    useEffect(() => {
      // console.log('stripe', stripe)
      if (stripe) {
        const pr = stripe.paymentRequest({
          country: 'US',
          currency: 'usd',
          total: {
            label: subscriptionData.plan_detail,
            amount: subscriptionData.amount * 100,
          },
          requestPayerName: true,
          requestPayerEmail: true
        });
        
        // console.log(pr.canMakePayment())
        // Check the availability of the Payment Request API.
        pr.canMakePayment().then(result => {
         
          if (result) {
            setPaymentRequest(pr);
            // console.log('result: ', result, 'pr: ', pr);
            //  alert('result' + JSON.stringify(result))
          }
        });
  
  
        pr.on('token', async (ev) => { 
          onConfirmPayment(ev);
        })
        
      }
    }, [stripe]);
  
    if (paymentRequest) {
      return <PaymentRequestButtonElement options={{paymentRequest}} />
    } else {
      return <div className="" style={{textAlign: 'center'}}><Spinner animation="border" size="sm" variant="dark" /></div>
    }
     return '';
  
}