import React, { useState, useEffect } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link }  from "react-router-dom"
import Spinner from 'react-bootstrap/Spinner';
import ImageSliderLoader from '../../components/loader/imageSliderLoader';
import {connect} from 'react-redux';
import * as actions from '../../store/product/actions';


const  StoreProducts = ({ officialMerchandize, getOfficialMerchandise }) => {

	useEffect(() => { 
		getOfficialMerchandise();
	},[])
	const settings = {
		className: "slider variable-width",
		dots: false,
		infinite: false,
		centerMode: false,
		slidesToShow: 4,
		slidesToScroll: 4,
		variableWidth: true,
		arrows: true,
		responsive: [
		  {
			breakpoint: 1700,
			settings: {
			  slidesToShow: 3,
			  slidesToScroll: 3,
			}
		  },
		  {
			breakpoint: 1600,
			settings: {
			  slidesToShow: 4,
			  slidesToScroll: 4,
			}
		  },
		  {
			breakpoint: 1000,
			settings: {
			  slidesToShow: 1,
			  slidesToScroll: 1,
			}
		  },
		]
	};

    return (
      <div className="slider-sec  top-twenty merchandise">
			<div className="top-heading">
				<h2><a href="//gear.clubkillers.com/collections/products" target="_blank">Official Merchandise</a></h2>
				<a href="//gear.clubkillers.com/collections/products" target="_blank" className="text-2">View all</a>
			</div>
			{
				officialMerchandize && officialMerchandize.length > 0 ?
					<Slider {...settings}>
						{
							officialMerchandize.map((item, index) => {
								if(item.status == 'active'){
									return <div key={`officialMerchandise${index}`} className="item">
										<div className="box">
																					<a href={'https://gear.clubkillers.com/collections/frontpage/products/'+item.handle} target="_blank"><img src={item.image.src} alt={item.title} title={item.title} /></a>
																					<a href={'https://gear.clubkillers.com/collections/frontpage/products/'+item.handle} target="_blank">	<p className="text-4">{item.title}</p> </a>
										</div>
									</div>
								}
							})
						}
					</Slider>
				: <ImageSliderLoader itemCount={8} />
			}
			
		</div>
				
				
    );
  
}


function mapStateToProps(state) {
	return {
		officialMerchandize: state.product.products
	};
}
 
function mapDispatchToProps(dispatch) {
	return {
		getOfficialMerchandise: () => dispatch(actions.getProducts())
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreProducts);
