import React, { useState, useEffect } from "react";


import {connect} from 'react-redux';
import * as actions from '../../store/playlist/actions';

const RecentPlayedSong = ({getPlayList, playList}) => {
    useEffect(() => { 
        getPlayList();
    },[])
	if(playList && playList.length> 0){
		return (
			<div className="recently-played-sidebar">
                <div className="top">
                    <h2>Recently Played</h2>
                </div>
                <div className="track-list recently-played">
                    <ul>
				{
					playList.map((song, songIndex) => {
						return <li key={`recentlyplayedsidebar-${song.id}-${song.song_version_id}`}>
                                    <div className="content">
                                            <div>
                                                {/* <img src={AlbumImg} alt={`dangerouslySetInnerHTML={{__html: song.title}}`} title=""/> */}
                                                <div className="track-name">
                                                    <div className="text-2" dangerouslySetInnerHTML={{__html: song.name}}></div>
                                                    <div className="text-5" dangerouslySetInnerHTML={{__html: song.writer}}></div>
                                                </div>
                                            </div>
                                            
                                            <a href="#">
                                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.9994 5.42871H12.1423C12.2939 5.42871 12.4392 5.48891 12.5464 5.59608C12.6535 5.70324 12.7137 5.84859 12.7137 6.00014V14.5716C12.7137 14.7231 12.6535 14.8685 12.5464 14.9756C12.4392 15.0828 12.2939 15.143 12.1423 15.143H1.85658C1.70503 15.143 1.55969 15.0828 1.45252 14.9756C1.34536 14.8685 1.28516 14.7231 1.28516 14.5716V6.00014C1.28516 5.84859 1.34536 5.70324 1.45252 5.59608C1.55969 5.48891 1.70503 5.42871 1.85658 5.42871H2.99944" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M6.99902 0.857422V10.0003" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M4.71387 7.71484L6.99958 10.0006L9.2853 7.71484" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </li>
									
					})
                }
                    </ul>
				
			    </div>
            </div>
		)
	} else {
		return '';
	}
}



function mapStateToProps(state) {
    return {
		playList: state.playlist.playList,
    };
}
    
function mapDispatchToProps(dispatch) {
    return {
        getPlayList: () => dispatch(actions.getPlayList()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentPlayedSong);