import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';

const PopUp = ({ songId, version, togglePopUp, popUp }) => {
    // console.log(popUp, togglePopUp)
    if(popUp == 'playlist' && togglePopUp){
        return (
            
               <div className="folder-popup">
                    <div className="inner-block">
                        <div className="top-section">
                            <h1>Create New Folder</h1>
                            <span className="close">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 16.5 12 12 7.5 7.5m9 9L12 12l4.5-4.5" stroke="#919191" strokeWidth="2" strokeMiterlimit="10"></path></svg>
                            </span>
                        </div>
                        <div className="form-content">
                            <p className="text-3">Create folders to organize your playlists by genre, event, or more.</p>
                            {/* <input type="text" name="" placeholder="Folder Name"/> */}
                            <div className="btn-sec">
                                <button className="primary-btn">Create New Folder</button>
                                <button className="secondary-btn">Cancel</button>
                            </div>
                        </div>                            
                    </div>
                    <div className="overlay-shadow" ></div>
                </div>
        )
    } else {
        return '';
    }
    
    
    

}
function mapStateToProps(state) {
    return {
        
    };
}
    
function mapDispatchToProps(dispatch) {
    return {
        
    };
}
    
export default connect(mapStateToProps, mapDispatchToProps)(PopUp);
              