import * as types from './type';

const initialState = {
    previousChampionship: '',
    latestUploadThisWeek: '',
    top10ThisWeek: '',
    top10ThisMonth: '',
    top20Tracks: '',
    top20Albums: ''
};



export default function mtkReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.GET_PREVIOUS_CHEMPIONSHIP: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          previousChampionship: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.GET_LATEST_UPLOAD_THIS_WEEK: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          latestUploadThisWeek: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.GET_TOP_10_THIS_WEEK: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          top10ThisWeek: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.GET_TOP_10_THIS_MONTH: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          top10ThisMonth: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.GET_TOP_20_TRACKS: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          top20Tracks: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.GET_TOP_20_ALBUMS: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          top20Albums: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    default: {
      return {
        ...state
      };
    }
    
  }

}