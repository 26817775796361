import React, { useState, useEffect } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";


import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import RightSide from "../../components/rightSide/rightSide";
import Spinner from 'react-bootstrap/Spinner';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import MusicDashboard from '../../layouts/musicDashboard';
import { checkPremiumMember } from '../../components/utlity/checkPremiumMember';
import { checkCustomer } from '../../components/utlity/checkCustomer';

import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import {Calendar} from 'react-modern-calendar-datepicker';

import GenresLoader from '../../components/loader/genresLoader';

import { connect } from 'react-redux';
import * as genreActions from '../../store/genres/actions';
import * as downloadActions from '../../store/download/actions';
import config from '../../config/config';

const  ExpressDownloads = ({ themeType, getGenresWithCount, genresWithCount, displayLoading, hideLoading, userProfile, downloadExpressSongs, getCustomerDownloads }) => {
    const MySwal = withReactContent(Swal)
    const navigate = useNavigate();
    const [currentGenreId, setCurrentGenreId] = useState(0);
    const [ canAccess, setCanAccess ] = useState(false);
    const [ isPremium, setIsPremium ] = useState(false);  
    const [ downloadInProcess, setDownloadInProcess ] = useState(false);
    
    const d = new Date();
    let year = d.getFullYear();
    let month = d.getMonth();
    let dayOfMonth = d.getDate();

    const minimumDate = {
    year: year,
    month: month-1,
    day: dayOfMonth
    };

    const maximumDate = {
    year: year,
    month: month,
    day: dayOfMonth
    }
    
    const defaultValue = {
        from: minimumDate,
        to: maximumDate,
      };
      const [selectedDayRange, setSelectedDayRange ] = useState(
        defaultValue
      );
   
    useEffect(() => { 
        if(checkCustomer(userProfile)){
            setCanAccess(true);
        }
        if(checkPremiumMember(userProfile)){
            setIsPremium(true);
        }
    },[userProfile])

    useEffect(() => { 
		loadData();
	},[])

    const processDownload = () => {
        downloadExpressSongs({ genreId: currentGenreId, selectedDayRange: selectedDayRange, song_sectoin_id: 1}).then(res => {
            setDownloadInProcess(false);
            if(res.payload.response && res.payload.response.status === 403){
                MySwal.fire({
                    title: "Need Attention",
                    text: res.payload.response.data.message[0],
                    type: "danger",
                    allowOutsideClick: false,
                });
            } else if(res.payload.data) {
                getCustomerDownloads();
                const data = res.payload.data;
                const zipUrl = `${config.downloadSelfUrl}tmp/${encodeURIComponent(data.zip)}`;
                window.location.assign(zipUrl);
            }
        });
    }

    const downloadExpress = () => {
        let message = 'Select the desired date range from the calendar.';
        if(currentGenreId > 0){
            if(selectedDayRange.from && selectedDayRange.to){
                if(canAccess){ 
                    setDownloadInProcess(true);
                    processDownload();
                } else {
                    setDownloadInProcess(false);
                    navigate('/subscription/plans')
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: "Error!", 
                    text: message,
                    type: "error", 
                    closeOnConfirm: true
                }, function(){
                    
                });
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: "Error!", 
                text: "Select Genres to download",
                type: "error", 
                closeOnConfirm: true
            }, function(){
                
            });
        }
    }

	const loadData = async() => {
        displayLoading();
        await getGenresWithCount();
        hideLoading();
        window.scrollTo({top: 0});
    }
    // console.log('genresWithCount', genresWithCount)
	return (
			<MusicDashboard rightContent={<RightSide blocks={{ top30TrendingTracks: true}} />}>
                <div className="content-sec">
                    <div className="genres-sec">
                        <div className="top-heading">
                            <h2>Express DJ Download</h2>
                            
                        </div>
                        <br />
                        <div className="alert alert-danger">Select the desired date range from the calendar. After selecting the date range and genres, click the "Download" button located beside the calendar.</div>
                        <div className="expressdownload-calender-container">
                            <div class="left-group">
                                {selectedDayRange.from && selectedDayRange.to ? <label>Select Date Range ({selectedDayRange.from.year+'/'+selectedDayRange.from.month+'/'+selectedDayRange.from.day +' - '+selectedDayRange.to.year+'/' + selectedDayRange.to.month+'/'+selectedDayRange.to.day })</label> : ''}
                                <br />
                                <Calendar
                                    value={selectedDayRange}
                                    onChange={setSelectedDayRange}
                                    minimumDate={minimumDate}
                                    maximumDate={maximumDate}
                                    shouldHighlightWeekends={true}
                                />
                            </div> 
                            <div className="">
                                {
                                    downloadInProcess ? <Spinner animation="border" variant="danger" size="lg" />
                                :
                                    <span  className="cp" title="Download" style={{border: '1px solid', borderRadius: '50%', display: 'block', padding: '10px 12px'}} onClick={()=>downloadExpress()}>
                                        <svg width="40" height="44" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.9994 5.42871H12.1423C12.2939 5.42871 12.4392 5.48891 12.5464 5.59608C12.6535 5.70324 12.7137 5.84859 12.7137 6.00014V14.5716C12.7137 14.7231 12.6535 14.8685 12.5464 14.9756C12.4392 15.0828 12.2939 15.143 12.1423 15.143H1.85658C1.70503 15.143 1.55969 15.0828 1.45252 14.9756C1.34536 14.8685 1.28516 14.7231 1.28516 14.5716V6.00014C1.28516 5.84859 1.34536 5.70324 1.45252 5.59608C1.55969 5.48891 1.70503 5.42871 1.85658 5.42871H2.99944" stroke={themeType == 'dark-theme' ? "#FFF" : "#282A2D"} strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M6.99902 0.857422V10.0003" stroke={themeType == 'dark-theme' ? "#FFF" : "#282A2D"} strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M4.71387 7.71484L6.99958 10.0006L9.2853 7.71484" stroke={themeType == 'dark-theme' ? "#FFF" : "#282A2D"} strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </span>
                                }
                            </div>
                        </div> 
                        <br />
                        {
                            genresWithCount  && genresWithCount.length > 0 ? 
                                genresWithCount.map((genre, index) => {
                                    if(genre.total > 0){
                                        return <div className="genres-block express-download-block" key={`genresHome${genre.genre_id}`}>
                                            <div className="img-block">
                                                <h5 dangerouslySetInnerHTML={{__html: genre.name}}></h5>
                                            </div>
                                            {
                                                genre.subgenre && genre.subgenre.length > 0 ?
                                                    <div className="genres-right-block" >
                                                        <div className="tags">
                                                        {
                                                            genre.subgenre.map((subGenre, subGenreIndex) => {
                                                                if(parseInt(subGenre.total) > 0){
                                                                    return <span onClick={()=>setCurrentGenreId(subGenre.genre_id)} key={`genreSubGEnreHome${subGenre.genre_id}`}  dangerouslySetInnerHTML={{__html: subGenre.name}} className={ currentGenreId == subGenre.genre_id ? "active cp" : 'cp'}></span>
                                                                } else {
                                                                    return null;
                                                                }
                                                            })
                                                        }
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                : ''
                                            }
                                        </div>
                                    } else {
                                        return null;
                                    }
                                })
                                
                            : <GenresLoader itemCount={16} />
                        }
                    </div>
                </div>
        </MusicDashboard>
    );
}

function mapStateToProps(state) {
	return {
        loggingIn: state.authentication.loggedIn,
        genresWithCount: state.genre.genresWithCount,
        userProfile: state.customer.customerProfile,
        themeType: state.theme.theme,
	};
 }
 
function mapDispatchToProps(dispatch) {
	return {
		getGenresWithCount: () => dispatch(genreActions.getGenresWithCount()),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        downloadExpressSongs: (data) => dispatch(downloadActions.downloadExpressSongs(data)),
        getCustomerDownloads: () => dispatch(downloadActions.getCustomerDownloads())
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(ExpressDownloads);
