// src/StripeProvider.js
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// Replace with your own Stripe public key
// const stripePromise = loadStripe("pk_test_51OLGLVIGZoAJE3zoVNK92g4VQb8NcKFUjvjd2PEF57Wx80WlodBGujIuG6ZCKcwDvmQvZVVUS2aM4R7vhKDMrAMQ00ZvCI53G7");
const stripePromise = loadStripe('pk_live_51OLGLVIGZoAJE3zoNZ9pjfcJOh35nAjKJgpySzh30wPuu6OVWxr5h2ZAclDRESR8QnkY0HI5HTvzzrZDfqvJUukw00C8ax3Qs2');

const StripeProvider = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeProvider;