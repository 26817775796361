import * as types from './type';

import { Mtk }  from  '../../services/mtk';

export function getPreviousChamionship() {
    return {
      type: types.GET_PREVIOUS_CHEMPIONSHIP,
      payload: Mtk.getPreviousChamionship(),
    };
};

export function getLatestUploadsThisWeek() {
  return {
    type: types.GET_LATEST_UPLOAD_THIS_WEEK,
    payload: Mtk.getLatestUploadsThisWeek(),
  };
};

export function getTop10ThisWeek() {
  return {
    type: types.GET_TOP_10_THIS_WEEK,
    payload: Mtk.getTop10ThisWeek(),
  };
};

export function getTop10ThisMonth() {
  return {
    type: types.GET_TOP_10_THIS_MONTH,
    payload: Mtk.getTop10ThisMonth(),
  };
};

export function getTop20Tracks(data) {
  return {
    type: types.GET_TOP_20_TRACKS,
    payload: Mtk.getTop20Tracks(data),
  };
};

export function getTop20Ablums(data) {
  return {
    type: types.GET_TOP_20_ALBUMS,
    payload: Mtk.getTop20Ablums(data),
  };
};