import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';

import * as actions from '../../../store/mtk/actions';

import { showLoading, hideLoading } from 'react-redux-loading-bar';

const  MTKTop20Albums = ({ displayLoading, hideLoading, getTop20Ablums, user, setAlbum }) => {

    const [ showLoadMoreAlbum, setShowLoadMoreAlbum ] = useState(false);
    const [ processing, setProcessing ] = useState(false);
    const [ page, setPage ] = useState(1);
    const [albums, setAlbums ] = useState([]);
    
	useEffect(() => { 
        if(albums.length == 0){
            displayLoading();
            loadData();
            hideLoading();
        }
    },[]);

    const loadData = async() =>{
        // Get genres filter to display on homepage
        displayLoading();
        setProcessing(true);
        await getTop20Ablums({page: page, limit: 9, token: user}).then(res => {
            if(res && res.payload && res.payload.data && res.payload.data.length > 0){
                const newAlbums = res.payload.data;
                const currentAblums = albums;
                const combinedAlbums = currentAblums.concat(newAlbums);
                setAlbums(combinedAlbums);
                setShowLoadMoreAlbum(true);
            }
        });
        hideLoading();
        setProcessing(false);
        window.scrollTo({top: 0});
    }

    const loadMoreAblums = async () => {
        const pageNumber = page+1;
        setPage(pageNumber);
        displayLoading();
        setProcessing(true);
        await getTop20Ablums({page: pageNumber, limit: 9, token: user}).then(res => {
            if(res && res.payload && res.payload.data && res.payload.data.length > 0){
                const newAlbums = res.payload.data;
                const currentAblums = albums;
                const combinedAlbums = currentAblums.concat(newAlbums);
                setAlbums(combinedAlbums)
            } else {
                setShowLoadMoreAlbum(false);
            }
        });
        hideLoading();
        setProcessing(false);
    }
    
    
    return (
        <div className="chart-block">
            { 
                albums && albums.length > 0 ?
                    <div className="chart-block-inner" >
                    {
                        albums.map((album, index) => {
                        return <div className="block cp" key={`top20albums-${album.month}-${album.year}-${index}`} onClick={()=>setAlbum({month: album.month, year: album.year, token: user})}>
                                        <h2>{album.month_name} {album.year}</h2>
                                        <span> {album.name} 
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.3931 9.2245L13.5416 16.1343L6.63184 14.2828" stroke="#EB4E27" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M6.45801 3.8656L13.4705 16.0116" stroke="#EB4E27" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </div>
                                
                        })
                    }
                    </div>
                    : ''
                }
            {
                showLoadMoreAlbum ? 
                    <div className="load-more-btn">
                        <span className="load-more cp" onClick={() => loadMoreAblums()}>Load more 
                            {
                                processing ? <Spinner size="lg" />
                                :
                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="22" cy="22" r="22" fill="white"/>
                                    <path d="M27.3931 21.2245L25.5416 28.1343L18.6318 26.2829" stroke="#EB4E27" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M18.4585 15.8657L25.471 28.0117" stroke="#EB4E27" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            }
                        </span>
                    </div>
                : ''
            }
        </div>
    );
}
function mapStateToProps(state) {
	return {
        loggingIn: state.authentication.loggedIn,
        user: state.authentication.user,
        top20Albums: state.mtk.top20Albums
	};
 }
 
function mapDispatchToProps(dispatch) {
	return {
		displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        getTop20Ablums: (post) => dispatch(actions.getTop20Ablums(post)),
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(MTKTop20Albums);