import React, { useState, useEffect, useRef } from "react";

import {connect} from 'react-redux';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import Form from 'react-bootstrap/Form'
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import Spinner from 'react-bootstrap/Spinner';
import config from '../../config/config';
import  API_BASE_URL  from '../../config/config';
import { FilePond, File, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';            

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import * as actions from '../../store/customer/actions';


const  PlaylistPopup = ({ song, addPlaylistAlbum, authentication, displayLoading, hideLoading, getCustomerPlayListCoverImage, getCustomerPlayLists, playlistAlbums, addSongToPlaylist, userProfile, playlistOpener, themeType}) => {
    registerPlugin(FilePondPluginFileValidateType);
    const [files, setFiles] = useState([]);
    const ref = useRef();
    const [ uploadedFileName, setUploadedFileName ] = useState('');
    const [toggleSong, setToggleSong ] = useState(false);
    const [activeSongId, setActiveSongId] = useState(0);
    const [activeAlbumId, setActiveAlbumId] = useState(0);
    const [ processing, setProcessing ] = useState(false);
    const [createNewPlayList, setCreateNewPlayList ] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        cover_image: ''
    });
    const [validated, setValidated] = useState(false);
    const [ errors, setErrors ] = useState({});
    const [ coverImage, setCoverImage ] = useState();
    // const [ coverImagelabel, setCoverImagelabel] =  useState('Cover Image');
    const [ submit, setSubmit ] = useState(false);

    const uploadURL = API_BASE_URL.apiUrl+"/customer/upload-customer-album-image.json?token="+authentication.user+"&store_id=1&lang_code=en";

    
    // useEffect(() => { 
    //     getCustomerPlayLists();
    // },[]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        }); 
        findFormErrors();   
    }

    const findFormErrors = () => {
        const { title, cover_image } = formData
        const newErrors = {}

        
        // password errors
        if ( !cover_image || cover_image === '' ) newErrors.cover_image = 'Please upload cover image'
        if ( !title || title === '' ) newErrors.title = 'Playlist Name cannot be blank!'
        setErrors(newErrors);
        return newErrors
    }

    const handleSubmit = (event) => { 
        
        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors)
        } else {
            displayLoading();
            setSubmit(true);
            addPlaylistAlbum(formData).then(res => {
                if(res && res.payload && res.payload.data){
                    setSubmit(false);
                    getCustomerPlayLists();
                    setFormData({
                        title: '',
                        cover_image: ''
                    })
                    setUploadedFileName('');
                    // document.getElementById("create-playlist-form").reset();
                    setCreateNewPlayList(false);
                    
                }
            }); 
            hideLoading();
        }
    }

    

    const getUploadedFile = async(file) => {
        await getCustomerPlayListCoverImage().then(res => {
            if(res && res.payload && res.payload.data){
                setFormData({
                    ...formData,
                    cover_image : res.payload.data.cover_image
                }); 
                errors.cover_image = ''
                setErrors(errors);
            }
        });
    }


    const addSongToAlbum = (albumId) => {
        displayLoading();
        setProcessing(true);
        setActiveAlbumId(albumId);
        if(activeSongId > 0){
            let postData = {
                song_entity_id: activeSongId,
                customer_playlist_album_id: albumId
            }
            addSongToPlaylist(postData).then(res => {
                hideLoading();
                setProcessing(false);
            });
        }
    }

    const onOpenPopUp = (songId) => {
        setFormData({
            ...formData,
            song_entity_id: songId
        }); 
        setActiveSongId(songId);
        document.body.classList.add('pop-in');
    }
    const onClosePopUp = () => {
        setFormData({
            ...formData,
            song_entity_id: ''
        }); 
        setActiveSongId(0);
        setCreateNewPlayList(false);
        document.body.classList.remove('pop-in');
        
    }
    
    if((userProfile.is_premium_member && userProfile.is_premium_member == 1 ) || userProfile.group_id == 3){
    return <div className="playlist-popup-container">
        
        {/* themeType === 'light-theme' ? '#282A2D' */}
                <Popup trigger=
                {
                    playlistOpener && playlistOpener  == 'button' ? 
                        <button className="primary-btn create-btn cp" title="Add Song to Playlist">Create new</button>
                    :
                        <span className="cp" title="Add Song to Playlist">
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 15.5L9 15.5977" stroke={themeType === 'light-theme' ? '#282A2D' :  "#D7D7D7"} strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M4 10.5H16" stroke={themeType === 'light-theme' ? '#282A2D' :  "#D7D7D7"} strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M4 5.5H16" stroke={themeType === 'light-theme' ? '#282A2D' :  "#D7D7D7"} strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12.4258 12.5565C12.387 12.5259 12.3405 12.5069 12.2914 12.5016C12.2423 12.4962 12.1927 12.5048 12.1482 12.5263C12.1038 12.5477 12.0663 12.5813 12.0401 12.6231C12.0139 12.6648 12 12.7131 12 12.7624V18.238C12.0001 18.2873 12.014 18.3355 12.0403 18.3772C12.0665 18.4189 12.104 18.4524 12.1484 18.4738C12.1928 18.4953 12.2424 18.5038 12.2915 18.4984C12.3405 18.4931 12.3871 18.474 12.4258 18.4435L15.9 15.7061C15.9312 15.6816 15.9564 15.6503 15.9737 15.6146C15.991 15.579 16 15.5399 16 15.5002C16 15.4606 15.991 15.4215 15.9737 15.3858C15.9564 15.3501 15.9312 15.3188 15.9 15.2943L12.4258 12.5565Z" stroke={themeType === 'light-theme' ? '#282A2D' :  "#D7D7D7"} strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                }
                modal createNewPlayList={createNewPlayList} setCreateNewPlayList={setCreateNewPlayList} onOpen={() => onOpenPopUp(song.entity_id)} onClose={() => onClosePopUp(song.entity_id)} >
                
                {
                    close => (
                        <div className='modal playlist-popup-container'>
                            {/* <span class="close cp close-popup">
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L9 9M9 1L1 9" stroke="white" strokeLinecap="round"/>
                                </svg>
                            </span> */}
                            <div className='content'>
                                {
                                    createNewPlayList ?
                                    <div className="inner-block">
                                        <div className="top-section">
                                            <h1>Create New Folder</h1>
                                            <span className="close" onClick={() => {
                                                close();
                                                setCreateNewPlayList(false);
                                            }
                                            }>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 16.5 12 12 7.5 7.5m9 9L12 12l4.5-4.5" stroke="#919191" strokeWidth="2" strokeMiterlimit="10"></path></svg>
                                            </span>
                                        </div>
                                        <div className="form-content">
                                            <Form id="create-playlist-form"  validated={validated} onSubmit={handleSubmit}>
                                            
                                                <FilePond
                                                    files={files}
                                                    // onupdatefiles={setFiles}
                                                    allowFileTypeValidation={true}
                                                    acceptedFileTypes={'image/*'}
                                                    
                                                    allowMultiple={true}
                                                    maxFiles={1}
                                                    server={uploadURL}
                                                    name="UploadFilesForm[files]" 
                                                    labelIdle={uploadedFileName ? uploadedFileName : 'Add Cover Image'}
                                                    onprocessfile={(error, file) => {
                                                        // Set currently active file objects to this.state
                                                        getUploadedFile(file); 
                                                    }}
                                                    onupdatefiles={fileItems => {
                                                        
                                                        if(fileItems && fileItems[0] && fileItems[0].file && fileItems[0].file.filename){
                                                            setUploadedFileName(fileItems[0].file.filename);
                                                            findFormErrors();
                                                        }
                                                    }}
                                            
                                                />
                                                {errors.cover_image != '' ? <Form.Control.Feedback type="invalid">
                                                        { errors.cover_image }
                                                </Form.Control.Feedback> : '' }
                                                <Form.Group className="form-group" controlid="formBasicFullname" >
                                                    <Form.Label className="text-3">Create folders to organize your playlists by genre, event or more.</Form.Label>
                                                    <Form.Control  type="text" placeholder="Playlist Name" name="title" onFocus={handleChange} onBlur={handleChange}   onChange={handleChange} isInvalid={ !!errors.full_name } ref={ref} />
                                                    <Form.Control.Feedback type="invalid">
                                                        { errors.title }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <p className="text-3"></p>
                                                
                                                <div className="btn-sec">
                                                    { submit ? <button className="primary-btn"><Spinner animation="border" variant="dark" size="sm" /></button> :<button className="primary-btn" type="submit">Create</button>}
                                                    <button className="secondary-btn" onClick={() => {
                                                        // close();
                                                        setCreateNewPlayList(!createNewPlayList);
                                                    }}>Cancel</button>
                                                </div>
                                            </Form>
                                        </div>
                                    </div> : 
                                    <div className="inner-block latest-spotlights">
                                        <div className="top-section">
                                            {/* <h1>&nbsp;</h1> */}
                                            <span className="close" onClick={() => {
                                                close();
                                                setCreateNewPlayList(false);
                                            }
                                            }>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 16.5 12 12 7.5 7.5m9 9L12 12l4.5-4.5" stroke="#919191" strokeWidth="2" strokeMiterlimit="10"></path></svg>
                                            </span>
                                        </div>
                                    {
                                        playlistAlbums && playlistAlbums.length > 0 ?
                                        <SimpleBar style={{maxHeight: '700px'}}>
                                        <ul className="playlist-albums latest-spot-block">
                                            {playlistAlbums.map((album, index) => {
                                                return <li key={`customerplaylistAlbums${index}`} className={album.playlist_album_id == activeAlbumId ? "user-playlist-albums cp active box" :"user-playlist-albums cp box"} onClick={() => addSongToAlbum(album.playlist_album_id)}>
                                                    <div className="playlist-album-img">
                                                        <img src={`${config.downloadSelfUrl}customer-album-images/${album.cover_image}`} />
                                                        { processing && album.playlist_album_id == activeAlbumId ? <Spinner animation="border" className="center-spinner" variant="dark" size="sm" /> : ''}
                                                    </div>
                                                    <div className="playlist-album-name">{album.title}</div>
                                                </li>
                                            })}
                                            <li className="creat-playlist-link">
                                                <span onClick={() => {
                                                    setCreateNewPlayList(!createNewPlayList);
                                                }} className="cp">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.0029 6.76562V13.2336" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M6.76758 10H13.2356" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M15.6 3H4.4C3.6268 3 3 3.6268 3 4.4V15.6C3 16.3732 3.6268 17 4.4 17H15.6C16.3732 17 17 16.3732 17 15.6V4.4C17 3.6268 16.3732 3 15.6 3Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                    
                                                </span>
                                                <span className="cp" onClick={() => {
                                                    // console.log('createNewPlayList', createNewPlayList);
                                                    setCreateNewPlayList(!createNewPlayList);
                                                    
                                                }}> Create New Folder</span>
                                            </li>
                                        </ul>
                                        </SimpleBar>
                                        :
                                        <div className="playlist-albums">

                                                <span onClick={() => {
                                                    setCreateNewPlayList(!createNewPlayList);
                                                }} className="cp">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.0029 6.76562V13.2336" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M6.76758 10H13.2356" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M15.6 3H4.4C3.6268 3 3 3.6268 3 4.4V15.6C3 16.3732 3.6268 17 4.4 17H15.6C16.3732 17 17 16.3732 17 15.6V4.4C17 3.6268 16.3732 3 15.6 3Z" stroke="#D7D7D7" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                    
                                                </span>
                                                <span className="cp" onClick={() => {
                                                    setCreateNewPlayList(!createNewPlayList);
                                                }}> Create New Folder</span>
                                        </div>
                                    }
                                    </div>
                                }
                            </div>
                            
                        </div>
                    )
                }
            </Popup>
            
            </div>
            } else {
                return null;
            }
  
}


function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    playlistAlbums: state.customer.playlistAlbums,
    userProfile: state.customer.customerProfile,
    themeType: state.theme.theme,
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    addPlaylistAlbum: (post) => dispatch(actions.addPlaylistAlbum(post)),
    getCustomerPlayLists: () => dispatch(actions.getCustomerPlayLists()),
    addSongToPlaylist: (post) => dispatch(actions.addSongToPlaylist(post)),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    getCustomerPlayListCoverImage: () => dispatch(actions.getCustomerPlayListCoverImage()),
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistPopup);
