import React, { useEffect, useState} from "react";

//custom components
import SongTrackLoader from '../../components/loader/songTrackLoader';
import { Link } from "react-router-dom";
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import GenresMoods from './genresMood';
import RightSide from "../../components/rightSide/rightSide";
import Pagination from "react-js-pagination";
import MusicDashboard from '../../layouts/musicDashboard';
import SongTrack from '../../components/songTrack/songTrack';
import {connect} from 'react-redux';
import * as genreActions from '../../store/genres/actions';
import { useNavigate, useParams } from "react-router-dom";
import { hide } from "@popperjs/core";

const  GenreView = ({ getGenreSongs, genresWithCount, getGenresWithCount, genresData, displayLoading, hideLoading}) => {

    let match = useParams("/genres/:id");
    const navigate = useNavigate();

    const [genreId, setGenreId] = useState();
    const [ page, setPage ] = useState(1);
    const [ order, setOrder ] = useState('entity_id');
    const [ orderDir, setOrderDir ] = useState('DESC');
    const [ songSectionId, setSongSectionId ] = useState(1);
    const [ processing, setProcessing] = useState(false);
    const [ currentGenre, setCurrentGenre ] = useState();

    useEffect(() => { 
        setProcessing(true);
        setGenreId(match.id)
        if(match.id != genreId){
            displayLoading();
            getGenreSongs({page: 1, order: order, orderDir: orderDir, song_sectoin_id: songSectionId, genre_id: match.id}).then((res) => {
                
            });
            getGenresWithCount().then(res => {
                if(res.payload && res.payload.data && res.payload.data.data && res.payload.data.data.genres && res.payload.data.data.genres.length > 0){
                    const responseGenre = res.payload.data.data.genres;
                    const matchedGenre = responseGenre.filter(function (entry) { return entry.genre_id === match.id; });
                    if(matchedGenre && matchedGenre[0]){
                        setCurrentGenre(matchedGenre[0]);
                    } else {
                        setCurrentGenre();
                    }
                } else {
                    setCurrentGenre()
                }
                hideLoading();
                setProcessing(false);
            });
            window.scrollTo({top: 0});
        } 
    },[navigate]);

    

    const handlePageChange = async (pageNumber) => 
    {
        setProcessing(true);
        displayLoading();
        setPage(pageNumber);
        await getGenreSongs({page: pageNumber, order: order, orderDir: orderDir, song_section_id: songSectionId, genre_id: genreId});
        hideLoading();
        setProcessing(false);
    }
    
    // console.log(genresWithCount.indexOf(match.id));
  return (
    <MusicDashboard rightContent={<RightSide blocks={{ top30TrendingTracks: true}} />}>
        <div className="content-sec">
            <GenresMoods />
            <div className="play-list-sec">
                <div className="top-heading">
                { genresData && genresData.genre ? 
                    <div className="back-arrow-head">
                            <span className="cp" onClick={() => {
                                setPage(0);
                                navigate(-1)
                            }}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 6L8.09618 9.78462C8.06582 9.81224 8.04162 9.8456 8.02508 9.88265C8.00853 9.91969 8 9.95963 8 10C8 10.0404 8.00853 10.0803 8.02508 10.1174C8.04162 10.1544 8.06582 10.1878 8.09618 10.2154L12 14" stroke="#080A0D" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                            </span>
                            { genresData && genresData.genre ? <h2 dangerouslySetInnerHTML={{__html: genresData.genre.title}}></h2> : ''}
                        </div>
                    : ''
                    // <h2>{ genresData && genresData.genre ? <span dangerouslySetInnerHTML={{__html: genresData.genre.title}}></span> : ''}</h2>
                }
                </div>
                <div className="inner-block">
                        <div className="top-list">
                        
                                {
                                    currentGenre && currentGenre.subgenre && currentGenre.subgenre.length ? 
                                    <ul>
                                        {
                                            currentGenre.subgenre.map((genre, index)=>{
                                                return <li key={`homeGenresSong${genre.genre_id}`} ><Link to={`/mp3/genres/${genre.genre_id}`} dangerouslySetInnerHTML={{__html: genre.name}}></Link></li>
                                            })
                                        }
                                    </ul>
                                    : ""
                                }
                        </div>
                            
                        {
                            processing ? <SongTrackLoader itemCount={20} /> : 
                                genresData && genresData.songs && genresData.songs.length > 0 ?
                                    <SongTrack songs={genresData.songs}   /> 
                                : ''
                        }
                </div>
            </div>
            <div className="pagingnation-sec">
            {
                genresData && genresData.pagination ? 
                    <Pagination
                        activePage={genresData.pagination.page}
                        itemsCountPerPage={genresData.pagination.perPageLimit}
                        totalItemsCount={genresData.pagination.totalCount}
                        // pageRangeDisplayed={pageRangeDisplayed}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                /> : ''
            }
            </div>
        </div>
    </MusicDashboard>
    )
}

function mapStateToProps(state) {
  return {
    genresWithCount: state.genre.genresWithCount,
    genresData: state.genre.genresData,
    loggingIn: state.authentication.loggedIn,
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getGenresWithCount: () => dispatch(genreActions.getGenresWithCount()),
    getGenreSongs: (data) => dispatch(genreActions.getGenreSongs(data)),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
	hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(GenreView);