import React, {useState, useEffect} from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import AccountNav from '../../elements/accountElements/accountNav';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form'
import { Link } from 'react-router-dom';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {connect} from 'react-redux';
import * as commonActions from '../../store/common/actions';

const Booking = ({applyBooking, displayLoading, hideLoading}) => {
  
    const [formData, setFormData] = useState({
        dj: '',
        date: '',
        venue: '',
        venue_social_url: '',
        name: '',
        email: '',
        phone_number: '',
    });

    const [ processing, setProcessing ] = useState(false);
    const [ responseError, SetResponseError ] = useState();
    const [ responseSuccess, SetResponseSuccess ] = useState();
    const [ errors, setErrors ] = useState({});
    const [validated, setValidated] = useState(false);
    

  
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const findFormErrors = () => {
        const { dj, date, email, venue, venue_social_url, name, phone_number } = formData
        const newErrors = {}

        
        // password errors
        if ( !dj || dj === '' ) newErrors.dj = 'Clubkiller DJ cannot be blank!'
        if ( !date || date === '' ) newErrors.date = 'Date cannot be blank!'
        if ( !email || email === '' ) newErrors.email = 'Email cannot be blank!'
        if ( !venue || venue === '' ) newErrors.venue = 'Venue cannot be blank!'
        if ( !venue_social_url || venue_social_url === '' ) newErrors.venue_social_url = 'Vennue social url cannot be blank!'
        if ( !name || name === '' ) newErrors.name = 'Enter your name!'
        if ( !phone_number || phone_number === '' ) newErrors.phone_number = 'Phone Number cannot be blank!'

        return newErrors
    }

    const handleSubmit = (event) => { 
        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        // console.log(newErrors)
        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors)
        } else {
            SetResponseError('');
            SetResponseSuccess('');
            displayLoading(); 
            setProcessing(true);
            applyBooking(formData).then(res => {
                if(res && res.payload && res.payload.message && res.payload.message.errors){
                    SetResponseError(res.payload.message.errors[0]);
                    SetResponseSuccess('');
                    hideLoading();
                    setProcessing(false);
                } else {
                    SetResponseError('');
                    SetResponseSuccess('Booking request successfully sent');
                    hideLoading();
                    setProcessing(false);
                }
            });
            
        }
    }


  return (
    <MusicDashboard  accountPages={true} middleSectionCssClass={'account-info-sec'}>
       	<div className="content-sec">
            <div className="account-block">
                <div className="top-heading">
                    <h2>Account Settings</h2>
                </div>
                <AccountNav />
                <div className="account-inner-block">
                    <div className="edit-profile">
                        <div className="title-heading">
                            <h3>Booking</h3>
                        </div>
                        <Form  validated={validated} onSubmit={handleSubmit}>
                            <div className="form-content">
                            <div className="mt-1 mb-1 response-msg">
                                {responseSuccess ? <div className="alert alert-success">{responseSuccess}</div>: ''}
                                {responseError ? <div className="alert alert-danger">{responseError}</div>: ''}
                            </div>
                                <div>
                                    <Form.Group className="form-group" controlId="formBasicEmail">
                                        <Form.Label>Clubkiller DJ</Form.Label>
                                        <Form.Control type="text" placeholder="Clubkiller DJ" name="dj" onChange={handleChange} isInvalid={ !!errors.dj } />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.dj }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="form-group" controlId="formBasicEmail" >
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control type="text" placeholder="Date" name="date" onChange={handleChange} isInvalid={ !!errors.date } />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.date }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div>
                                    <Form.Group className="form-group" controlId="formBasicEmail" >
                                        <Form.Label>Venue</Form.Label>
                                        <Form.Control type="text" placeholder="Venue" name="venue" onChange={handleChange} isInvalid={ !!errors.venue } />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.venue }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="form-group" controlId="formBasicEmail">
                                        <Form.Label>Venue Social URL</Form.Label>
                                        <Form.Control type="text" placeholder="Venue Social URL" name="venue_social_url"  onChange={handleChange} isInvalid={ !!errors.venue_social_url } />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.venue_social_url }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div>
                                    <Form.Group className="form-group" controlId="formBasicEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="text" placeholder="Email" name="email" onChange={handleChange} isInvalid={ !!errors.email } />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.email }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="form-group" controlId="formBasicEmail">
                                        <Form.Label>Your Name</Form.Label>
                                        <Form.Control type="text" placeholder="Your Name" name="name" onChange={handleChange} isInvalid={ !!errors.name } />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.name }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div>
                                    <Form.Group className="form-group" controlId="formBasicEmail">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control type="text" placeholder="Phone Number" name="phone_number" onChange={handleChange} isInvalid={ !!errors.phone_number } />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.phone_number }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="save-btn">
                            { processing ? <button className="primary-btn"><Spinner animation="border" variant="dark" size="sm" /></button> : <button type="submit" className="primary-btn">Send Request</button> } 
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
		</div>
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
    return {
        customer: state.customer,
        countryList: state.common.countryList,
        user: state.authentication.user,
        authentication: state.authentication
    };
}

function mapDispatchToProps(dispatch) {
    return {
        applyBooking: (data) => dispatch(commonActions.applyBooking(data)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    	hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Booking);