export const checkPremiumMember = (userProfile) => {
    // console.log('checkPremiumMember', userProfile)
    if(userProfile && userProfile.group_id == 2 && userProfile.is_premium_member && userProfile.is_premium_member == 1){
        if(userProfile.payment_profile_status == 'paid' && (userProfile.profile_status == 'ACTIVE' || userProfile.profile_status == 'Active')){
            return true;
        } else if(userProfile.payment_profile_status == 'unpaid'){
            return false;
        } else if(userProfile.payment_profile_status == "pending"){
            return false;
        } else if(userProfile.payment_profile_status == 'canceled' && userProfile.profile_status == 'Cancelled'){
            var d1 = new Date();
            var d2 = new Date(userProfile.cancel_download_date);
            var same = d1.getTime() === d2.getTime();
            // console.log('checked', userProfile.cancel_download_date)
            if(d2.getTime() > d1.getTime()){
                return true;
            } else {
                return false;
            }
        } else if(userProfile.payment_profile_status == 'paused' && userProfile.profile_status == 'Suspend'){
            var d1 = new Date();
            var d2 = new Date(userProfile.pause_download_date);
            var same = d1.getTime() === d2.getTime();
            // console.log('checked', userProfile.cancel_download_date)
            if(d2.getTime() > d1.getTime()){
                // console.log('return true cancel');
                return true;
            } else {
                // console.log('canceled');
                return false;
            }
        } else {
            return false;
        }
    } else if(userProfile && userProfile.group_id == 3){
        var d1 = new Date();
        var d2 = new Date(userProfile.staff_membership_expire_date);
        if(d2.getTime() > d1.getTime()){
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}