import React, { useState, useEffect } from 'react';
import MusicDashboard from '../../layouts/musicDashboard';
import AccountNav from '../../elements/accountElements/accountNav';
import Spinner from 'react-bootstrap/Spinner';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import Form from 'react-bootstrap/Form';
import {connect} from 'react-redux';
import * as commonActions from '../../store/common/actions';

const Dmca = ({ getCountryList, countryList, sendDMCAData, displayLoading, hideLoading }) => {

    const [formData,setFormData] = useState({
                                    role:'',
                                    name:'',
                                    zipcode:'',
                                    email:'',
                                    company:'',
                                    country:'',
                                    city:'',
                                    address:'',
                                    phone:'',
                                    song_title:'',
                                    artist:'',
                                    terms_condition: '',
                                });
    const [validated, setValidated] = useState(false);
    const [ processing, setProcessing ] = useState(false);
    const [ errors, setErrors ] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);
    const [ responseError, SetResponseError ] = useState();
    const [ responseSuccess, SetResponseSuccess ] = useState();

    useEffect(() => { 
        getCountryList();
    },[]);

    const handleChange = (e) =>{
        const {name,value}=e.target;  
            setFormData({
                ...formData,
            [name]:value
        });
        if(isSubmit){
            findFormErrors();
        }    
    }

    const findFormErrors = () => {
        const {role, name, zipcode, email, company, country, city, address, phone, song_title, artist, terms_condition } = formData
        let newErrors = {}
        // password errors
        if ( !name || name === '' ) newErrors.name = 'Name cannot be blank!'
        if ( !role || role === '' ) newErrors.role = 'Role cannot be blank!'
        if ( !zipcode || zipcode === '' ) newErrors.zipcode = 'Zipcode cannot be blank!'
        if ( !email || email === '' ) newErrors.email = 'Enter email address'
        if ( !country || country === '' ) newErrors.country = 'Country cannot be blank!'
        if ( !company || company === '' ) newErrors.company = 'Company cannot be blank!'
        if ( !city || city === '' ) newErrors.city = 'City cannot be blank!'
        if ( !address || address === '' ) newErrors.address = 'Address cannot be blank!'
        if ( !phone || phone === '' ) newErrors.phone= 'Phone cannot be blank!'
        if ( !song_title || song_title === '' ) newErrors.song_title= 'Song name cannot be blank!'
        if ( !artist || artist === '' ) newErrors.artist= 'Artist cannot be blank!'
        
        if(email && !validateEmail(email)){
            newErrors.email = 'Your e-mail has been entered in the wrong format. Please check and try again';
        }
        if( !terms_condition || terms_condition === '')  newErrors.terms_condition = 'Terms is require'

        // console.log('newErrors', newErrors)
        setErrors(newErrors);
        return newErrors
    }

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    }

    const handleSubmit = ( event ) => {
        const checkErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setIsSubmit(true)
        // console.log('check 2', Object.keys(checkErrors).length)
        if ( Object.keys(checkErrors).length > 0 ) {
            // We got errors!
            setErrors(checkErrors);
            // console.log('check 3');
        } else {
            displayLoading(); 
            setProcessing(true);
            SetResponseError('');
            SetResponseSuccess('');
            sendDMCAData({formData: formData}).then(res => {
                if(res && res.payload && res.payload.data){
                    if(res.payload.data.message){
                        SetResponseError('');
                        SetResponseSuccess(res.payload.data.message);
                        hideLoading();
                        setProcessing(false);
                    } else {
                        SetResponseError(res.payload.data.errors[0]);
                        SetResponseSuccess('');
                        hideLoading();
                        setProcessing(false);
                    }
                }
            });
        }
    }

    return (
        <MusicDashboard accountPages={true} middleSectionCssClass={'account-info-sec'}>
            <div className="content-sec">
                <div className="account-block">
                    <div className="top-heading">
                        <h2>DMCA</h2>
                    </div>
                    <div className="account-inner-block">
                        <AccountNav />
                        <Form validated={validated} onSubmit={handleSubmit}>
                                <div className="form-content">
                                <div className="mt-1 mb-1 response-msg">
                                    {responseSuccess ? <div className="alert alert-success" style={{width: '100%'}}>{responseSuccess}</div>: ''}
                                    {responseError ? <div className="alert alert-danger" style={{width: '100%'}}>{responseError}</div>: ''}
                                </div>
                                
                                    <div>
                                        <Form.Group className="form-group" controlid="formBasicEmail" style={{minWidth: '100% !important'}} >
                                            <Form.Label>I am<span className="required">*</span></Form.Label>
                                            <Form.Control as="select" type="select" placeholder="Select your role" name="role" onChange={handleChange}
                                            >
                                                <option value="the owner of the infringing content">the owner of the infringing content.</option> 
                                                <option value="an agent, commissioned and allowed to file this tackdown notice on behalf of the rightholder">an agent, commissioned and allowed to file this tackdown notice on behalf of the rightholder.</option> 
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                { errors.role }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div>
                                        <Form.Group className="form-group" controlid="formBasicEmail" >
                                            <Form.Label>Your Full Legal Name<span className="required">*</span></Form.Label>
                                            <Form.Control type="text" placeholder="Full Name" name="name" onChange={handleChange}/>
                                            <Form.Control.Feedback type="invalid">
                                            { errors.name }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="form-group" controlid="formBasicEmail" >
                                            <Form.Label>Your ZIP/Postal Code<span className="required">*</span></Form.Label>
                                            <Form.Control type="text" placeholder="ZIP/Postal Code" name="zipcode" onChange={handleChange}/>
                                            <Form.Control.Feedback type="invalid">
                                                { errors.zipcode }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div>
                                        <Form.Group className="form-group" controlid="formBasicEail">
                                            <Form.Label>Your Email<span className="required">*</span></Form.Label>
                                            <Form.Control type="email" placeholder="" name="email" onChange={handleChange}/>
                                            <Form.Control.Feedback type="invalid">
                                                { errors.email }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="form-group" controlid="formBasicEail">
                                            <Form.Label>Your Country<span className="required">*</span></Form.Label>
                                            <Form.Control as="select" type="select" placeholder="Country" name="country" onChange={handleChange}>
                                                {
                                                    countryList && countryList.length > 0 ?
                                                        countryList.map((country, index)=>{
                                                            return <option value={country.name} key={`country-${country.id}`}>{country.name}</option>
                                                        })
                                                    : ''
                                                }
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                { errors.country }
                                            </Form.Control.Feedback>
                                            
                                        </Form.Group>
                                    </div>
                                    <div>
                                        <Form.Group className="form-group" controlid="formBasicEail">
                                            <Form.Label>Your Company/Organisation<span className="required">*</span></Form.Label>
                                            <Form.Control type="text" placeholder="" name="company" onChange={handleChange}/>
                                            <Form.Control.Feedback type="invalid">
                                                { errors.company }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="form-group" controlid="formBasicEail">
                                            <Form.Label>Your City<span className="required">*</span></Form.Label>
                                            <Form.Control type="text" placeholder="City" name="city" onChange={handleChange}/>
                                            <Form.Control.Feedback type="invalid">
                                                { errors.city }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div>
                                        <Form.Group className="form-group" controlid="formBasicEail">
                                            <Form.Label>Your Address<span className="required">*</span></Form.Label>
                                            <Form.Control type="text" placeholder="" name="address" onChange={handleChange}/>
                                            <Form.Control.Feedback type="invalid">
                                                { errors.address }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="form-group" controlid="formBasicEail">
                                            <Form.Label>Your Phone<span className="required">*</span></Form.Label>
                                            <Form.Control type="text" placeholder="" name="phone" onChange={handleChange}/>
                                            <Form.Control.Feedback type="invalid">
                                                { errors.phone }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div>
                                        <Form.Group className="form-group" controlid="formBasicEail">
                                            <Form.Label>Write the song title exactly as it appears/displayed<span className="required">*</span></Form.Label>
                                            <Form.Control type="text" placeholder="" name="song_title" onChange={handleChange}/>
                                            <Form.Control.Feedback type="invalid">
                                                { errors.song_title }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="form-group" controlid="formBasicEail">
                                            <Form.Label>Write the artist's name exactly as it is displayed<span className="required">*</span></Form.Label>
                                            <Form.Control type="text" placeholder="" name="artist" onChange={handleChange}/>
                                            <Form.Control.Feedback type="invalid">
                                                { errors.artist }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>  
                                    {/* <div>
                                        <Form.Group className="form-group" controlid="formBasicEail">
                                            <Form.Label>URL(s) of infringing content<span className="required">*</span></Form.Label>
                                            <Form.Control type="textarea" as="textarea" name="message" onChange={handleChange}/>   
                                            <Form.Control.Feedback type="invalid">
                                                { errors.message }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div> */}
                                    <div style={{width:"100%",border:"1px dotted white" , padding:"0 10px",margin:"10px 0"}}>
                                        <p >
                                        I am the owner, or an agent authorized to act on behalf of the owner of an exclusive right that is allegedly infringed. <br/>
                                        I have good faith belief that the use of the content in the manner complained of, is not authorized by the compyright owner, its agent, or the law. <br/>
                                        The information in this notification is accurate. This also means that each content type field contains corresponding information related to a single copyright infringement only. <br/>
                                        Otherwise it is possible that the notification is not going to be processed.<br/>
                                        I acknowledge that there may be adverse legal consequences for making false or bad faith allegations of copyright infringement by using this process.<br/>
                                        I understand that each affected user will be informed about this take down action. This also includes contact details you provide, upon the user?s request.
                                        </p>
                                    </div>
                                    <div style={{display:"flex" , justifyContent:"start",gap:"4px"}}>
                                        <Form.Group>
                                        <Form.Check 
                                                value="1"
                                                type='checkbox'
                                                id={`terms-vondition`}
                                                onChange={handleChange}
                                                name="terms_condition"
                                                label={
                                                    <span className="text-5" > <span className="required">*</span>
                                                        <span> I have read and accept the terms above</span>.</span>} 
                                                isInvalid={ !!errors.terms_condition }
                                            />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.terms_condition }
                                        </Form.Control.Feedback>
                                        </Form.Group>
                                        
                                    </div>
                                    <br />
                                    <div className="save-btn">
                                        { processing ? <button className="primary-btn"><Spinner animation="border" variant="dark" size="sm" /></button> : <button type="submit" className="primary-btn">Submit</button> }
                                    </div>
                                </div>
                            </Form>
                    </div>
                </div>
            </div>  
        </MusicDashboard>
    )
}

function mapStateToProps(state) {
    return {
        customer: state.customer,
        countryList: state.common.countryList
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCountryList: () => dispatch(commonActions.getCountryList()),
        sendDMCAData: (data) => dispatch(commonActions.sendDMCAData(data)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    	hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dmca);
