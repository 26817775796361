import React, {useState, useEffect} from "react";
import MusicDashboard from '../../layouts/musicDashboard';
import RightSide from "../../components/rightSide/rightSide";
import FavouriteSongTrack from '../../components/songTrack/favouriteSongTrack';
import SongTrackLoader from '../../components/loader/songTrackLoader';
import PopUp from '../../elements/popup/popUp';
import CuratedSpotlightCarousel from '../../components/curatedSpotlightCarousel/curatedSpotlightCarousel';
import config from '../../config/config';
import { Link, useNavigate } from "react-router-dom";
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { checkCustomer } from '../../components/utlity/checkCustomer';
import { checkPremiumMember } from '../../components/utlity/checkPremiumMember';
import {connect} from 'react-redux';
import * as songActions from '../../store/song/actions';






const FavouriteSongs = ({getCustomerFavouriteSongs, customerFavSongs, getFavouriteSongs, displayLoading, hideLoading, userProfile}) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const perPageLimit =  config.itemsCountPerPage;
  const [ togglePopUp, setTogglePopUp ] = useState(false);
  const [ processing, setProcessing ] = useState(false);  
  const [ isPremium, setIsPremium ] = useState(false);  

  useEffect(() => { 
		if(!checkCustomer(userProfile)){
            navigate('/subscription/plans');
    }
    if(checkPremiumMember(userProfile)){
      setIsPremium(true);
    }
  },[userProfile]);
  
  useEffect(() => { 
    loadPageData()
  },[]);
  
  const loadPageData = async () => {
    setProcessing(true);
    displayLoading()
    await getFavouriteSongs();
    await getCustomerFavouriteSongs({page: page, pageLimit: perPageLimit}, () => {
        // this.setState({genres: this.props.song.latestSong});
    })
    hideLoading();
    setProcessing(false);
    window.scrollTo({top: 0});
  }

  const showHidePopUp = (togglePopUp) => {
    setTogglePopUp(togglePopUp)
  }
  

  

  return (
    <MusicDashboard rightContent={<RightSide blocks={{ top30TrendingTracks: true, recentPlayedSong: true, suggestedCollection: true}} />} >
       	<div className="content-sec">
          <div className="play-list-sec">
            <div className="top-heading">
            { isPremium  ? <h2>My Library</h2> : <h2>Favorites Songs</h2> }
            </div>
            
              <div className="inner-block">
              { isPremium ? 
                <div className="top-list">
                  <ul className="tab-sec">
                    <li className='active'><span>Favorites</span></li>
                    <li className="divide"><Link to={`/playlists/home`}>Playlists</Link></li>
                    <li><Link to={`/my-library/download-rescue`}>Download Rescue</Link></li>
                  </ul>
                </div> 
                : ''
                }
              { 
                processing ?  <SongTrackLoader itemCount={20} /> :
                customerFavSongs && customerFavSongs.songs && customerFavSongs.songs.length > 0 ?
                  <FavouriteSongTrack songs={customerFavSongs.songs}  /> :  
                  <div>
                    <div className="alert alert-danger">
                      You have not added any favourite songs. Click <Link to="/" className="text-3">here</Link> to add song to your favourite list.
                    </div>
                </div> 
             } 
                
            </div>
          </div>
          <div style={{marginTop: '35px'}}>
            <CuratedSpotlightCarousel title={'Suggested for you'}/>
          </div>
		    </div>
		
    <PopUp showHidePopUp={showHidePopUp} togglePopUp={togglePopUp} popUp={'playlist'}  />
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
  return {
    customerFavSongs: state.song.customerFavouriteSongs,
    favouriteSongs: state.song.favouriteSongs,
    userProfile: state.customer.customerProfile,
  };
}

function mapDispatchToProps(dispatch) {
    return {
        getFavouriteSongs: () => dispatch(songActions.getFavouriteSongs()),
        getCustomerFavouriteSongs: () => dispatch(songActions.getCustomerFavouriteSongs()),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FavouriteSongs);