import * as types from './type';

const initialState = {
    customerProfile: '',
    customerError: '',
    customerSuccess: '',
    customerSignUpSuccess: '',
    customerPlayListAlbums: '',
    playlistCoverImage: '',
    playlistAlbums: '',
    playlistAlbumSongs: '',
    devices:''
};



export default function customerReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.GET_CUSTOMER_PROFILE: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          customerProfile: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.UPDATE_PROFILE_DATA: {
      if (action.payload && action.payload) {
        return {
          ...state,
          customerProfile: action.payload
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.GET_CUSTOMER_DEVICES: {
      if (action.payload && action.payload) {
        return {
          ...state,
          devices: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.REMOVE_CUSTOMER_DEVICE: {
      if (action.payload && action.payload) {
        return {
          ...state,
          devices: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.SEND_DEVICE_VERIFICATION_CODE: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          customerProfile: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.VERIFY_DEVICE_CODE: {
      // if (action.payload && action.payload.data) {
      //   return {
      //     ...state
      //   }
      // } else {
      //   return {
      //     ...state
      //   }
      // }
    }
    case types.GET_TOKEN_BY_EMAIL:{
      if (action.payload && action.payload.data) {
        return {
          ...state,
          customerSignUpSuccess: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.CHANGE_PASSWORD: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          customerSuccess: action.payload.data.message
        }
      } else {
        return { 
          ...state,
          customerError: action.payload.message.errors
        }
      }
    }
    case types.CUSTOMER_SIGNUP: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          customerSignUpSuccess: action.payload.data
        }
      } else {
        return { 
          
          ...state,
          customerError: action.payload.message.errors
        }
      }
    }
    case types.SUBMIT_DJ_APP: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          customerSuccess: action.payload.data.message
        }
      } else {
        return { 
          ...state,
          customerError: action.payload.message.errors
        }
      }
    }
    case types.UPDATE_PROFILE: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          customerProfile: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.ADD_PLAYLIST_ALBUM:{
      if (action.payload && action.payload.data) {
        return {
          ...state,
          playlistCoverImage: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.GET_PLAYLIST_ALBUM_IMAGE:{
      if (action.payload && action.payload.data) {
        return {
          ...state,
          playlistCoverImage: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.GET_CUSTOMER_PLAYLIST_ALBUMS:{
      if (action.payload && action.payload.data) {
        return {
          ...state,
          playlistAlbums: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.GET_CUSTOMER_PLAYLIST_SONGS:{
      if (action.payload && action.payload.data) {
        return {
          ...state,
          playlistAlbumSongs: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.REMOVE_SONG_FROM_CUSTOMER_PLAYLIST:{
      if (action.payload && action.payload.data) {
        return {
          ...state,
          playlistAlbumSongs: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.CLEAR_CUSTOMER_PLAYLIST:{
      if (action.payload && action.payload.data) {
        return {
          ...state,
          playlistAlbumSongs: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.DELETE_CUSTOMER_PLAYLIST:{
      if (action.payload && action.payload.data) {
        return {
          ...state,
          playlistAlbumSongs: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.ADD_SONG_TO_PLAYLIST_ALBUMS:{
      if (action.payload && action.payload.data) {
        return {
          ...state
        }
      } else {
        return {
          ...state
        }
      }
    }

    default: {
      return {
        ...state
      };
    }
    
  }

}