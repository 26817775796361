import * as types from './type';

const initialState = {
  careerLists: [],
  jobDetail: ''
};



export default function careerReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.GET_CAREER_LISTING: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          careerLists: action.payload.data.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.GET_JOB_DETAIL: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          jobDetail: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    
    default: {
      return {
        ...state
      };
    }
    
  }

}