import React, { useState, useEffect } from "react";
import axios from 'axios';
import {connect} from 'react-redux';
import * as actions from '../../store/customer/actions';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { useNavigate, Link } from "react-router-dom";
import {isDesktop, isMobile, isTablet, browserName, browserVersion, osName, osVersion, isMobileOnly} from 'react-device-detect';


const  ProfileImage = ({ loggingIn, customerData, user, getCustomerProfile, getUsersDevices, removeUserDevice }) => {
    const MySwal = withReactContent(Swal)
    const navigate = useNavigate();
    const [ userDeviceInfo, setUserDeviceInfo ] =  useState({
        device_type: isDesktop ? 'desktop' : isMobile ? 'mobile' : 'tablet',
        browser_name: browserName,
        browser_version: browserVersion,
        os_name: osName,
        os_version: osVersion,
        ip_address: '',
        token: localStorage.user,
        device_id: localStorage.getItem('deviceId')
    });
    

    const getIP =  () => {
        // const res = await axios.get("https://api.ipify.org/?format=json");
        let deviceInfo = userDeviceInfo;
        // deviceInfo.ip_address = res.data.ip;
        // setUserDeviceInfo(deviceInfo);
        if(!customerData.customerProfile){
            getCustomerProfile(deviceInfo).then(res => {
                if(res.payload.isAxiosError){
                    window.location.href = '/logout';
                }
            }); 
        }
    }
    
    useEffect(() => { 
        getIP();
		if(loggingIn !== undefined && loggingIn === false){
            window.location.href = '/logout';
		} 
    },[])
    useEffect(() => { 
        getUsersDevices({token: user})
    },[])
    
    

    useEffect(() => { 
        if(customerData.customerProfile && parseInt(customerData.customerProfile.device_count) > 2){
            getUsersDevices({token: user});
        }
    },[customerData.customerProfile]);
    
    // console.log('customerData', customerData)

    if(customerData && customerData.customerProfile){
        return (
            <div className="profile-name">
                <Link to={`/profile/update`}>
                {
                    customerData.customerProfile.profile_picture ? 
                        <img src={customerData.customerProfile.profile_picture} alt={customerData.customerProfile.dj_name ? customerData.customerProfile.dj_name : ''} title={customerData.customerProfile.dj_name ? customerData.customerProfile.dj_name : ''}/> 
                    : 
                    <div className="circle">
                        <p className="circle-inner">{customerData.customerProfile.dj_name && customerData.customerProfile.dj_name[0] ? Array.from(customerData.customerProfile.dj_name)[0] : customerData.customerProfile.firstname && customerData.customerProfile.firstname[0] ? Array.from(customerData.customerProfile.firstname)[0] : ''}</p>
                    </div>
                }
                
                <span className="text-5">{customerData.customerProfile.dj_name}</span>
                </Link>
                {/* {&& parseInt(customerData.customerProfile.is_premium_member) == 1} */}
                
            </div>

        );
    } else {
        return null
    }
  
}


function mapStateToProps(state) {
    return {
        loggingIn: state.authentication.loggedIn,
        customerData: state.customer,
        user: state.authentication.user
    };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getCustomerProfile: (data) => dispatch(actions.getCustomerProfile(data)),
    getUsersDevices: (token) => dispatch(actions.getUsersDevices(token))
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(ProfileImage);
