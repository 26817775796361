import React, {useEffect}  from "react";

import AccountNav from '../../elements/accountElements/accountNav'; 
import MusicDashboard from "../../layouts/musicDashboard";
import SuccessSvg from "../../assets/images/success-icon.svg";
import { Link } from "react-router-dom";
import {connect} from 'react-redux';




const SuccessPayment = ({}) => {
    return (
        <MusicDashboard accountPages={true} middleSectionCssClass={"account-info-sec full-section"}>
            <div className="content-sec">
                <div className="account-block">
                    <div className="top-heading">
                        <h2>Account Setting</h2>
                    </div>
                    <AccountNav/> 
                    <div className="account-inner-block">
                        <div className="subscription-sec">
                            <div className="payment-block msg-popup success" >
								<div className="right">
									<div className="box">
										<div className="body-text">
											<img src={SuccessSvg} />
											<h1>Success!</h1>
											<span className="text-3">Your payment was processed.</span>
										</div>
										
									</div>
									
									<Link to={`/`} className="secondary-btn">Back to main page</Link>
									<Link className="text-3 like-subscription" to={`/subscription/plans`}>Go to your subscriptions</Link>
								</div>
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </MusicDashboard>
    )
}
function mapStateToProps(state) {
    return {
        customer: state.customer,
    };
}

function mapDispatchToProps(dispatch) {
  	return {
  	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPayment);